import { isEmpty, isEqual, omit, padStart, sortBy } from "lodash-es";
import { normalizeResponse } from "../../../store/actions/dashboard/dashboard";
import { daysOfWeek } from "../../../utils/constants/constants";
import convertFiltersToOperatorsMode, {
  convertFiltersToConfigFormat,
} from "../../../Editors/BlockEdit/VisualizationGuiEditSection/convertFiltersToOperatorsMode";
import { parseExpression } from "cron-parser";
import { addHours, format } from "date-fns";
import { range } from "d3-array";
import { getEstTimezoneOffset } from "../../../utils/dateFunc";

export function getBlocks(pages) {
  const blocks = pages.flatMap((page) =>
    page.blocks.map((block) => ({ ...block, pageName: page.displayName }))
  );

  const response = { data: { blocks } };
  return normalizeResponse(response, true);
}

export function buildContentOptions(blocks = []) {
  return blocks.flatMap((block) =>
    block.charts.map((chart, index) => ({
      label:
        block.pageName +
        " | " +
        (block.displayName || block.title || chart.displayName || index),
      value: chart.uuid,
    }))
  );
}

export function getChartVisualization(blocks, content, report) {
  if (!content) {
    return;
  }

  // value is visualization uuid
  const { value } = content;

  const block =
    blocks.find((block) =>
      (block.charts ?? []).find((chart) => chart.uuid === value)
    ) ?? {};

  const chart =
    (block.charts ?? []).find((chart) => chart.uuid === value) ?? {};

  const filters =
    report.filters ??
    convertFiltersToConfigFormat((report.attachments ?? [])[0]?.filters ?? []);

  function setFilters() {
    if (value !== report.visualizationUuid) {
      return chart.filters;
    }

    return filters ?? chart.filters;
  }

  return { ...chart, filters: setFilters(), immutableFilters: chart.filters };
}

export function getFrequencyTableString(report) {
  if (!report.frequency) {
    return null;
  }

  const { frequency, day, time } = parseCronExpression(report.frequency);

  const dayOf =
    frequency === "Weekly"
      ? dayOfWeekOptions.find((option) => option.value === day)?.label
      : day;

  return `${frequency} ${dayOf} ${time}`;
}

function formatParagraphValues(htmlString) {
  return (htmlString || "").replaceAll("<p>", "").replaceAll("</p>", "\n");
}

function replaceAllEndLines(value) {
  const regexp = /(.*?)\n|(.*?$)/g;
  return (value ?? "").replace(regexp, (match, group1, group2) => {
    const clear = group1 || group2;
    return clear ? `<p>${clear}</p>` : "";
  });
}

export function setAttachmentFromChart(content, filters, chart) {
  return {
    name: content.label,
    filters: filters ?? [],
    overrides: chart.overrides ?? [],
    orderBy: chart.orders ?? [],
    limit: 500,
    queryUuid: chart.queryId,
    chartFilters: chart.immutableFilters,
  };
}

/**
 * Converts from Form to API Request format.
 */
export function getGeneralPayload({ chart, content, ...rest }) {
  const {
    name,
    message,
    active = false,
    day,
    frequency,
    filters,
    time,
    uuid,
    visualizationUuid,
    attachments,
    type,
    triggerEtlConfigUuid,
  } = rest;

  const dayNumber = day?.value ?? day;
  const cronValue = (() => {
    if (frequency.value === FrequencyValues.UNSCHEDULED) {
      return "";
    }
    const cronString = generateCronExpression(frequency.value, dayNumber, time);
    return convertCronScheduleTimezone(cronString, getEstTimezoneOffset() * -1);
  })();

  const updateFromChart = visualizationUuid !== chart.uuid || !uuid;
  const attach = (attachments ?? [])[0] ?? {};

  const fromChart = setAttachmentFromChart(
    content,
    convertFiltersToOperatorsMode(filters),
    chart
  );

  const attachment = updateFromChart
    ? fromChart
    : {
        ...attach,
        filters: convertFiltersToOperatorsMode(filters),
        chartFilters: attach.chartFilters ?? chart.immutableFilters,
      };

  return {
    name,
    frequency: cronValue,
    message: replaceAllEndLines(message),
    active,
    visualizationUuid: chart.uuid,
    attachments: [{ ...attachment, type: type ?? "excel" }],
    triggerEtlConfigUuid,
  };
}

/**
 * Converts from API response to Form format.
 */
export function getConvertedReport(report) {
  if (!report) {
    return null;
  }

  const { frequency, day, time } = parseCronExpression(report.frequency);
  const { triggerEtlConfig, ...restReport } = report;

  const dayOption =
    frequency === "Weekly"
      ? dayOfWeekOptions.find((option) => option.value === day)
      : day;

  const [hoursString, minutesString] = time.split(":");
  const hours24 = Number(hoursString);
  const minutes = Number(minutesString);
  const isPm = hours24 >= 12;
  const hours = !report.frequency ? null : isPm ? hours24 - 12 : hours24;

  return {
    ...restReport,
    active: !!report.active,
    frequency: frequencyOptions.find((option) => option.value === frequency),
    triggerEtlConfigUuid: triggerEtlConfig?.uuid ?? null,
    triggerEtlConfigDefaultName: triggerEtlConfig?.name,
    day: dayOption,
    time: {
      hours,
      minutes,
      isPm,
    },
    groups: report.groups ?? [],
    message: formatParagraphValues(report.message),
    filters: convertFiltersToConfigFormat(report.attachments[0]?.filters),
    type: report.attachments[0]?.type ?? "excel",
  };
}

/**
 * Generates a CRON expression based on the frequency, day of week/month, and time.
 * @param {String} frequency - 'Daily', 'Weekly', or 'Monthly'
 * @param {String|Number} day - Day of week (0-6, Sunday is 0) or day of month (1-31), used for 'Weekly' or 'Monthly' frequencies.
 * @param {{hours: number, minutes: number, isPm: boolean}} time - Time of day
 * @returns {String} CRON expression
 */
export function generateCronExpression(frequency, day, time) {
  // Split the time into hours and minutes
  const { hours: hours12, minutes, isPm } = time;
  const hours = isPm ? hours12 + 12 : hours12;

  switch (frequency) {
    case "Daily":
      // Return a cron expression that runs at the specified hours and minutes every day
      return `${minutes} ${hours} * * *`;
    case "Weekly":
      // Return a cron expression that runs at the specified hours and minutes on a specific day of the week
      return `${minutes} ${hours} * * ${day}`;
    case "Monthly":
      // Return a cron expression that runs at the specified hours and minutes on a specific day of the month
      return `${minutes} ${hours} ${day} * *`;
    default:
      return null;
  }
}

/**
 * Parses a CRON expression and returns the frequency, day, and time.
 * @param {String} cronExpression - The CRON expression to parse.
 * @returns {Object} Object containing frequency, day, and time.
 */
export function parseCronExpression(cronExpression) {
  const timeZonedCronExpression = convertCronScheduleTimezone(
    cronExpression,
    getEstTimezoneOffset()
  );
  const cronParts = timeZonedCronExpression.split(" ");
  const hours = cronParts[1] === "0" ? "00" : cronParts[1];
  const minutes = cronParts[0] === "0" ? "00" : cronParts[0];
  const time = `${hours}:${minutes}`;

  if (!cronExpression) {
    return {
      frequency: FrequencyValues.UNSCHEDULED,
      time: "00:00",
      day: "",
    };
  }

  if (cronParts[2] === "*" && cronParts[3] === "*" && cronParts[4] === "*") {
    // Daily cron job
    return {
      frequency: FrequencyValues.DAILY,
      time: time,
      day: "",
    };
  } else if (cronParts[2] === "*" && cronParts[3] === "*") {
    // Weekly cron job
    return {
      frequency: FrequencyValues.WEEKLY,
      day: +cronParts[4],
      time: time,
    };
  } else if (cronParts[3] === "*" && cronParts[4] === "*") {
    // Monthly cron job
    return {
      frequency: FrequencyValues.MONTHLY,
      day: +cronParts[2],
      time: time,
    };
  }

  // Return a default value or handle unsupported expressions
  return {
    frequency: "Unsupported",
    time: time,
  };
}

export const FrequencyValues = {
  UNSCHEDULED: "Unscheduled",
  DAILY: "Daily",
  WEEKLY: "Weekly",
  MONTHLY: "Monthly",
};

export const frequencyOptions = [
  {
    value: FrequencyValues.UNSCHEDULED,
    label: FrequencyValues.UNSCHEDULED,
  },
  {
    value: FrequencyValues.DAILY,
    label: FrequencyValues.DAILY,
  },
  {
    value: FrequencyValues.WEEKLY,
    label: FrequencyValues.WEEKLY,
  },
  {
    value: FrequencyValues.MONTHLY,
    label: FrequencyValues.MONTHLY,
  },
];

export const dayOfWeekOptions = daysOfWeek.map((day, index) => ({
  value: index,
  label: day,
}));

export const hoursOptions = sortBy(
  range(0, 12).map((value) => ({
    value,
    label: String(value || 12),
  })),
  (v) => Number(v.label)
);
const minuteIncrements = 30;
export const minutesOptions = range(0, 60 / minuteIncrements).map(
  (value, index) => {
    const minutes = minuteIncrements * index;
    return {
      value: minutes,
      label: padStart(minutes, 2, "0"),
    };
  }
);

export function convertCronScheduleTimezone(cronString, hours) {
  const cronParts = cronString.split(" ");
  const interval = parseExpression(cronString);
  const nextOccurrenceInEST = interval.next().toDate();
  // Convert EST time to UTC
  const nextOccurrenceInUTC = addHours(nextOccurrenceInEST, hours);

  // Format the next occurrence in UTC as components
  const minute = cronParts[0] === "*" ? "*" : format(nextOccurrenceInUTC, "m");
  const hour = cronParts[1] === "*" ? "*" : format(nextOccurrenceInUTC, "H");
  const dayOfMonth =
    cronParts[2] === "*" ? "*" : format(nextOccurrenceInUTC, "d");
  const month = cronParts[3] === "*" ? "*" : format(nextOccurrenceInUTC, "M");
  const dayOfWeek =
    cronParts[4] === "*"
      ? "*"
      : format(nextOccurrenceInUTC, "i") === "7"
      ? "0"
      : format(nextOccurrenceInUTC, "i"); // Convert the days to be 0 to 6

  // Assemble the UTC cron expression
  return `${minute} ${hour} ${dayOfMonth} ${month} ${dayOfWeek}`;
}

export function getReportPayload(report) {
  const cleared = report.url ? report : omit(report, "url");

  return {
    ...cleared,
    active: !cleared.active,
    overrides: cleared.overrides ?? [],
    filters: cleared.filters ?? [],
    attachments: cleared.attachments.map((attachment) => ({
      ...attachment,
      filters: cleared.filters ?? [],
      overrides: cleared.overrides ?? [],
      limit: 500,
    })),
  };
}

export function getDisabledSubmitStatus({
  frequency,
  content,
  reportName,
  day,
  time,
  report,
  convertedReport,
}) {
  const nothingChanged = isEqual(report, convertedReport);

  const requiresDay = ![
    FrequencyValues.UNSCHEDULED,
    FrequencyValues.DAILY,
  ].includes(frequency?.value);

  const requiresTime = frequency?.value !== FrequencyValues.UNSCHEDULED;

  if (!frequency) {
    return "Please select a Frequency.";
  }

  if (!content) {
    return "Please select the Content.";
  }

  if (!reportName) {
    return "Please provide the Report Name.";
  }

  if (requiresDay && !Number.isInteger(day?.value ?? day)) {
    return "Please select a Day for the scheduling.";
  }

  if (requiresTime) {
    if (typeof time.hours !== "number") {
      return "Please select an Hour for the scheduling.";
    }
    if (typeof time.minutes !== "number") {
      return "Please select Minutes for the scheduling.";
    }
    if (typeof time.isPm !== "boolean") {
      return "Please select AM/PM for the scheduling.";
    }
  }

  if (nothingChanged) {
    return "No changes to save.";
  }

  return "";
}

export function checkObsoleteConfig(chart, report) {
  if (!chart || isEmpty(report)) {
    return;
  }

  const attahcment = (report.attachments ?? [])[0] ?? {};

  const overridesCase = !isEqual(chart.overrides, attahcment.overrides);
  const filtersCase = !isEqual(chart.immutableFilters, attahcment.chartFilters);
  const ordersCase = !isEqual(chart.orders, attahcment.orderBy);
  const queryUuidCase = chart.queryId !== attahcment.queryUuid;

  return overridesCase || filtersCase || ordersCase || queryUuidCase;
}

export function getGeneralPayloadForReports(convertedReports, chart, content) {
  return convertedReports.map((report) => {
    const extendedFilters = extendFilters(
      chart.immutableFilters,
      report.filters
    );

    return {
      ...getGeneralPayload({
        ...report,
        chart: chart,
        content,
        uuid: report.uuid,
      }),
      attachments: [
        {
          ...setAttachmentFromChart(
            content,
            convertFiltersToOperatorsMode(extendedFilters),
            chart
          ),
          type: (report?.attachments ?? [])[0]?.type ?? "excel",
        },
      ],
      uuid: report.uuid,
    };
  });
}

export function extendFilters(chartFilters, overridenFilters) {
  // the rule: exclude all filters with same names if they exist in email overriden filters
  const extendedFilters = chartFilters.filter(
    (filter) =>
      !overridenFilters.some((overriden) => overriden.type === filter.type)
  );

  return [...overridenFilters, ...extendedFilters];
}
