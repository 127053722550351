import React from "react";

export default function DifferenceBars(props) {
  const { differences, x, y } = props;
  return differences.map((d) => {
    const startX = x(d.fromYear) + x.bandwidth() * 1.4;
    const endX = x(d.toYear);
    const middleX = (startX + endX) / 2;
    const startValue = y(d.fromValue);
    const endValue = y(d.toValue);

    return (
      <g key={d.fromYear}>
        <line
          x1={startX}
          x2={endX}
          y1={startValue}
          y2={startValue}
          stroke="lightgray"
        />
        <line
          x1={startX}
          x2={endX}
          y1={endValue}
          y2={endValue}
          stroke="lightgray"
        />
        <line
          x1={middleX}
          x2={middleX}
          y1={startValue}
          y2={endValue}
          stroke="lightgray"
        />
        <g
          transform={`translate(${middleX}, ${
            d.percentDifference > 0 ? endValue - 8 : startValue + 18
          })`}
        >
          <text
            style={{
              textAnchor: "middle",
              fontSize: 9,
              fill: d.percentDifference > 0 ? "green" : "red",
            }}
          >
            {d.percentDifference}%
          </text>
        </g>
      </g>
    );
  });
}
