import React, { useEffect, useState, useCallback } from "react";
import { format, parseISO } from "date-fns";
import isEqual from "react-fast-compare";
import ActiveTableFileEditor from "./Grid/ActiveTableFileEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ApiImage from "../TableView/Elements/ApiImage";
import { cloneDeep, orderBy } from "lodash-es";
import { isDate } from "../../utils/formatters/dateFormatter";
import { mergeColumnsAndQuery } from "./activeTableHelpers";
import usePrevious from "../../utils/usePrevious";
import styled from "@emotion/styled";
import { reservedWords } from "../../utils/constants/constants";
import { getActiveTableFileTypeConfig } from "../../utils/activeTable";
import { colors } from "./constants";
import Flex from "../../UI/Flex/Flex";
import { useActiveTableFileDownload } from "./Grid/common";
import { DATA_SOURCE_COLUMN_TYPE_MAP } from "../../utils/dataSources";

const Container = styled.div`
  height: 100%;
  & > div {
    height: 100% !important;
    width: 80px !important;
    margin: 0 auto;
  }
`;

const ImageRenderer = (value) => {
  const field = value.colDef.field;
  const imageValue = value.data[field];

  const imageIsProcessed = !!imageValue && typeof imageValue === "string";

  return (
    <Container>
      {imageIsProcessed ? (
        <ApiImage value={imageValue} blockZoom contain />
      ) : (
        <div
          style={{ fontSize: 32, color: colors.fileIcon, textAlign: "center" }}
        >
          <FontAwesomeIcon icon={["fas", "image"]} />
        </div>
      )}
    </Container>
  );
};

function FileRenderer(value) {
  const columnType = value.colDef.type;
  const fileTypeConfig = getActiveTableFileTypeConfig(columnType);
  const icon = fileTypeConfig.solid_icon;

  const field = value.colDef.field;
  const fileValue = value.data[field];

  const fileIsProcessed = !!fileValue && typeof fileValue === "string";
  const isImage = columnType === "image";

  const { downloadIcon } = useActiveTableFileDownload(fileValue);

  return (
    <Container>
      {isImage && fileIsProcessed ? (
        <ApiImage value={fileValue} blockZoom contain />
      ) : (
        <Flex
          style={{
            fontSize: 32,
            color: colors.fileIcon,
            textAlign: "center",
            opacity: fileIsProcessed ? 1 : 0.4,
          }}
          alignItems="center"
          justifyContent="center"
          gap="1rem"
          title={fileIsProcessed ? "" : "No File Uploaded."}
        >
          <FontAwesomeIcon icon={icon} color={colors.fileIcon} />

          {downloadIcon}
        </Flex>
      )}
    </Container>
  );
}

export default (
  tableConfig,
  user = { groups: [] },
  queryFields,
  handleRowChangeRef
) => {
  // To always call the latest-rendered version of the callback.
  const handleRowChange = useCallback(
    (...args) => handleRowChangeRef.current(...args),
    [handleRowChangeRef]
  );
  // Table Columns for Configs
  const [columns, setColumns] = useState([]);

  // Last timeTableConfig was updated
  const [prevConfig, setPrevConfig] = useState(null);

  // Last time table config was updated, translated versions
  const [prevTranslatedConfig, setPrevTranslatedConfig] = useState(null);

  // validation messages
  const [validationMessage, setValidationMessage] = useState(null);

  const defaultRow = columns.reduce((acc, curr) => {
    return { ...acc, [curr.field]: curr.type === "boolean" ? false : "" };
  }, {});

  // for join mode we need to get actual query fields which are come after active table
  // so wee need to update columns one more time
  const prevQueryFields = usePrevious(queryFields);

  const shouldUpdate =
    !isEqual(tableConfig, prevConfig) || !isEqual(queryFields, prevQueryFields);

  // update full configs on load and after save response is returned
  useEffect(() => {
    if (shouldUpdate) {
      const mergedColumns = mergeColumnsAndQuery(
        tableConfig.columns,
        queryFields
      );
      const initialColumns = removeIoSystemColumns(mergedColumns);
      const orderedInitialColumns = orderBy(initialColumns, "sortPriority");

      const nextColumnsConverted = orderedInitialColumns
        .map(applyAccessRules)
        .map((column) => convertColumn(column, handleRowChange, queryFields))
        .map(amendColumnForActiveGrid);
      setColumns(nextColumnsConverted);
      setPrevConfig(tableConfig);
      setPrevTranslatedConfig({
        ...tableConfig,
        columns: nextColumnsConverted,
      });
    }
    function applyAccessRules(column) {
      if (user.role === "tenant_owner") return column;
      const accessUuids = column.displaySettings?.accessGroups;
      if (!accessUuids?.length) return column;
      if (!column.isEditable) return column;
      const hasAccess = user.groups.find((uuid) => accessUuids.includes(uuid));
      return { ...column, isEditable: !!hasAccess };
    }
  }, [tableConfig, user, queryFields, handleRowChange, shouldUpdate]);

  const updateColumn = (index, newColumn) => {
    const newColumns = columns.map((col, i) => {
      const { queryOnly, ...rest } = newColumn;
      return i === index
        ? convertColumn(rest, handleRowChange, queryFields)
        : col;
    });
    setColumns(newColumns);
    return convertToApiColumns(newColumns);
  };

  const apiColumns = convertToApiColumns(columns);

  function convertToApiColumns(cols) {
    return cols
      .filter((col) => !col.queryOnly || col.editable)
      .map((col, i) => {
        const {
          field,
          editable,
          colId,
          cellEditor,
          cellEditorParams,
          displayName,
          ...rest
        } = col;
        let type = col.type;
        let displaySettings = {
          accessGroups: cellEditorParams?.accessGroups || [],
          activeOnly: !!col.displaySettings?.activeOnly,
        };
        let valueOptions = [];
        if (type === "select") {
          type = "string";
          displaySettings.editMode = "select";
          valueOptions = col.cellEditorParams.values.map((v) => ({ value: v }));
        }
        if (type === "image") {
          type = DATA_SOURCE_COLUMN_TYPE_MAP["api-image"].key;
        }

        const {
          headerName,
          joinedOverride,
          queryOnly,
          headerClass,
          cellStyle,
          cellRenderer,
          ...remaining
        } = rest;

        return {
          ...remaining,
          name: col.field,
          isEditable: col.editable,
          uuid: col.colId !== "temp" ? col.colId : undefined,
          type,
          displaySettings,
          valueOptions,
          sortPriority: i,
        };
      });
  }

  const addColumn = (name, type) => {
    const nextColumns = [
      ...columns,
      {
        colId: "temp",
        field: name || "",
        type: type || "string",
        editable: true,
        displaySettings: { activeOnly: true },
      },
    ];
    return setColumns(nextColumns);
  };

  const setAllLocked = () => {
    const nextColumns = columns.map((col) => ({ ...col, editable: false }));
    return setColumns(nextColumns);
  };

  const getColumn = (uuid) => {
    const column = columns.find((c) => c.colId === uuid);
    if (!column) {
      console.error(`No column with UUID '${uuid}' found`);
      return;
    }
    return column;
  };

  const addOption = (uuid, value) => {
    const column = getColumn(uuid);

    column.cellEditorParams.values = [...column.cellEditorParams.values, value];

    const nextColumns = columns.map((col) =>
      col.uuid === uuid ? column : col
    );

    return setColumns(nextColumns);
  };

  function setOptions(uuid, rawOptions) {
    return setColumns((columns) => {
      const column = cloneDeep(columns.find((c) => c.colId === uuid));
      let options;

      if (typeof rawOptions[0] === "object") {
        options = rawOptions.map((option) => option.value);
      } else {
        options = rawOptions;
      }

      column.cellEditorParams.values = options;

      const nextColumns = columns.map((col) =>
        col.colId === uuid ? column : col
      );
      return nextColumns;
    });
  }

  function setAccessGroups(uuid, nextGroups) {
    const nextColumns = columns.map((col) =>
      col.colId === uuid
        ? {
            ...col,
            cellEditorParams: {
              ...(col.cellEditorParams ?? {}),
              accessGroups: nextGroups,
            },
          }
        : col
    );

    return setColumns(nextColumns);
  }

  const nextConfig = { ...prevTranslatedConfig, columns: apiColumns };

  const isDirtyStraight = !!(
    nextConfig.columns.length !== 0 &&
    !isEqual(columns.map(ignoreVisible), prevTranslatedConfig.columns)
  );

  function ignoreVisible(column) {
    const { visible, ...rest } = column;
    return rest;
  }

  function updateConfig(config) {
    setPrevConfig(config);
  }

  // Settings Field name validation
  function validateFieldName(colDef) {
    const { field, colId } = colDef;
    const regex = /^\w+( +\w+)*$/;
    let message = null;

    // do not include new field and editing field
    const fields = columns.filter(
      (col) => col.colId !== "temp" && col.colId !== colId
    );

    if (!field) {
      message = "Column name cannot be empty.";
    } else if (field.length < 2) {
      message = "Column name must have at least 2 characters.";
    } else if (!regex.test(field)) {
      message = `Column name cannot contain only spaces, end or start with space or non alphanumeric symbols`;
    } else if (fields.some((col) => col.field === field)) {
      message = "That column name already exists.";
    } else if (reservedWords.some((word) => word === field.toLowerCase())) {
      message =
        "Value is equal to reserved word that cannot be used. Please try different value.";
    }

    setValidationMessage(message);
  }

  return {
    nextConfig,
    columns,
    apiColumns,
    updateColumn,
    setColumns,
    defaultRow,
    addColumn,
    addOption,
    setOptions,
    setAllLocked,
    setAccessGroups,
    isDirty: isDirtyStraight,
    updateConfig, // setUpdateConfig
    prevConfig,
    validateFieldName,
    convertToApiColumns,
    isInvalid: validationMessage,
  };
};

export const systemColumns = [
  "uuid",
  "io_created_at",
  "io_updated_at",
  "IOUUID",
  "IOLastUpdatedBy",
  "IOLastUpdated",
  "IOCreated",
  "IOCreatedBy",
  "IOEffectiveStart",
  "IOEffectiveEnd",
];

function removeIoSystemColumns(columns) {
  return columns
    .filter((c) => !systemColumns.find((s) => s === c.name))
    .map((c) => ({ ...c, field: c.name }));
}

export const convertColumn = (column, handleRowChange, queryFields) => {
  const accessGroups =
    column.displaySettings?.accessGroups ??
    column.cellEditorParams?.accessGroups ??
    [];

  const isEditEnabled = !!(column.isEditable || column.editable);

  const { mapping } =
    (queryFields ?? []).find(
      (field) => field.name === column.name || field.name === column.field
    ) ?? {};
  const displayName = mapping?.displayName ?? column.field;

  const setting = {
    field: column.field,
    editable: isEditEnabled, // To work for FE and API configs
    type:
      column?.displaySettings?.editMode === "select" ? "select" : column.type,
    colId: column.colId || column.uuid,
    headerName: isEditEnabled ? `✏️ ${displayName} ️` : displayName,
    cellEditorParams: {
      accessGroups,
    },
    displayName: column.field?.substring(0, Math.floor(20)), // @farhod Where is this used that we can't use headerName?
    displaySettings: column.displaySettings,
  };
  if (column.queryOnly) setting.queryOnly = true;
  if (column.joinedOverride) setting.joinedOverride = true;

  const values = column.cellEditorParams?.values?.length
    ? column.cellEditorParams.values
    : column.valueOptions?.length
    ? column.valueOptions.map((v) => v.value)
    : [];

  switch (setting.type) {
    case "api-image":
    case "image": // type apiImage
      return {
        ...setting,
        ...getCommonFileRendererProperties(),
        type: "image",
        cellRenderer: ImageRenderer,
      };
    case "api-pdf":
    case "api-excel":
    case "api-word":
      return {
        ...setting,
        ...getCommonFileRendererProperties(),
        type: setting.type,
        cellRenderer: FileRenderer,
      };
    case "date":
      return {
        ...setting,
        cellEditor: "agDateStringCellEditor",
        valueFormatter: (params) => {
          if (params.value && isDate(params.value)) {
            return format(parseISO(params.value), "MM/dd/yyyy");
          }
          return params.value;
        },
      };
    case "select":
      return {
        ...setting,
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: ["None...", ...values],
          accessGroups,
        },
      };
    case "currency":
      return {
        ...setting,
        valueFormatter: (params) => {
          return "$" + (params.value ?? "").toLocaleString();
        },
        headerClass: "ag-right-aligned-header",
        cellStyle: { textAlign: "right" },
      };
    case "number":
    case "integer":
      return {
        ...setting,
        valueFormatter: (params) => {
          if (!params?.value) return "--";
          return params.value.toLocaleString();
        },
        headerClass: "ag-right-aligned-header",
        cellStyle: { textAlign: "right" },
      };
    case "decimal":
      return {
        ...setting,
        valueFormatter: (params) => {
          return params.value && params.value.toLocaleString();
        },
        headerClass: "ag-right-aligned-header",
        cellStyle: { textAlign: "right" },
      };
    case "text":
      return { ...setting, cellEditor: "agTextCellEditor" };
    case "string":
      return {
        ...setting,
        cellEditor: "agTextCellEditor",
        cellEditorParams: {
          values,
          accessGroups,
        },
      };
    case "boolean":
      return {
        ...setting,
        cellEditor: "agCheckboxCellEditor",
        cellRenderer: "agCheckboxCellRenderer",
        cellStyle: { display: "flex", justifyContent: "center" },
      };
    default:
      return setting;
  }

  function getCommonFileRendererProperties() {
    return {
      cellEditorPopup: true,
      cellEditorPopupPosition: "over",
      cellEditorParams: {
        handleChange: handleRowChange,
        accessGroups,
      },
      headerClass: "ag-center-aligned-header",
      cellEditor: ActiveTableFileEditor,
    };
  }
};

function amendColumnForActiveGrid(column) {
  return { ...column, enableCellChangeFlash: true };
}
