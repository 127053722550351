import React, { forwardRef, useMemo } from "react";
import styled from "@emotion/styled";
import { getPinnedRows } from "../functions/tableMapper";

const defaultRowHeight = 29; // 29px;

const TrContainer = styled.tr(
  ({
    theme,
    index,
    totalRow,
    forceBold,
    hasRowExpand,
    background,
    bordered,
    sticky,
    stickyPinned,
    powerRow,
    expandedRowBackground,
    pinnedRow,
    hideRowZebraBackground,
    groupedRowBackground,
  }) => `
    cursor: ${hasRowExpand ? "pointer" : "auto"};
    border-top: ${bordered ? `1px solid ${theme.gray.gray900}` : "inherit"};
    border-bottom: ${bordered ? `1px solid ${theme.gray.gray900}` : "inherit"};
    ${
      !hideRowZebraBackground &&
      `
       background: ${
         index !== -1 &&
         (index % 2 === 0
           ? theme.gray900
           : `rgba(0,0,0,${theme.type === "dark" ? 0.15 : 0.05})`)
       };
      `
    };
    background: ${expandedRowBackground || (background && theme.primary)};
    color: ${background && theme.primary && "white"};
    vertical-align: middle;
    ${
      (totalRow || forceBold) &&
      `
        font-weight: bold;
        td {
            background-color: ${
              typeof forceBold === "string" ? forceBold : "black"
            } !important;
            color: white;
        }
        `
    };

    ${
      !!(sticky && totalRow) &&
      `
      position: sticky;
      z-index: 20;
      bottom: 0;
      left: 0;
    `
    }; 

    ${
      !!(sticky && stickyPinned) &&
      `
      position: sticky;
      z-index: 20;
      ${[pinnedRow.position]}: ${pinnedRow.offset}px;
      left: 0;
      td {
        background: ${
          theme.type === "dark" ? theme.gray.gray900 : theme.gray.gray400
        } !important;
        color: white;
      }
    `
    };

    ${
      powerRow &&
      `
      td {
        background: ${
          theme.type === "light" ? "rgb(255, 255, 255)" : "rgb(0,0,0)"
        };
        font-weight: 700;
      }
    `
    };

    &:hover {
      td {
        border-top: 1px solid ${theme.notification.infoMain};
        border-bottom: 1px solid ${theme.notification.infoMain};
      }
    };

    ${
      groupedRowBackground &&
      `td { background: ${groupedRowBackground} !important;}`
    };
`
);

// We set the default row height to 30px, but since different heights may be needed for cases like star ratings,
// we obtain the correct height from a reference passed in props, since we also use pinned rows for drilldowns.
const Tr = forwardRef((props, ref) => {
  const {
    pinnedRows,
    sticky,
    row = {},
    children,
    totals,
    tHeadHeight,
    dataValues,
  } = props;

  const pinned = useMemo(() => {
    if (!pinnedRows) return {};

    const stickyPinned = !!(
      sticky && row.values.find((val) => getPinnedRows(val, pinnedRows))
    );

    const pinnedRow = applyPinnedRowOffset({
      pinnedRows: getExistingPinnedRows(pinnedRows, dataValues),
      row,
      tHeadHeight,
      totals,
      ref,
    });

    return {
      stickyPinned,
      pinnedRow,
    };
  }, [pinnedRows, sticky, row, dataValues, tHeadHeight, totals, ref]);

  return (
    <TrContainer {...props} {...pinned} ref={ref}>
      {children}
    </TrContainer>
  );
});

export default React.memo(Tr);

const getPinnedRowItem = (pin, row) => {
  return row.values?.find((val) => val[pin.column] === pin.value);
};

const getPinnedRowIndex = (props) => {
  // for bottom aligned we need check for total row
  // if we have totals then we need to pin to one position top from total
  if (props.pinnedRow?.position === "bottom") {
    return (
      props.pinnedRows
        .slice()
        .reverse()
        .findIndex((pin) => getPinnedRowItem(pin, props.row)) +
      (props.totals ? 1 : 0)
    );
  }

  // for top pin position we need to check for parent headers
  // if parent headers exists then we need to put pin 2 position after parent
  return (
    props.pinnedRows.findIndex((pin) => getPinnedRowItem(pin, props.row)) + 1
  );
};

export const applyPinnedRowOffset = (props) => {
  if (!props.pinnedRows || !props.pinnedRows.length) {
    return;
  }

  const pinnedRow = props.pinnedRows.find((pin) =>
    getPinnedRowItem(pin, props.row)
  );

  if (!pinnedRow) {
    return;
  }

  const index = getPinnedRowIndex({ ...props, pinnedRow });

  // get real row height
  const rowHeight = props.ref?.current?.clientHeight ?? defaultRowHeight;

  // separate offset calculation for position = top
  // table headers can have dynamic height value
  const topOffset =
    (index === 1
      ? props.tHeadHeight
      : (index - 1) * rowHeight + props.tHeadHeight) - 1;

  // pinned rows using position sticky so we need set offset from top/bottom
  return pinnedRow
    ? {
        ...pinnedRow,
        offset: pinnedRow.position === "top" ? topOffset : index * rowHeight,
        rowHeight,
      }
    : null;
};

export function getExistingPinnedRows(pinnedRows, values) {
  return pinnedRows?.reduce((acc, curr) => {
    const isExist = values.some((item) => item[curr.column] === curr.value);
    if (isExist) {
      acc.push(curr);
    }

    return acc;
  }, []);
}
