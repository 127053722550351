import React, { useEffect, useRef, useMemo } from "react";
import Input from "../../../UI/Form/Input/Input";
import ActiveTableDisplayFormat from "./ActiveTableDisplayFormat";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ActiveTableColumnSettingsPanel from "./ActiveTableColumnSettingsPanel";
import ActiveTableOptionsSettings from "./ActiveTableOptionsSettings";
import {
  faFilePdf,
  faFileExcel,
  faFileWord,
} from "@fortawesome/pro-light-svg-icons";

const Container = styled.div`
  display: flex;
  margin-bottom: 8px;
`;
const LabelSpan = styled.span`
  path {
    fill: ${(props) => props.theme.text.primary} !important;
  }
`;

const OuterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 22px;
`;

const Label = (props) => {
  return (
    <LabelSpan>
      <FontAwesomeIcon
        style={{ fontSize: 16, position: "relative", top: 1 }}
        icon={typeof props.icon === "string" ? ["fal", props.icon] : props.icon}
      />
      {props.text}
    </LabelSpan>
  );
};

const Instructions = styled.div`
  color: ${(props) => props.theme.text.primary};
`;

export default function ActiveTableSettingsRow(props) {
  const {
    colDef,
    index,
    updateColumn,
    updateColumnConfig,
    updateTypeConfig,
    setOptions,
    accessGroups,
    setAccessGroups,
    validateFieldName,
    fieldNameValidation,
    setActiveColumn,
    activeTableUpdateLoading,
    setEditing,
  } = props;

  const fieldNameRef = useRef(null);

  useEffect(() => {
    if (fieldNameRef.current && colDef?.colId) {
      fieldNameRef.current.focus();
    }
  }, [colDef?.colId]);

  useEffect(() => {
    if (colDef) {
      validateFieldName(colDef);
    }
  }, [colDef, validateFieldName]);

  const availableTypeOptions = useMemo(() => {
    return typeOptions.filter((v) => !v.deprecated || v.value === colDef?.type);
  }, [colDef?.type]);

  if (!colDef) {
    return (
      <Instructions data-cy="settings-message">Select a column</Instructions>
    );
  }

  function handleChangeField(e) {
    updateColumn(index, { ...colDef, field: e.target.value.trimLeft() });
  }

  function handleEditStyleChange(e) {
    const editors = {
      text: "agTextCellEditor",
      select: "agSelectCellEditor",
    };

    updateColumn(index, {
      ...colDef,
      type: e.value,
      displaySettings: { ...(colDef.displaySettings ?? {}), editMode: e.value },
      cellEditor: editors[e.value],
    });
  }

  function handleFormatChange(e) {
    // do nothing yet
  }

  function handleLocked(e) {
    if (colDef.queryOnly) {
      const nextColDef = {
        ...colDef,
        editable: !e.target.checked,
        colId: "temp",
      };
      updateColumn(index, nextColDef);
      setActiveColumn("temp");
      updateColumnConfig("temp", colDef.field, index, nextColDef);
    } else {
      updateColumn(index, { ...colDef, editable: !e.target.checked });
    }
  }

  function handleChangeType(e) {
    // Update in useActiveTableSettings hook
    // updateColumn(index, { ...colDef, type: e.value });
    const nextColumnSetting = { ...colDef, type: e.value };
    updateTypeConfig(
      colDef?.colId,
      { ...colDef, type: e.value },
      index,
      nextColumnSetting
    );
  }

  function handleFieldNameChange() {
    setEditing(false);
    if (!fieldNameValidation) {
      updateColumnConfig(colDef?.colId, colDef?.field, index, colDef);
    }
  }

  const lockedField = !colDef.displaySettings?.activeOnly;

  return (
    <div>
      <OuterContainer>
        <Container>
          <div
            style={{
              marginRight: 8,
              width: 180,
              opacity: colDef.queryOnly ? 0.4 : 1,
            }}
          >
            <Input
              value={colDef?.field}
              label="Field name"
              style={{ padding: 8, marginTop: 1 }}
              onChange={handleChangeField}
              cy="field-name"
              onBlur={handleFieldNameChange}
              errorMessage={fieldNameValidation}
              invalid={!!fieldNameValidation}
              disabled={lockedField}
              onFocus={() => setEditing(true)}
              ref={fieldNameRef}
            />
          </div>
          <ActiveTableDisplayFormat
            updateColumn={handleChangeType}
            options={availableTypeOptions}
            cy="select-field-type"
            value={colDef?.type === "select" ? "string" : colDef.type}
            label="Field type"
            isDisabled={
              !!fieldNameValidation ||
              colDef.queryOnly ||
              activeTableUpdateLoading
            }
          />

          <ActiveTableDisplayFormat
            updateColumn={handleFormatChange}
            options={displayOptions}
            cy="display-style"
            value={"default"}
            label="Display Style"
            isDisabled={
              !!fieldNameValidation ||
              colDef.queryOnly ||
              activeTableUpdateLoading
            }
          />

          {colDef?.editable &&
          ["string", "select"].find((t) => t === colDef?.type) ? (
            <ActiveTableDisplayFormat
              updateColumn={handleEditStyleChange}
              options={editOptions}
              cy="select-edit-style"
              value={colDef.type === "select" ? "select" : "text"}
              label="Edit style"
              isDisabled={!!fieldNameValidation}
            />
          ) : null}
        </Container>
        <ActiveTableColumnSettingsPanel
          handleLocked={handleLocked}
          locked={!colDef?.editable}
          colDef={colDef}
          accessGroups={accessGroups}
          setAccessGroups={setAccessGroups}
          activeTableUpdateLoading={activeTableUpdateLoading}
        />
      </OuterContainer>
      {colDef?.type === "select" ? (
        <ActiveTableOptionsSettings colDef={colDef} setOptions={setOptions} />
      ) : null}
    </div>
  );
}

const editOptions = [
  {
    label: <Label icon="keyboard" text="Text entry" />,
    value: "text",
  },
  {
    label: <Label icon="list-alt" text="List select" />,
    value: "select",
  },
];

const displayOptions = [
  {
    label: <Label icon="text" text="Default" />,
    value: "default",
  },
];

const typeOptions = [
  {
    label: <Label icon="mobile-android-alt" text="Integer" />,
    value: "integer",
  },
  {
    label: <Label icon="mobile-android" text="Decimal" />,
    value: "decimal",
  },
  {
    label: <Label icon="toggle-on" text="Boolean" />,
    value: "boolean",
  },
  {
    label: <Label icon="calendar" text="Date" />,
    value: "date",
  },
  // {
  //   label: <Label icon="watch-calculator" text="Datetime" />,
  //   value: "datetime",
  // },
  {
    label: <Label icon="text-size" text="String" />,
    value: "string",
  },
  {
    label: <Label icon="text-width" text="Text (Deprecated)" />,
    value: "text",
    deprecated: true,
  },
  {
    label: <Label icon="file-invoice-dollar" text="Currency" />,
    value: "currency",
  },
  {
    label: <Label icon="image" text="Image" />,
    value: "image",
  },
  {
    label: <Label icon={faFilePdf} text="PDF" />,
    value: "api-pdf",
  },
  {
    label: <Label icon={faFileExcel} text="Excel" />,
    value: "api-excel",
  },
  {
    label: <Label icon={faFileWord} text="Word" />,
    value: "api-word",
  },
];
