import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  findFieldInOverrides,
  getReorderedTooltipRows,
} from "../../utils/tableEditorHelper";
import TooltipRow from "./TooltipRow";

export default function TooltipDND(props) {
  const { config, parentIndex, setChartState, chart, fields } = props;
  const { overrides, tooltipConfig } = chart;

  function updateTooltipRows(action, params) {
    const { key, value, index, name, result, tooltipRows } = params;

    let updatedOverrides;
    const newTooltipConfig = tooltipConfig.map((conf, tIndex) => {
      if (tIndex !== parentIndex) return conf;

      switch (action) {
        case "update":
          updatedOverrides = findFieldInOverrides(overrides, name)
            ? overrides
            : [...overrides, { name }];

          return {
            ...conf,
            tooltipRows: conf.tooltipRows.map((item, i) =>
              i === index ? { ...item, [key]: value } : item
            ),
          };

        case "remove":
          return {
            ...conf,
            tooltipRows: conf.tooltipRows.filter((_, i) => i !== index),
          };

        case "reorder":
          const reorderedRows = getReorderedTooltipRows(result, tooltipRows);
          return {
            ...conf,
            tooltipRows: reorderedRows,
          };

        default:
          return conf;
      }
    });

    setChartState({
      ...chart,
      tooltipConfig: newTooltipConfig,
      ...(updatedOverrides && { overrides: updatedOverrides }),
    });
  }

  return (
    <DragDropContext
      onDragEnd={(result) =>
        updateTooltipRows("reorder", {
          result,
          tooltipRows: config.tooltipRows,
        })
      }
    >
      <Droppable droppableId="droppable">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {(config.tooltipRows ?? []).map((row, index) => (
              <Draggable
                key={row.key + index}
                draggableId={(index + "-" + row.key).toString()}
                index={index}
              >
                {(provided, snapshot) => (
                  <TooltipRow
                    row={row}
                    key={row.key + index}
                    fields={fields}
                    overrides={overrides}
                    index={index}
                    onTooltipRowChange={updateTooltipRows}
                    removeTooltipRowItem={updateTooltipRows}
                    provided={provided}
                    snapshot={snapshot}
                    tooltipRows={config.tooltipRows}
                  />
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
