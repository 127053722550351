import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
  createRef,
} from "react";
import PropTypes from "prop-types";
import Table from "../../UI/Tables/Table/Table";
import TableContainer from "./TableContainer";
import Tr from "./Elements/Tr";
import ParentHeaders from "./ParentHeaders";
import ColumnHeaders from "./ColumnHeaders";
import TableMainCells from "./TableMainCells";
import FixedRowHeader from "./FixedRowHeader";
import conditionalRowsMapper from "./conditionalRowsMapper";
import { useDispatch } from "react-redux";
import { loadRowExpandChart } from "../../store/actions/dashboard/dashboard";
import ExpandedRowVisualization from "./Elements/ExpandedRowVisualization";
import HighLevelHeaders from "./HightLevelHeaders";
import styled from "@emotion/styled";
import { isNumber } from "lodash-es";
import ExpandRowIcon from "./Elements/ExpandRowIcon";
import { useWriteBacksContext, WRITE_BACKS_TYPES } from "./WriteBacksContext";
import IOButton from "../../UI/Form/Button/IOButton";
import WriteBacksCrmEditor from "./WriteBacks/WriteBacksCrmEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  geRowGroupKeyLinkData,
  getTextAlign,
  hideTableColumnsByColumnName,
  hideTableColumnsBySectionName,
  replaceToGroupDisplayKey,
} from "./functions/tableMapper";

import {
  buildDynamicFiltersQuery,
  buildRowIndexKey,
  convertFiltersToDateRange,
  getExpandableBoolean,
  getFilterPrefix,
  removeRowIndex,
} from "./functions/dynamicDrillDown";

import {
  checkExcludeFromDrilldownRow,
  rowIndexStrictComparison,
} from "./functions/getExpandedRow";
import ZoomInDrilldown from "./Elements/ZoomInDrilldown";
import { getMappingType } from "../../utils/getVisualizationLabel";
import formatter from "../../utils/formatters/formatter";
import BreakdownByPopup from "./Elements/BreakdownByPopup";
import ExpandAll from "./Elements/ExpandAll";
import TableTooltip from "./Elements/TableTooltip";
import TippyTooltipWrapper from "../Tooltip/TippyTooltipWrapper";
import { lightenDarkenColor } from "../../styles/colorConvertor";
import PinnedHeaders from "./Elements/PinnedHeaders";
import BatchRenderer from "../../etc/BatchRenderer";
import { useTheme } from "emotion-theming";
import { setGroupedRowBackgroundColor } from "./functions/tableCellHelper";

const GroupingColumn = styled.td(
  ({
    theme,
    textAlign,
    freezeLeft,
    freezeWidth,
    freezeNextColumn,
    background,
    dynamicWidth,
    isRowExpanded,
    zIndex = 15,
    maxColumnsWidth,
    expandedRowBackground,
    apiStyles,
  }) => `
  text-align: ${textAlign};
  position: ${freezeLeft ? "sticky" : "relative"};
  width: ${maxColumnsWidth || freezeWidth || dynamicWidth}px;
  
  top: 0;
  z-index: ${zIndex};
  left: 0;
  box-sizing: border-box;
  background: ${
    freezeLeft || freezeNextColumn
      ? theme.freezeBackground
      : background
      ? `rgba(0,0,0,${theme.type === "dark" ? 0.15 : 0.05})`
      : null
  };

  ${
    isRowExpanded &&
    `
      background: ${expandedRowBackground || theme.primary};
    `
  };
  ${apiStyles} !important;
  a {
      color: ${lightenDarkenColor(theme.primary, 60)};
    }

   ${
     freezeNextColumn &&
     `
      position: sticky;
      left: ${freezeWidth}px;
      top: 0;
      z-index: ${zIndex};
      width: ${maxColumnsWidth || dynamicWidth}px;
  `
   };
`
);

export default function TableView(props) {
  const {
    data,
    meta,
    rowGroupKey,
    subTitles,
    valueKeyAsSubTitle,
    hasNumeration,
    calculatedRows = [],
    freezeLeft,
    freezeWidth,
    totalRows,
    allowWrap,
    fixedRows,
    totalBold,
    rollup,
    exportId,
    totals,
    chart,
    subTotalRow,
    rowIndexes,
    setRowIndexes,
    totalsPositionTop,
    hideHeaders,
    offsetZebra,
    totalsChart,
    colors,
    segmentTitle,
    title,
    highlightedTotalRow,
    hoverRowEffectBorder,
    expandedTitle,
    activeTab,
    dateFilters,
    menuFilters,
    rowData,
    setRowData,
    dynamicBoldRows,
    headerColorConfig,
    maxColumnsWidth = {},
    dynamicDrilldowns,
    drilldownLevel,
    setDrilldownLevel,
    isLoading,
    fromFullDetails,
    groupedRowBackgroundColumn,
    ...rest
  } = props;
  const {
    hasRowExpand,
    rowExpandVisualizationParams,
    localRowExpandGrouping,
    showSearch,
  } = chart ?? {};

  const [tHeadHeight, setTHeadHeight] = useState(0);
  const rowRef = createRef(null);
  const tHeadRef = useRef(null);
  const theme = useTheme();

  useEffect(() => {
    if (tHeadRef.current && tHeadHeight !== tHeadRef.current.clientHeight) {
      setTHeadHeight(tHeadRef.current.clientHeight);
    }
  }, [tHeadHeight]);

  const subTitlesMemo = useMemo(() => {
    if (!!rest.hiddenGroupings?.length) {
      return hideTableColumnsBySectionName(
        data.headers,
        subTitles,
        rest.hiddenGroupings,
        rest.hiddenColumns
      );
    }

    if (!!rest.hiddenColumns?.length) {
      return hideTableColumnsByColumnName(subTitles, rest.hiddenColumns);
    }

    return subTitles;
  }, [data.headers, rest.hiddenColumns, rest.hiddenGroupings, subTitles]);
  const roughColumnCount = subTitlesMemo.flat().length;

  // backward compatibility
  const expandedParams =
    (dynamicDrilldowns
      ? rowExpandVisualizationParams[drilldownLevel.key]
      : rowExpandVisualizationParams) ?? {};

  const {
    expandIndexes,
    isDrillable,
    dynamicFilters,
    visualizationId,
    dynamicFilterValue,
    expandedRowBackground,
  } = expandedParams;

  const conditionalRows = calculatedRows.map(
    conditionalRowsMapper(data, rowGroupKey)
  );

  const dispatch = useDispatch();

  const [writeBacksRow, setWriteBacksRow] = useState(null);
  function closeCrmModal() {
    setWriteBacksRow(null);
  }
  const writeBacks = useWriteBacksContext();

  const isBolded = useCallback(
    (index, s, totalBold, row) => {
      if (dynamicBoldRows) {
        const data = (row.values || [])[0] ?? {};
        const { name, values = [], background } = dynamicBoldRows;
        const value = (data[name] || "").toString().trim();
        const isValueExist = values.includes(value);

        if (!isValueExist) {
          return;
        }

        return background ?? isValueExist;
      }

      return totalBold && inBoldedBlock();

      function inBoldedBlock() {
        return index > s.length - 1 - totalBold;
      }
    },
    [dynamicBoldRows]
  );

  const loadInnerTable = (filters, key, config) => {
    dispatch(
      loadRowExpandChart(filters, {
        ...chart,
        ...config,
        expanded: true,
        visualizationId: `${key}-${config.visualizationId}`,
      })
    );
  };

  const getExpandedRow = (row, index, config) => {
    const { dynamicFilters, dynamicFilterValue } = config ?? {};
    let newIndex = index;
    const rowIndexKey = buildRowIndexKey(
      row,
      dynamicFilters,
      dynamicFilterValue
    );

    const isNumeric = isNumber(rowIndexKey);

    // ignore index for numbers
    if (isNumeric) {
      newIndex = 0;
    }

    const expandedRow = rowIndexStrictComparison(
      rowIndexes,
      rowIndexKey,
      newIndex
    );

    return {
      expandedRow,
      rowIndexKey,
      newIndex,
    };
  };

  const expandRow = (row, index, config) => {
    const { isDrillable, dynamicFilters, dynamicFilterValue } = config ?? {};

    if ((!hasRowExpand || expandedTitle) && !isDrillable) {
      return;
    }

    const prefix = getFilterPrefix(chart); // Parameterized filter prefix
    const filters = buildDynamicFiltersQuery(
      row,
      dynamicFilters,
      dynamicFilterValue,
      prefix
    );

    // Some filters may represent aggregated values based on dates
    // so we need to convert them into date range format.
    const toDateRangeConverted = convertFiltersToDateRange(filters);

    const { expandedRow, rowIndexKey, newIndex } = getExpandedRow(
      row,
      index,
      config
    );

    // close row
    if (expandedRow) {
      setRowIndexes((rowIndexes) => removeRowIndex(rowIndexes, expandedRow));
      return;
    }

    setRowIndexes((prev) => [...prev, rowIndexKey + newIndex]);

    if (!localRowExpandGrouping) {
      loadInnerTable(toDateRangeConverted, rowIndexKey + newIndex, config);
    }
  };

  const onRowClick = (row, index) => {
    // backward compatibility
    if (dynamicDrilldowns) {
      setDrilldownLevel({ ...drilldownLevel, row, index });
    } else {
      expandRow(row, index, expandedParams);
    }
  };

  function getFormattedValue(value, column) {
    const type = getMappingType(meta?.fields, column);
    return formatter(value, type);
  }

  const tableMainCells = (row, index, rollup, hasRowExpand, tableRowIndex) => {
    const expanded =
      hasRowExpand &&
      !!rowIndexStrictComparison(rowIndexes, index, tableRowIndex);

    const { dynamicColumnsWidth = {}, rowGroupDisplayKey, linkColumns } = rest;
    const cellValue = replaceToGroupDisplayKey(
      row,
      rowGroupKey,
      rowGroupDisplayKey
    );

    function renderWithTooltip(child, key) {
      const currentKey = key ?? rowGroupKey;
      if (rest.tooltipConfig?.showOnColumn !== currentKey) {
        return child;
      }

      return (
        <TippyTooltipWrapper
          content={
            <TableTooltip
              row={(row.values ?? [])[0]}
              tooltipConfig={rest.tooltipConfig}
              meta={meta}
            />
          }
        >
          {child}
        </TippyTooltipWrapper>
      );
    }

    const link = geRowGroupKeyLinkData(linkColumns, rowGroupKey, row);

    return (
      <>
        {/* Include count in first column */}
        {hasNumeration && <td>{index + 1}</td>}

        {/* Need New Name -- suppressRowGroupKeyInHeader */}
        {!valueKeyAsSubTitle && (
          <>
            {renderWithTooltip(
              <GroupingColumn
                textAlign={getTextAlign(row[rowGroupKey])}
                freezeLeft={freezeLeft}
                freezeWidth={freezeWidth}
                background={freezeLeft}
                expandedRowBackground={expandedRowBackground}
                dynamicWidth={dynamicColumnsWidth[rowGroupKey]}
                isRowExpanded={expanded}
                maxColumnsWidth={maxColumnsWidth[rowGroupKey]}
                apiStyles={(row?.values ?? [])[0]?.style}
              >
                <ExpandRowIcon
                  i={0}
                  index={index}
                  rowIndexes={rowIndexes}
                  hasRowExpand={hasRowExpand}
                  tableRowIndex={tableRowIndex}
                />
                {link ? (
                  <a href={link} target="_new" data-cy="table-cell-link">
                    {getFormattedValue(cellValue, rowGroupKey)}
                  </a>
                ) : (
                  <span>{cellValue}</span>
                )}

                {rest.zoomInDrilldownConfig && (
                  <ZoomInDrilldown
                    hasRowExpand={hasRowExpand}
                    row={row}
                    i={0}
                    setRowData={setRowData}
                    attachedURLParameter={
                      rest.zoomInDrilldownConfig.attachedURLParameter
                    }
                  />
                )}
              </GroupingColumn>
            )}

            {/* add ability to add static columns in dynamic grouppings */}
            {(rest.rowGroupKeys || []).map((rowGroupKey) =>
              renderWithTooltip(
                <GroupingColumn
                  textAlign={getTextAlign(row[rowGroupKey])}
                  background={freezeLeft}
                  freezeNextColumn={rest.freezeNextColumn}
                  freezeWidth={freezeWidth}
                  dynamicWidth={dynamicColumnsWidth[rowGroupKey]}
                  isRowExpanded={expanded}
                  expandedRowBackground={expandedRowBackground}
                  zIndex={rest.freezeNextColumn ? 15 : 14}
                  key={rowGroupKey}
                  maxColumnsWidth={maxColumnsWidth[rowGroupKey]}
                  apiStyles={(row[rowGroupKey]?.values ?? [])[0]?.style}
                >
                  {getFormattedValue(row[rowGroupKey], rowGroupKey)}
                </GroupingColumn>,
                rowGroupKey
              )
            )}
          </>
        )}
        <TableMainCells
          {...rest}
          rowValues={row.values}
          subTitles={row.subTitleOverrides || subTitlesMemo}
          hasNumeration={hasNumeration}
          headers={data.headers}
          progressBarTotals={data.progressBarTotals}
          meta={meta}
          valueKeyAsSubTitle
          freezeWidth={freezeWidth}
          rollup={rollup}
          totals={totals}
          freezeLeft={rest.groupingKey ? false : freezeLeft}
          // we should not show expand icon on totals which calcs on FE
          hasRowExpand={hasRowExpand}
          rowIndexes={hasRowExpand ? rowIndexes : null}
          index={index}
          offsetZebra={offsetZebra}
          colors={colors}
          tableRowIndex={tableRowIndex}
          expandRow={expandRow}
          row={row}
          setRowData={setRowData}
          isRowExpanded={expanded}
          expandedRowBackground={expandedRowBackground}
          maxColumnsWidth={maxColumnsWidth}
        />
      </>
    );
  };

  const tableRows = [...data.rows, ...conditionalRows];
  const colSpan = nestedSubTitlesCount();
  const rows = fixedRows ? makeRowsForFixedFormat() : makeBasicRows();

  function nestedSubTitlesCount() {
    return subTitles.reduce((acc, curr) => acc + curr.length, 0);
  }

  function makeRowsForFixedFormat() {
    return fixedRows.map((row, index) => {
      if (row.type === "header") {
        return (
          <FixedRowHeader
            key={index}
            colSpan={colSpan}
            color={row.color}
            label={row.label}
          />
        );
      } else if (row.type === "match") {
        return matchRow();

        function matchRow() {
          const matchedRow = foundRowInData();

          return (
            matchedRow && (
              <Tr
                key={index}
                index={index}
                tHeadHeight={tHeadHeight}
                hideRowZebraBackground={rest.hideRowZebraBackground}
              >
                {tableMainCells(matchedRow, index, rollup)}
              </Tr>
            )
          );

          function foundRowInData() {
            return tableRows.find((r) => {
              return r.values[0][rowGroupKey] === row.value;
            });
          }
        }
      } else {
        return null;
      }
    });
  }

  function makeBasicRows() {
    const groupedRowsBackgroundObject = setGroupedRowBackgroundColor(
      theme,
      tableRows,
      groupedRowBackgroundColumn
    );

    const rowCallbacks = tableRows.map((row, index, s) => {
      const subTotal = row.values.find(
        (v) => v[subTotalRow?.groupingKey] === "Total"
      );

      const hasCommonTotalRow =
        totals && index === (totalsPositionTop ? 0 : s.length - 1);

      const isExpandable = getExpandableBoolean({
        index,
        hasCommonTotalRow,
        row,
        isDrillable,
        hasRowExpand,
        expandedTitle,
        expandIndexes,
      });

      const excluded = checkExcludeFromDrilldownRow(
        rest.excludeFromDrilldownSettings,
        row
      );

      const canExpandRow = isExpandable && !excluded;

      const rowIndexKey = buildRowIndexKey(
        row,
        dynamicFilters,
        dynamicFilterValue
      );

      const rowIndex = localRowExpandGrouping || showSearch ? "" : index;
      // need to find expanded row index from strict to low equality
      const expanded = rowIndexStrictComparison(
        rowIndexes,
        rowIndexKey,
        rowIndex
      );

      const mainCells = tableMainCells(
        row,
        rowIndexKey,
        null,
        canExpandRow,
        rowIndex
      );

      const highlightTotalFromBE = row.values.find((v) =>
        v[highlightedTotalRow]?.includes("Total")
      );
      const totalsFromBEOnTop =
        row.values.findIndex((v) =>
          v[highlightedTotalRow]?.includes("Total")
        ) === 0;

      const isTotalRow = (!colors && hasCommonTotalRow) || highlightTotalFromBE;

      const isPowerRow =
        rest.powerRowCondition &&
        row.values[0][rest.powerRowCondition.key] ===
          rest.powerRowCondition.value;

      const groupedRowBackground =
        groupedRowsBackgroundObject[
          row.values?.[0]?.[groupedRowBackgroundColumn]
        ];

      return () => (
        <React.Fragment key={row.uniqueRowUuid ?? "row-" + index}>
          <Tr
            index={index}
            totalRow={isTotalRow}
            totalsFromBEOnTop={totalsFromBEOnTop}
            className={isTotalRow ? "cy-totals-row" : ""}
            forceBold={isBolded(index, s, totalBold, row)}
            // we should not expand row on totals which calcs on FE
            onClick={() => canExpandRow && onRowClick(row, rowIndex)}
            hasRowExpand={canExpandRow}
            background={props.clearBackground ? 0 : expanded}
            expandedRowBackground={expanded && expandedRowBackground}
            bordered={!!subTotal}
            sticky={rest.stickyHeaders}
            row={row}
            pinnedRows={rest.pinnedRows}
            powerRow={isPowerRow}
            hoverRowEffectBorder={hoverRowEffectBorder}
            totals={totals}
            tHeadHeight={tHeadHeight}
            hideRowZebraBackground={rest.hideRowZebraBackground}
            dataValues={(tableRows ?? []).flatMap((row) => row?.values)}
            ref={rowRef}
            groupedRowBackground={groupedRowBackground}
          >
            {(writeBacks.type === WRITE_BACKS_TYPES.crm ||
              writeBacks.type === WRITE_BACKS_TYPES.survey) && (
              <td
                style={{
                  verticalAlign: "middle",
                  paddingTop: 2,
                  paddingBottom: 2,
                }}
              >
                <IOButton
                  type="button"
                  fit
                  style={{ padding: 7, gap: 5 }}
                  onClick={() => {
                    setWriteBacksRow(row);
                  }}
                >
                  <FontAwesomeIcon icon={["fas", "pencil-alt"]} pull="left" />
                  View
                </IOButton>
              </td>
            )}

            {mainCells}
          </Tr>
          {drilldownLevel?.index === index && (
            <BreakdownByPopup
              drilldownLevel={drilldownLevel}
              expandRow={expandRow}
              rowExpandVisualizationParams={rowExpandVisualizationParams}
              setDrilldownLevel={setDrilldownLevel}
              getExpandedRow={getExpandedRow}
            />
          )}
          {expanded && (
            <ExpandedRowVisualization
              config={{
                ...chart,
                ...expandedParams,
                visualizationId: `${expanded}-${visualizationId}`,
              }}
              setRowIndexes={setRowIndexes}
              title={title || segmentTitle}
              activeTab={activeTab}
              dateFilters={dateFilters}
              menuFilters={menuFilters}
              breakdownBy={drilldownLevel.key}
              row={row}
              pinnedRows={rest.pinnedRows}
              tHeadHeight={tHeadHeight}
              totals={totals}
              hasParentHeaders={data.headers}
              dataValues={(tableRows ?? []).flatMap((row) => row?.values)}
              rowRef={rowRef}
            />
          )}
        </React.Fragment>
      );
    });

    // Full details does a lot of rerendering when pressing controls, so
    // it's better to never use batching there.
    return rest.enableBatching && !fromFullDetails ? (
      <BatchRenderer
        itemCallbacks={rowCallbacks}
        batchSize={calculateRowRenderBatchSize(roughColumnCount)}
      />
    ) : (
      rowCallbacks.map((rc) => rc())
    );
  }

  const headerProps = {
    headers: data.headers,
    rows: data.rows,
    hasNumeration,
    subTitles: subTitlesMemo,
    meta,
    freezeLeft,
    freezeWidth,
    allowGroupsRightBorder: rest.allowGroupsRightBorder,
    staticGroupingKeys: rest.staticGroupingKeys,
    stickyHeaders: rest.stickyHeaders,
    parentHeaderPrefix: rest.parentHeaderPrefix,
    dynamicHighLevelKeysConfig: rest.dynamicHighLevelKeysConfig,
    postfixes: data.postfixes,
    hiddenGroupings: rest.hiddenGroupings,
    dynamicSubTitleKeys: rest.dynamicSubTitleKeys ?? subTitlesMemo,
    staticPostfixColumn: rest.staticPostfixColumn,
    isGroupingSortable: rest.isGroupingSortable,
    sortDynamicGrouping: rest.sortDynamicGrouping,
    groupingKeysAlign: rest.groupingKeysAlign,
    maxColumnsWidth,
    parentHeaderSort: rest.parentHeaderSort,
    setParentHeaderSort: rest.setParentHeaderSort,
    rowGroupKey,
    dateFilters,
  };

  return (
    <div style={{ width: "100%" }}>
      <TableContainer
        freezeLeft={freezeLeft}
        freezeWidth={freezeWidth}
        overflowX={rest.overflowX}
        expanded={rest.expanded}
        stickyHeaders={rest.stickyHeaders}
        isGlobalSticky={!!rest.offsetTop}
        scrollConfig={rest.scrollConfig}
      >
        <Table tableId={exportId} noWrap={!allowWrap}>
          <thead
            style={{
              visibility: hideHeaders ? "collapse" : "visible",
              ...(rest.stickyHeaders && {
                position: "sticky",
                top: rest.offsetTop,
                zIndex: 16,
              }),

              ...(rest.hideSubTitleHeaders && { lineHeight: 0, opacity: 0 }),
            }}
            ref={tHeadRef}
          >
            <PinnedHeaders
              freezeLeft={freezeLeft}
              freezeWidth={freezeWidth}
              pinnedHeader={rest.pinnedHeader}
              hasRowExpand={hasRowExpand}
            />
            <HighLevelHeaders
              highLevelHeaders={data.highLevelHeaders}
              {...headerProps}
            />
            <ParentHeaders {...headerProps} />
            <ColumnHeaders
              {...headerProps}
              {...rest}
              rollup={rollup}
              dynamicHeaderFormat={rollup?.dynamicHeaderFormat}
              expanded={rest.expanded}
              headerColor={rest.headerColor}
              headerColorConfig={headerColorConfig}
              beforeCells={
                writeBacks.type === WRITE_BACKS_TYPES.crm && (
                  <th
                    style={{
                      verticalAlign: "middle",
                      paddingTop: 2,
                      paddingBottom: 2,
                    }}
                  >
                    {writeBacks.canCreate && (
                      <IOButton
                        type="button"
                        fit
                        style={{ padding: 7, gap: 5 }}
                        onClick={() => {
                          setWriteBacksRow({});
                        }}
                        standard
                      >
                        <FontAwesomeIcon icon={["fal", "plus"]} pull="left" />
                        {writeBacks.customAddLabel
                          ? writeBacks.customAddLabel
                          : "Add"}
                      </IOButton>
                    )}
                  </th>
                )
              }
            />
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </TableContainer>

      {writeBacksRow && writeBacks.type === WRITE_BACKS_TYPES.crm ? (
        <WriteBacksCrmEditor
          config={writeBacks}
          closeModal={closeCrmModal}
          row={writeBacksRow}
          overrides={chart.overrides}
          visualizationId={chart.uuid}
          meta={meta}
        />
      ) : null}

      <ExpandAll
        tableRows={tableRows}
        rowIndexes={rowIndexes}
        hasRowExpand={hasRowExpand}
        onRowClick={onRowClick}
        setRowIndexes={setRowIndexes}
        totalsPositionTop={totalsPositionTop}
        totals={totals}
        isLoading={isLoading}
        isDrillable={isDrillable}
        localRowExpandGrouping={localRowExpandGrouping}
        excluded={rest.excludeFromDrilldownSettings}
      />
    </div>
  );
}

TableView.propTypes = {
  data: PropTypes.shape({
    headers: PropTypes.array,
    rows: PropTypes.array.isRequired,
  }),
  subTitles: PropTypes.array,
  valueKeyAsSubTitle: PropTypes.bool,
};

export function calculateRowRenderBatchSize(columnCount) {
  return Math.ceil(500 / Math.max(1, columnCount));
}
