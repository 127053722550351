import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import LayoutActions from "./LayoutActions";
import StaticGroupingKeys from "./StaticGroupingKeys";
import SubTitles, { Column } from "./SubTitles";
import RepeatingGroups from "./RepeatingGroups";
import { useTheme } from "emotion-theming";
import RawRowsHeaders from "./RawRowsHeaders";
import Preview from "../Preview";
import { getColumnStyles } from "../utils/tableEditorHelper";
import AutomationSyncWarning from "./AutomationSyncWarning";
import QueryFieldsWarningLink from "./QueryFieldsWarningLink";

const Container = styled.div(
  ({ theme, hideMenu }) => `
  display: flex;
  width: calc(100% - ${hideMenu ? 110 : 420}px);
  font-size: 14px;
  overflow-x: auto;
  padding: 20px 20px 20px 30px;
  flex-direction: column;
  position: relative;
`
);

const TitleDescription = styled.div(
  ({ offsetTop, offsetBottom, italic }) => `
  display: flex;
  font-size: 12px;
  font-weight: 600;
  ${offsetTop && `margin-top: ${offsetTop}px`};
  ${offsetBottom && `margin-bottom: ${offsetBottom}px`};
  ${italic && `font-style: italic;`};
  width: fit-content;
`
);

const Section = styled.div(
  ({ theme, shake, isActive, width, hideMenu, color }) => `
  height: 30px;
  line-height: 25px;
  padding: 5px 10px;
  text-align: center;
  background: ${theme.blueGray.blueGray500};
  color: ${color};

  & > div:first-of-type {
    color: ${color};
  }

  & > svg:first-of-type {
    color: ${theme.notification.errorMain};
    position: absolute;
    top: -12px;
    right: -15px;
    z-index: 1;
    cursor: pointer;
  }

  ${
    shake &&
    `
    animation: shake 0.2s;
    animation-iteration-count: infinite;
  `
  };

  ${
    isActive &&
    `
    background: ${theme.primary};
    color: white;
  `
  }

  @keyframes shake {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(0.5deg); }
    50% { transform: rotate(0deg); }
    75% { transform: rotate(-0.5deg); }
    100% { transform: rotate(0deg); }
  }

  // 20px padding from left and right 10px
  ${
    width &&
    `
    width: ${width - 20}px;
    position: relative;
    & > span {
      position: fixed;
      left: calc(50% + ${hideMenu ? 0 : 165}px);
    }
  `
  }
`
);

const Headers = styled.div`
  display: flex;
  gap: 1rem;
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 20px;
`;

export default function Layout({
  chart,
  hideMenu,
  setChartState,
  selectedColumn,
  setSelectedColumn,
  removeColumn,
  removeDynamicSubTitleColumn,
  setHideMenu,
  setNavigationState,
  showPreview,
  previewChart,
  fields,
  query,
  isFiltering,
  isParameterized,
  chartName,
  block,
  visualizationUuid,
  joinConditinFields,
  levelIndicator,
}) {
  const {
    staticGroupingKeys,
    subTitles,
    groupingKey,
    emptyFreezeLeft,
    freezeWidth,
  } = chart;
  const [removeGroupingEffect, setRemoveGroupingEffect] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    setHideMenu(false);
  }, [setHideMenu]);

  const innerProps = {
    Section,
    setChartState,
    selectedColumn,
    setSelectedColumn,
    removeColumn,
    removeDynamicSubTitleColumn,
    setSelectedGroup,
    removeGroupingEffect,
    selectedGroup,
    setNavigationState,
    fields,
    query,
    joinConditinFields,
    isParameterized,
    showPreview,
    ...chart,
  };

  const justSubTitles = !staticGroupingKeys && !groupingKey && subTitles;
  const hasAnyGroupings = groupingKey || staticGroupingKeys;

  const preview = (
    <>
      <TitleDescription offsetTop={40} offsetBottom={20} italic>
        {chartName}
      </TitleDescription>
      <TitleDescription offsetBottom={10}>Table preview</TitleDescription>
      <Preview
        chart={chart}
        showPreview={showPreview}
        previewChart={previewChart}
        levelIndicator={levelIndicator}
      />
    </>
  );

  if (isFiltering) {
    return <Container hideMenu={hideMenu}>{preview}</Container>;
  }

  return (
    <Container hideMenu={hideMenu}>
      <LayoutActions
        {...innerProps}
        chart={chart}
        setRemoveGroupingEffect={setRemoveGroupingEffect}
      />
      <AutomationSyncWarning
        chart={chart}
        block={block}
        visualizationUuid={visualizationUuid}
      />
      <br />

      <QueryFieldsWarningLink
        isParameterized={isParameterized}
        fields={fields}
        queryUuid={query?.uuid}
      />
      <br />

      <TitleDescription>Header layout</TitleDescription>
      <Headers>
        {emptyFreezeLeft && (
          <Column
            data-cy="layout-column-button"
            style={getColumnStyles(hasAnyGroupings, theme, freezeWidth)}
          >
            Empty Frozen Column
          </Column>
        )}
        <RawRowsHeaders
          chart={chart}
          setChartState={setChartState}
          fields={fields}
          joinConditinFields={joinConditinFields}
          showPreview={showPreview}
        />
        {groupingKey && <RepeatingGroups {...innerProps} chart={chart} />}
        {staticGroupingKeys && (
          // static grouping keys here will render subtitles also
          <StaticGroupingKeys {...innerProps} chart={chart} />
        )}
        {justSubTitles && <SubTitles {...innerProps} chart={chart} />}
      </Headers>
      {preview}
    </Container>
  );
}
