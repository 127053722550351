import { format as dateFormat } from "date-fns";
import { dateFromWeek } from "./dateFunc";

export default (convertToDate) => (arr) => {
  if (!convertToDate) return arr;
  const { key, format } = convertToDate;
  return arr.map((v) => ({ ...v, [key]: converted(v[key], format) }));
};

const converted = (val, format) => {
  const [year, week] = val.split(" ");
  const stringFormat = format && format === "date" ? "yyyy-MM-dd" : "M/dd";
  return dateFormat(dateFromWeek(week.substr(1, 2), year), stringFormat);
};
