import React, { useRef, useState } from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { lightenDarkenColor } from "../../../styles/colorConvertor";
import { format } from "date-fns";
import SimpleTooltip from "../../../UI/SimpleTooltip/SimpleTooltip";
import { absoluteDate } from "../../../utils/dates/dateFunc";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import EditableMenu from "./EditableMenu";

const ThContainer = styled.th(
  ({
    background,
    theme,
    pointer,
    sorting,
    freeze,
    freezeWidth,
    expanded,
    borderRight,
    headerColor,
    stickyHeaders,
    stickyTop,
    showEditMenu,
    hideSubTitleHeaders,
    freezeNextColumn,
    maxColumnsWidth,
    textColor,
    enumBackground,
    hideColumnZebraBackground,
  }) => `
    background: ${
      hideColumnZebraBackground
        ? theme.background.primary
        : headerColor
        ? theme.primary
        : background
        ? `rgba(0,0,0,${
            background === "none" ? 0 : theme.type === "dark" ? 0.15 : 0.05
          })`
        : ""
    };
    color: ${headerColor ? "rgba(255, 255, 255, 0.8)" : "inherit"};
    font-size: 12px;
    position: relative;
    user-select: none;
    box-sizing: border-box;
    &:hover {
        color: ${pointer ? lightenDarkenColor(theme.primary, 60) : null};
    }
    cursor: ${pointer ? "pointer" : null};
    border-right: ${
      borderRight ? `4px solid ${theme.divider} !important` : "none"
    };
    ${expanded && `width: ${freezeWidth}px`};
    ${
      stickyHeaders &&
      !hideColumnZebraBackground &&
      `
        background: ${
          background !== 0 ? theme.stickyBackground : theme.background.primary
        };
      `
    };
    ${
      freeze &&
      `
        min-width: ${freezeWidth}px;
        width: ${freezeWidth}px;
        position: sticky;
        left: ${freezeNextColumn ? freezeWidth : 0}px;
        top: ${stickyTop || 0}px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 15;
        background: ${background === 0 && theme.background.primary};
    `
    };
    ${
      sorting &&
      `
        color: ${lightenDarkenColor(theme.primary, 60)};
    `
    };

    ${
      showEditMenu &&
      `
      background: ${theme.primary};
      color: white;
    `
    };
    ${
      hideSubTitleHeaders &&
      `
        height: 0px !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
      `
    };
    ${
      maxColumnsWidth &&
      `
        min-width: ${maxColumnsWidth}px;
        max-width: ${maxColumnsWidth}px;
        white-space: normal !important;
      `
    };
    ${textColor && `color: ${textColor};`};

    ${
      enumBackground?.asHeaderBackground &&
      `
        background: ${enumBackground.color};
        color: white;
      `
    };
`
);

const SortContainer = styled.div`
  text-align: center;
  path {
    fill: ${(props) => lightenDarkenColor(props.theme.primary, 60)};
  }
`;

const Description = styled.div`
  width: 150px;
  white-space: pre-wrap;
`;

const EditableIcon = styled.span(
  ({ theme, show, showEditMenu }) => `
  opacity: ${show && !showEditMenu ? 1 : 0};
  position: absolute;
  top: 5px;
  right: 0;
  cursor: pointer;
  &:hover {
    color: ${theme.text.primary};
  }
`
);

const formatDate = (str, dateFormat) => {
  const d = absoluteDate(str);
  return isNaN(d.getTime()) ? str : format(d, dateFormat);
};

export default React.memo(function Th(props) {
  const displayText = props.dynamicHeaderFormat
    ? formatDate(props.children, props.dynamicHeaderFormat)
    : props.children;

  const [showEditIcon, setShowEditIcon] = useState(false);
  const [showEditMenu, setShowEditMenu] = useState(false);
  const ref = useRef(null);

  const minWidth = props.dynamicColumnsWidth || props.generalColumnsWidth;

  const withDefinition = props.definition ? (
    <SimpleTooltip
      usePortal
      label={
        <Description dangerouslySetInnerHTML={{ __html: props.definition }} />
      }
      position="top"
      useTooltipBody
    >
      {displayText}
    </SimpleTooltip>
  ) : (
    displayText
  );

  useOnClickOutside(ref, () => setShowEditMenu(false));

  const withEditElement = !!(props.editable && props.fromFullDetails) ? (
    <SimpleTooltip
      usePortal
      label={<EditableMenu {...props} ref={ref} />}
      position="top-right"
      click
      initialOffset={{ top: -30 }}
      useTooltipBody
      hideOnScroll
      hideOnScrollCallback={() => setShowEditMenu(false)}
    >
      <EditableIcon
        show={showEditIcon}
        onClick={() => setShowEditMenu(true)}
        showEditMenu={showEditMenu}
      >
        <FontAwesomeIcon icon={["far", "edit"]} />
      </EditableIcon>
    </SimpleTooltip>
  ) : null;

  function configOverride() {
    return (
      props.headerColorConfig &&
      props.headerColorConfig.find((c) => c.subTitle === props.column)?.color
    );
  }

  return (
    <ThContainer
      onClick={props.onClick}
      colSpan={props.colSpan}
      background={props.background}
      headerColor={props.headerColor}
      pointer={props.pointer}
      sorting={props.sorting}
      freeze={props.freeze}
      freezeWidth={props.freezeWidth}
      expanded={props.expanded}
      textColor={configOverride()}
      borderLeft={!props.freeze && props.borderLeft}
      borderRight={!props.freeze && props.borderRight}
      style={{
        ...((!displayText || !props.background) && {
          display: "table-cell",
        }),
        ...(!!(minWidth && !props.freeze) && { minWidth }),
        ...(props.textAlign && { textAlign: props.textAlign }),
        ...(props.strictWidth && {
          width: props.strictWidth + "px",
          whiteSpace: "normal",
        }),
        ...(props.isHorizontalTotal && {
          background: "black",
          color: "white",
        }),
      }}
      stickyHeaders={props.stickyHeaders}
      stickyTop={props.stickyTop}
      onMouseOver={() => setShowEditIcon(true)}
      onMouseOut={() => setShowEditIcon(false)}
      showEditMenu={showEditMenu}
      hideSubTitleHeaders={props.hideSubTitleHeaders}
      freezeNextColumn={props.freezeNextColumn}
      maxColumnsWidth={props.maxColumnsWidth}
      enumBackground={props.enumBackground}
      hideColumnZebraBackground={props.hideColumnZebraBackground}
    >
      {props.sorting ? (
        <SortContainer>
          <FontAwesomeIcon
            size="sm"
            icon={[
              "fas",
              `sort-amount-${props.sortOrder === "DESC" ? "up" : "down-alt"}`,
            ]}
          />
        </SortContainer>
      ) : null}
      {withDefinition}
      {withEditElement}
    </ThContainer>
  );
});
