import { stringify } from "qs";
import octane from "../octane";
import { cloneDeep, isObject, set } from "lodash-es";

export async function downloadFileFromOctane(fileUrl, signal) {
  const queryString = stringify({
    path: fileUrl,
  });

  return octane.get(`/api/warehouse-image?${queryString}`, {
    responseType: "arraybuffer",
    signal,
  });
}

/**
 * Source: https://stackoverflow.com/a/64908345/1381550
 *
 * @param {ArrayBuffer} data
 * @param {string} filename
 */
export function downloadFile(data, filename) {
  const a = document.createElement("a"); // Create "a" element
  const blob = new Blob([data], { type: "binary/octet-stream" }); // Create a blob (file-like object)
  const url = URL.createObjectURL(blob); // Create an object URL from blob
  a.setAttribute("href", url); // Set "a" element link
  a.setAttribute("download", filename); // Set download filename
  a.click(); // Start downloading
}

export function convertJsonDataToMultipartFormData(payload) {
  const formData = new FormData();
  for (const [key, value] of Object.entries(payload ?? {})) {
    recursivelyAppendToFormData(formData, key, value);
  }
  return formData;
}

function recursivelyAppendToFormData(formData, key, value) {
  if (!value || typeof value !== "object" || value instanceof File) {
    formData.append(key, value);
    return;
  }
  const entries = Object.entries(value);
  for (const [innerKey, value] of entries) {
    recursivelyAppendToFormData(formData, `${key}[${innerKey}]`, value);
  }
}

/**
 * Converts JSON data to FormData, but any values that are not files all get
 * put into __json_data as JSON. The IO API has some endpoints that check for
 * this key, and recursively merges it with the form data.
 * That way we can have both file uploads and precise types at the same time,
 * because normally any form data value can only either be a string or file.
 *
 * @param payload
 * @returns {FormData}
 */
export function convertJsonDataToMultipartFormDataAndJson(payload) {
  const jsonPayload = cloneDeep(payload);
  const filePayload = {};

  recursivelySeparate(jsonPayload, []);

  return convertJsonDataToMultipartFormData({
    ...filePayload,
    __json_data: JSON.stringify(jsonPayload),
  });

  function recursivelySeparate(object, keys) {
    if (!isObject(object)) {
      return;
    }
    for (const [key, value] of Object.entries(object)) {
      const currentKeys = [...keys, key];
      if (value instanceof File) {
        set(filePayload, currentKeys, value);
        delete object[key];
      } else if (isObject(value)) {
        recursivelySeparate(value, currentKeys);
      }
    }
  }
}
