import ColorPicker from "../../../../UI/ColorPicker/ColorPicker";
import Flex from "../../../../UI/Flex/Flex";
import Select from "../../../../UI/Form/Select/Select";

const initialColor = "#986111";

export default function TooltipAdditionalSettings(props) {
  const {
    showIfColumnValueExists,
    parentIndex,
    background,
    chart,
    setChartState,
    fields,
  } = props;

  function updateTooltipConfig(action, params) {
    const { option, color } = params;

    setChartState({
      ...chart,
      tooltipConfig: chart.tooltipConfig.map((conf, index) =>
        index === parentIndex
          ? {
              ...conf,
              ...(action === "updateShowOnly" && {
                showIfColumnValueExists: option?.name ?? null,
                background: option?.name ? initialColor : null,
              }),
              ...(action === "updateBackground" && {
                background: color,
              }),
            }
          : conf
      ),
    });
  }

  return (
    <>
      <br />
      <Select
        options={fields}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.name}
        simpleValue={showIfColumnValueExists}
        onChange={(option) =>
          updateTooltipConfig("updateShowOnly", {
            option,
            initialColor,
          })
        }
        isClearable
        label="Show only when column has value"
      />

      <br />
      {showIfColumnValueExists && (
        <>
          <Flex alignItems="center">
            <ColorPicker
              initialColor={background ?? initialColor}
              onChangeComplete={(color) =>
                updateTooltipConfig("updateBackground", {
                  color,
                })
              }
            />
            <span style={{ marginLeft: 10 }}>Cell background color</span>
          </Flex>
          <br />
        </>
      )}
    </>
  );
}
