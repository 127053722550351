import styled from "@emotion/styled";
import Switcher from "../../../../UI/Switcher/Switcher";
import { scrollbarDe } from "../../../../styles/styledSnippets";
import TooltipDependencySettings from "./TooltipDependencySettings";
import React from "react";
import { useTheme } from "emotion-theming";
import IOButton from "../../../../UI/Form/Button/IOButton";
import Flex from "../../../../UI/Flex/Flex";
import TooltipDND from "./TooltipDND";

const Container = styled.div(
  ({ theme }) => `
  overflow-y: auto;
  height: calc(100vh - 50px);
  ${scrollbarDe(theme)};
  margin: 5px 0px;
  padding: 15px;
`
);

export default function TooltipsSettings(props) {
  const { chart, setChartState, fields } = props;
  const { tooltipConfig } = chart;
  const theme = useTheme();

  function enableDisableTooltip() {
    setChartState({
      ...chart,
      tooltipConfig: tooltipConfig ? null : [tooltip],
    });
  }

  function addNewTooltip() {
    setChartState({
      ...chart,
      tooltipConfig: [...tooltipConfig, tooltip],
    });
  }

  return (
    <Container style={{}}>
      <div>Tooltip configuration</div>
      <br />
      <Flex justifyContent="space-between">
        <Switcher
          rightLabel="Enable"
          checked={!!tooltipConfig}
          handleSwitchChange={enableDisableTooltip}
        />

        <IOButton
          add
          smallPadding
          outline
          disabled={!tooltipConfig}
          onClick={addNewTooltip}
        >
          Add Config
        </IOButton>
      </Flex>

      {(tooltipConfig ?? []).map((config, parentIndex) => (
        <React.Fragment key={config.showOnColumn + parentIndex}>
          {/* Condition column to show */}
          <TooltipDependencySettings
            tooltipConfig={config}
            setChartState={setChartState}
            chart={chart}
            fields={fields}
            rows={config.tooltipRows}
            parentIndex={parentIndex}
          />

          {/* Drag and drop tooltip row items */}
          <TooltipDND
            config={config}
            parentIndex={parentIndex}
            setChartState={setChartState}
            chart={chart}
            fields={fields}
          />

          <hr
            color={theme.background.secondary}
            style={{ margin: "10px 0px" }}
          />
        </React.Fragment>
      ))}
      <br />
    </Container>
  );
}

const tooltip = { showOnColumn: "", tooltipRows: [] };
