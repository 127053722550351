import React from "react";
import PropTypes from "prop-types";
import { format, isValid } from "date-fns";
import { format as d3Format } from "d3-format";
import PositionCircle from "./PositionCircle";
import getVisualizationLabel from "../../utils/getVisualizationLabel";
import { tooltipOffsets } from "../../utils/constants/constants";
import formatter from "../../utils/formatters/formatter";
import styled from "@emotion/styled";
import labelFunc from "./CircleTooltip";

const Label = styled.text(
  ({ theme, x, y }) => `
  transform: translate(${x}, ${y});
  font-size: 10px;
  fill: ${theme.text.secondary};
`
);

export default function PositionCirclesPlaced(props) {
  const {
    xScale,
    yScale,
    markerKey,
    xKey,
    yKey,
    radius,
    xLabel,
    yLabel,
    color,
    bands,
    lineBands,
    onZoom,
    barWidth,
    setTooltip,
    borderSize,
    meta,
    noDate,
    xOffset = 0,
    yRightAxisFormat,
    tooltipDateFormat,
    lineLabelFormat,
    lineLabelPrecision,
    lineLabelUnit = "",
    bandwidth = 0,
    segment,
    showLabel,
    activeValueKey,
    tooltipForceType,
    fiscalQuarterStartOffset,
    noDateObject,
    labelFormat,
    skipTooltipConfig,
  } = props;

  const data = bands
    ? props.data.filter((d) => d[markerKey || xKey])
    : props.data;

  const xPos = (d) => {
    return bands || lineBands
      ? xScale(d[xKey])
      : xScale(noDate ? d[xKey] : new Date(d[xKey]));
  };

  const yPos = (d) => yScale(d[yKey]) - (bands ? 8 : 0);
  const getLabelAndType = labelFunc(meta?.fields);

  const getFormattedValue = (value, type) => {
    if (lineLabelPrecision) {
      if (!value) return "--";
      return value.toFixed(lineLabelPrecision) + lineLabelUnit;
    }

    if (lineLabelFormat) {
      return d3Format(lineLabelFormat)(value);
    }

    if (yRightAxisFormat) {
      return formatter(value, yRightAxisFormat);
    }
    return value;
  };

  const getXPos = (d) => {
    const pos = xPos(d) + (barWidth || 0) / 2 + xOffset + bandwidth;
    return isNaN(pos) ? 0 : pos;
  };

  const getYPos = (d) => {
    return (
      yPos(d) -
      (!barWidth ? 0 : bands ? 0 : d[markerKey] && d[markerKey].mood ? 16 : 8)
    );
  };

  const getOffsetY = (d, i) => {
    return i === 0 || data[i - 1].value < d.value ? 10 : -14;
  };

  const showTooltipInfo = (d) => {
    const res = [
      {
        key: activeValueKey || yLabel,
        label:
          getLabelAndType(segment, activeValueKey || yLabel)?.label || yLabel, // Make real
        value: getFormattedValue(
          d[activeValueKey] || d.value,
          tooltipForceType ||
            getLabelAndType(segment, activeValueKey || yLabel).type
        ),
        type:
          lineLabelPrecision ?? lineLabelFormat ?? yRightAxisFormat
            ? null
            : tooltipForceType ||
              getLabelAndType(segment, activeValueKey || yLabel).type,
      },
    ];

    if (!noDateObject) {
      res.push({
        key: xLabel,
        label: getVisualizationLabel(meta.fields, xLabel),
        value: tooltipDateFormat
          ? formatter(
              d.xValue,
              tooltipDateFormat,
              null,
              null,
              null,
              fiscalQuarterStartOffset
            )
          : lineBands
          ? d.xValue
          : isValid(d.xValue)
          ? format(d.xValue, "MM/dd/yyyy")
          : d.xValue,
        type: "string",
      });
    }

    return res;
  };

  return data.map((d, i) => (
    <React.Fragment key={"circle-" + i}>
      <PositionCircle
        cx={getXPos(d)}
        cy={getYPos(d)}
        radius={radius}
        color={color}
        mood={d[markerKey] && d[markerKey].mood}
        onClick={() =>
          onZoom && d[markerKey] && d[markerKey].mood ? onZoom(d) : null
        }
        onMouseOver={() =>
          setTooltip({
            x: getXPos(d),
            y: getYPos(d) - 5 + tooltipOffsets.y,
            tooltip: showTooltipInfo(d),
            allValues: d,
            skipTooltipConfig,
          })
        }
        borderSize={borderSize}
        onMouseOut={() => setTooltip(null)}
      />
      {showLabel && (
        <Label x={getXPos(d) - 20} y={getYPos(d) - getOffsetY(d, i)}>
          {formatter(
            d.value,
            labelFormat ||
              getLabelAndType(segment, activeValueKey || yLabel).type,
            lineLabelPrecision,
            null,
            null,
            fiscalQuarterStartOffset
          )}
        </Label>
      )}
    </React.Fragment>
  ));
}

PositionCirclesPlaced.propTypes = {
  xScale: PropTypes.func,
  yScale: PropTypes.func,
  markerKey: PropTypes.string,
  xKey: PropTypes.string,
  yKey: PropTypes.string,
  data: PropTypes.array,
  color: PropTypes.string,
  bands: PropTypes.bool,
  onZoom: PropTypes.func,
};
