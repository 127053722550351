import styled from "@emotion/styled";
import React, { useMemo } from "react";
import Input from "../../../../../UI/Form/Input/Input";
import Select from "../../../../../UI/Form/Select/Select";
import {
  aggregations,
  columnFormats,
} from "../../../../../utils/constants/constants";
import { omit, debounce } from "lodash-es";
import MathOperations from "./MathOperations";
import { getNestedFormat, getOverrideByName, isTextValue } from "../helper";
import Checkbox from "../../../../../UI/Form/Checkbox/Checkbox";
import { Note } from "../../General/LimitRows/LimitRows";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ColumnLinkSelector from "./ColumnLinkSelector";
import HideNullableColumns from "./HideNullableColumns";

const Fields = styled.div`
  display: flex;
  justify-content: space-between;
`;

export function getAggregationPrefix(aggregation) {
  return (
    aggregations.find(
      (aggr) =>
        aggr.value === aggregation ||
        aggr.label === aggregation ||
        aggr.prefix === aggregation
    )?.prefix ?? ""
  );
}

export default function RegularColumn({
  overrides,
  selectedColumn,
  setChartState,
  setSelectedColumn,
  theme,
  query,
  rawRows,
  subTitles,
  fields,
  chart,
}) {
  const {
    column,
    subIndex,
    mapping,
    name,
    aggregation,
    index,
    isParameterized,
    hidden,
  } = selectedColumn;

  const changeColumnAggregation = (option) => {
    function changeOverrideAggregation(override) {
      const { value } = option;
      const mathOperation = override.ops;

      if (!value) {
        return {
          ...omit(override, "aggregation"),
          ops: mathOperation ? omit(mathOperation, "aggregation") : null,
        };
      }

      return mathOperation
        ? {
            ...omit(override, "aggregation"),
            ops: { ...mathOperation, aggregation: value },
          }
        : { ...override, aggregation: value };
    }

    setChartState({
      ...chart,
      overrides: chart.overrides.map((override) =>
        override.name === name ? changeOverrideAggregation(override) : override
      ),
      ...(chart.subTitles && {
        subTitles: chart.subTitles.map((subTitle, i) =>
          subTitle.map((sub, j) => {
            if (subIndex === i && sub === column && j === index) {
              return option.prefix + name;
            }

            return sub;
          })
        ),
      }),
      ...(chart.dynamicSubTitleKeys && {
        dynamicSubTitleKeys: chart.dynamicSubTitleKeys.map((key) =>
          name === key ? option.prefix + name : key
        ),
      }),
    });

    setSelectedColumn((prev) => ({
      ...(!option.value
        ? omit(prev, "aggregation")
        : { ...prev, aggregation: option.value }),
      subIndex,
      column: option.prefix + name,
      index,
    }));
  };

  const changeColumnName = (option) => {
    const aggregationPrefix = getAggregationPrefix(option.aggregation);

    const existingOverride =
      getOverrideByName(overrides, option.name) ??
      getOverrideByName(overrides, name);

    setChartState({
      ...chart,
      ...(chart.subTitles && {
        subTitles: chart.subTitles.map((subTitle, i) =>
          subTitle.map((sub, j) => {
            if (subIndex === i && sub === column && j === index) {
              return aggregationPrefix + option.name;
            }

            return sub;
          })
        ),
      }),
      ...(chart.dynamicSubTitleKeys && {
        dynamicSubTitleKeys: chart.dynamicSubTitleKeys.map((key) =>
          name === key ? option.name : key
        ),
      }),
      ...(!isParameterized && {
        overrides: existingOverride
          ? chart.overrides.map((override) =>
              override.name === name && override.aggregation === aggregation
                ? option
                : override
            )
          : [...chart.overrides, option],
      }),
    });

    setSelectedColumn(
      omit(
        {
          ...selectedColumn,
          ...option,
          subIndex,
          column: option.name,
          index,
          name: option.name,
        },
        "aggregation"
      )
    );
  };

  const changeDisplayNameWithDelay = useMemo(
    () =>
      debounce(
        (value) =>
          setChartState({
            ...chart,
            overrides: chart.overrides.map((override) =>
              override.name === selectedColumn.name
                ? {
                    ...override,
                    mapping: { ...override.mapping, displayName: value },
                  }
                : override
            ),
          }),
        500
      ),
    [selectedColumn.name, setChartState, chart]
  );

  const changeDisplayName = (e) => {
    // debounce need for see changes on fly in preview
    changeDisplayNameWithDelay(e.target.value);

    setSelectedColumn({
      ...selectedColumn,
      mapping: { ...selectedColumn.mapping, displayName: e.target.value },
    });
  };

  const changeFormat = (option) => {
    setChartState({
      ...chart,
      overrides: chart.overrides.map((override) =>
        override.name === name
          ? !option.value
            ? omit(override, "mapping.type")
            : {
                ...override,
                mapping: { ...override.mapping, type: option.value },
              }
          : override
      ),
    });

    setSelectedColumn({
      ...selectedColumn,
      mapping: { ...selectedColumn.mapping, type: option.value },
    });
  };

  const excludeFromTotals = () => {
    const noTotalCalcKeys = (chart.noTotalCalcKeys ?? []).includes(column)
      ? chart.noTotalCalcKeys.filter((key) => key !== column)
      : [...(chart.noTotalCalcKeys ?? []), column];

    setChartState({
      ...chart,
      noTotalCalcKeys,
    });
  };

  const isText = isTextValue(fields, name);

  const isExcludedFromTotals =
    (chart.noTotalCalcKeys ?? []).includes(column) || isText;

  return (
    <>
      <Fields>
        <div style={{ width: isParameterized ? "100%" : "60%" }}>
          <Select
            options={fields}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.name}
            value={selectedColumn}
            onChange={changeColumnName}
            label="Column"
            isDisabled={rawRows && !subTitles}
            cy="select-column-name"
          />
        </div>
        {!isParameterized && (
          <div style={{ width: "35%" }}>
            <Select
              options={[{ label: "None", prefix: "" }, ...aggregations]}
              value={
                aggregations.find((aggr) => aggr.value === aggregation) || {
                  label: "None",
                  prefix: "",
                }
              }
              label="Aggregate"
              onChange={changeColumnAggregation}
              cy="select-column-aggregation"
            />
          </div>
        )}
      </Fields>

      {!hidden && (
        <hr color={theme.background.secondary} style={{ margin: "10px 0px" }} />
      )}

      {!hidden && (
        <Input
          label="Column Header Label"
          inPlaceEdit
          value={mapping?.displayName ?? ""}
          hideEditIcon
          onChange={changeDisplayName}
        />
      )}

      <hr color={theme.background.secondary} style={{ margin: "10px 0px" }} />

      <Select
        options={columnFormats}
        value={getNestedFormat(mapping?.type)}
        onChange={changeFormat}
        label="Format"
      />

      <hr color={theme.background.secondary} style={{ margin: "10px 0px" }} />
      <MathOperations
        setChartState={setChartState}
        selectedColumn={selectedColumn}
        setSelectedColumn={setSelectedColumn}
        overrides={overrides}
        query={query}
        chart={chart}
      />

      <hr color={theme.background.secondary} style={{ margin: "10px 0px" }} />

      <ColumnLinkSelector
        chart={chart}
        setChartState={setChartState}
        selectedColumn={selectedColumn}
        fields={fields}
        overrides={overrides}
      />

      <hr color={theme.background.secondary} style={{ margin: "10px 0px" }} />
      <Checkbox
        label="Exclude from Totals"
        checked={isExcludedFromTotals}
        onChange={excludeFromTotals}
        disabled={isText}
      />
      {isText && (
        <Note color="warningDark">
          Text, Date and Media values are excluded from totals by default.
          <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />
        </Note>
      )}

      <HideNullableColumns
        setChartState={setChartState}
        chart={chart}
        column={column}
      />
    </>
  );
}
