import Flex from "../../../../../UI/Flex/Flex";
import Input from "../../../../../UI/Form/Input/Input";

export default function DaysQuantityInput(props) {
  const {
    xDays,
    applyChange,
    setValidationError,
    validationError,
    value,
    setDays,
  } = props;

  if (!xDays) {
    return null;
  }

  function onDaysChange(e) {
    const days = Math.abs(e.target.value);

    setDays(days);

    if (!days || !Number.isInteger(+days)) {
      return setValidationError("This value must be an integer.");
    }

    applyChange(days);
    setValidationError(false);
  }

  function getValue() {
    if (isNaN(+value)) {
      return 1;
    }

    return value;
  }

  return (
    <Flex direction="column">
      <Input
        type="number"
        style={{ height: "40px" }}
        onChange={onDaysChange}
        min={1}
        invalid={validationError}
        errorMessage={validationError}
        value={getValue()}
      />
      <span style={{ marginLeft: 10 }}>Days</span>
    </Flex>
  );
}
