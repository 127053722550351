import Checkbox from "../../../../../UI/Form/Checkbox/Checkbox";

export default function HideNullableColumns({ setChartState, chart, column }) {
  const isHiddenOnEmpty = (chart.hiddenNullableColumns ?? []).includes(column);
  const isHidden = (chart.hiddenColumns ?? []).includes(column);

  function removeFromHidden(iterable) {
    return iterable.filter((item) => item !== column);
  }

  function toggleColumnVisibility(key, isHidden) {
    const iterable = chart[key] ?? [];

    setChartState({
      ...chart,
      [key]: isHidden ? removeFromHidden(iterable) : [...iterable, column],
    });
  }

  return (
    <>
      <br />
      <Checkbox
        label="Hide column if column data is empty"
        checked={isHiddenOnEmpty}
        onChange={() =>
          toggleColumnVisibility("hiddenNullableColumns", isHiddenOnEmpty)
        }
      />
      <br />
      <Checkbox
        label="Hide column in table"
        checked={isHidden}
        onChange={() => toggleColumnVisibility("hiddenColumns", isHidden)}
      />
      <br />
    </>
  );
}
