export function getOrderByString(orders) {
  return (orders ?? []).reduce((acc, order, index) => {
    const prefix = `&orderBy[${index}]`;

    const { name, sort, values } = order;

    // Append the name and direction
    acc += `${prefix}[name]=${name}`;
    acc += `${prefix}[direction]=${sort}`;

    // Append the values if they exist
    if (values?.length) {
      acc += values.map((value) => `${prefix}[values][]=${value}`).join("");
    }

    return acc;
  }, "");
}
