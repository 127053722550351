import { useDispatch } from "react-redux";
import { getMenuFilterValues } from "../store/actions/layout";
import { useEffect, useMemo, useState } from "react";
import useDebounceChange from "./useDebounceChange";
import { menuFilterDefaultPerPageValues } from "../utils/constants/constants";

export default function useFilterPagination(filter = {}) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [allFilterValuesRequested, setAllFilterValuesRequested] =
    useState(false);

  const usePagination = filter.totalCount > menuFilterDefaultPerPageValues;

  const { realTotalCount, totalCount } = filter;

  const requestAllCondition = useMemo(() => {
    // Check if the real total count exceeds the current total count
    const isCountExceeded = realTotalCount > totalCount;

    // Check if the real total count is above the default limit for the menu filter
    const isAboveDefaultLimit = realTotalCount > menuFilterDefaultPerPageValues;

    // Check if the current filter values length doesn't match the real total count
    const isFilterIncomplete = realTotalCount !== filter.values?.length;

    // Return true if all conditions are met and not all filter values have been requested
    return (
      isCountExceeded &&
      isAboveDefaultLimit &&
      isFilterIncomplete &&
      !allFilterValuesRequested
    );
  }, [
    allFilterValuesRequested,
    filter.values?.length,
    realTotalCount,
    totalCount,
  ]);

  useEffect(() => {
    if (requestAllCondition) {
      // Mark all filter values as requested
      setAllFilterValuesRequested(true);

      // Dispatch an action to fetch all filter values
      dispatch(getMenuFilterValues(filter, "", 1, realTotalCount));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestAllCondition]);

  // return filters back when close item
  useEffect(() => {
    return () => {
      if (usePagination && !allFilterValuesRequested && page > 1) {
        fetchFilterValues();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { debounce } = useDebounceChange();

  const fetchFilterValues = (searchTerm, page) => {
    debounce(
      () => dispatch(getMenuFilterValues(filter, searchTerm, page)),
      1000
    );
  };

  // handle search change for selects
  const onSelectInputChange = (value, { action }, page) => {
    if (action === "input-change") {
      setSearchValue(value);
      if (usePagination) {
        fetchFilterValues(value, page);
        setPage(page ?? 1);
      }
    }
  };

  return {
    fetchFilterValues,
    page,
    setPage,
    usePagination,
    searchValue,
    onSelectInputChange,
  };
}
