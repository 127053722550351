import styled from "@emotion/styled";
import Input from "../../../../../UI/Form/Input/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "../../../../../UI/Flex/Flex";
import { useState } from "react";

const ValueContainer = styled.div`
  width: fit-content;
  border-radius: 2px;
  color: white;
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.primary};
  margin-right: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
`;

const TextBox = styled.div`
  padding: 4px 8px;
`;

const IconBox = styled.div(
  ({ theme }) => `
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  padding: 4px 6px;
  height: 100%;
  box-sizing: border-box;

  &:hover {
    cursor: pointer;
    background: ${theme.notification.errorLight};  
  }
`
);

export default function OrderValues(props) {
  const { onValueAdd, onValueRemove, values } = props;
  const [val, setVal] = useState("");

  function setNewValue(e) {
    const value = e.target.value;
    setVal(value);

    if (e.key === "Enter" && value) {
      e.stopPropagation();
      e.preventDefault();
      onValueAdd(value);
      setVal("");
    }
  }

  return (
    <div>
      {(values ?? []).length ? (
        <>
          <br />
          <div>Sorting Values:</div>
          <br />
        </>
      ) : null}

      <Flex wrap="wrap">
        {(values ?? []).map((value, index) => (
          <ValueContainer key={value + index}>
            <TextBox>{value}</TextBox>

            <IconBox onClick={() => onValueRemove(value)}>
              <FontAwesomeIcon icon={["fas", "times"]} />
            </IconBox>
          </ValueContainer>
        ))}
      </Flex>
      <br />
      <Input
        label="Press Enter to add value"
        onChange={setNewValue}
        onKeyDown={setNewValue}
        value={val}
      />
    </div>
  );
}
