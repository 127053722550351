import React from "react";

export default function TrendLine(props) {
  const { x1, y1, x2, y2 } = props;
  return (
    <g>
      <line
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        stroke="black"
        strokeWidth="1"
        strokeDasharray="4,2" // Makes the line dotted
      />
    </g>
  );
}
