import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import {
  scrollbarDe,
  themeBackground,
  themeColor,
  themeGrayIf,
  themeShadow,
  themeText,
} from "../../../styles/styledSnippets";
import IOButton from "../../../UI/Form/Button/IOButton";
import { format } from "date-fns";
import ActiveTableViewsDetail from "./ActiveTableViewsDetail";
import { faStreetView } from "@fortawesome/pro-light-svg-icons";
import useActiveTableViewManager from "./useActiveTableViewManager";
import AlertMessage from "../../../UI/Alerts/AlertMessage";
import useShowDetails from "./useShowDetails";

const ViewListContainer = styled.div`
  position: absolute;
  top: -24px;
  right: 0;
  padding: 18px;
  color: ${themeText("primary")};
  background: ${themeBackground("secondary")};
  z-index: 100;
  border-radius: 4px;
  box-shadow: ${themeShadow(2)};
  width: 280px;
  user-select: none;
  max-height: calc(100vh - 235px);
  overflow-y: auto;
  ${(props) => scrollbarDe(props.theme)};
`;

const Close = styled.div`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  color: ${themeText("primary")};
`;

const ViewItemContainer = styled.div`
  display: flex;
  border-radius: 4px;
  border: 1px solid ${themeColor("infoMain")};
  ${(props) =>
    !props.selected ? `border-color: ${themeGrayIf(700, 300)(props)};` : ""}
  margin-bottom: 12px;
  cursor: pointer;
  position: relative;
  &:hover {
    svg {
      opacity: 1 !important;
    }
  }
`;

const ViewItem = styled.div`
  padding: 12px;
  z-index: 100;
  font-size: 14px;
  width: 100%;
`;

const Indicator = styled.div`
  width: 36px;
  background: ${themeColor("infoMain")};
  ${(props) =>
    !props.selected ? `background: ${themeGrayIf(700, 300)(props)};` : ""}
  color: ${themeColor("infoLight")};
  svg {
    opacity: ${(props) => (props.selected ? 1 : 0.3)};
  }
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 18px;
`;

const ButtonSpacing = styled.div`
  padding: 24px 0 8px 0;
  margin-top: 24px;
  border-top: 1px solid ${themeGrayIf(700, 300)};
`;

const Subtitles = styled.div`
  font-weight: 300;
  font-size: 11px;
  margin-top: 4px;
`;

const Cog = styled.div`
  position: absolute;
  right: ${(props) => props.right ?? 12}px;
  top: 12px;
  color: ${themeGrayIf(700, 300)};
  ${(props) =>
    props.selected ? `color: ${props.theme.notification.infoMain};` : ""}
  z-index: 101;
  ${(props) =>
    props.isRemoveIcon && `color: ${props.theme.notification.errorMain};`};
`;

const ViewName = styled.div`
  width: 85%;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

export default function ActiveTableViewsNavigationList(props) {
  const {
    setShowList,
    views = [],
    selectedView,
    setSelectedView,
    columns,
    queryId,
    handleSaveView,
    user,
    handleSplit,
    confirmDeleteView,
  } = props;
  const { showDetailPanel, setShowDetailPanel, activeTab, setActiveTab } =
    useShowDetails(selectedView, setSelectedView);

  const {
    nextColumns,
    setNextColumns,
    visibleColumns,
    changeValue,
    setVisible,
    setInvisible,
    setAllVisible,
    setAllInvisible,
    name,
    setName,
    disabled,
    saveView,
    processing,
    filterApi,
    isDirty,
    displaySettings,
    setDefaultViewFlag,
    setVisibilityViewUser,
    setNextView,
    setFilters,
    setDisplaySettings,
    setViewSplitBy,
  } = useActiveTableViewManager({
    activeViewInitialState: views.find((v) => v.uuid === selectedView),
    allColumns: columns,
    handleSaveView,
    user,
  });

  function handleSelect(view) {
    setSelectedView(view.uuid);

    if (!view.visibleFields?.length && handleSplit) {
      handleSplit(null);
    }
  }

  function handleCloseModal() {
    setShowDetailPanel(null);
  }

  return (
    <ViewListContainer>
      {showDetailPanel ? (
        <div style={{ position: "relative" }}>
          <ActiveTableViewsDetail
            handleClose={handleCloseModal}
            columns={nextColumns}
            setNextColumns={setNextColumns}
            visibleColumns={visibleColumns}
            changeValue={changeValue}
            setVisible={setVisible}
            setInvisible={setInvisible}
            setAllVisible={setAllVisible}
            setAllInvisible={setAllInvisible}
            selectedView={selectedView}
            queryId={queryId}
            name={name}
            setName={setName}
            setVisibilityViewUser={setVisibilityViewUser}
            handleSaveView={saveView}
            processing={processing}
            disabled={disabled}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            isDirty={isDirty}
            filterApi={filterApi}
            displaySettings={displaySettings}
            setDefaultViewFlag={setDefaultViewFlag}
            views={views}
            setNextView={setNextView}
            setFilters={setFilters}
            setDisplaySettings={setDisplaySettings}
            setViewSplitBy={setViewSplitBy}
          />
        </div>
      ) : null}
      <Close onClick={setShowList}>
        <FontAwesomeIcon icon={["fal", "times-circle"]} />
      </Close>
      <div style={{ marginBottom: 12 }}>
        <FontAwesomeIcon icon={faStreetView} />{" "}
        <span style={{ paddingLeft: 6 }}>View list</span>
      </div>
      {views.map((view) => (
        <ViewSwatch
          key={view.uuid}
          selected={view.uuid === selectedView}
          onClick={() => handleSelect(view)}
          showDetails={() => {
            return setShowDetailPanel(true);
          }}
          onRemove={() => confirmDeleteView(view)}
        >
          <ViewName>{view.name}</ViewName>
          <Subtitles>Owner: {view.displaySettings?.owner?.email}</Subtitles>
          <Subtitles>
            Created:
            {view.createdAt && format(new Date(view.createdAt), "MMM dd, yyyy")}
          </Subtitles>
        </ViewSwatch>
      ))}
      {!views.length ? (
        <AlertMessage stacked outline status="warning" cy="empty-views-message">
          There are no views available
        </AlertMessage>
      ) : null}

      <ButtonSpacing>
        <IOButton
          fit
          standard
          cy="add-view"
          onClick={() => setSelectedView("new")}
        >
          Add View
        </IOButton>
      </ButtonSpacing>
    </ViewListContainer>
  );
}

const ViewSwatch = (props) => (
  <ViewItemContainer
    selected={props.selected}
    onClick={props.onClick}
    data-cy="at-view-list-item"
  >
    <Cog
      selected={props.selected}
      title="View settings"
      onClick={props.showDetails}
      data-cy="view-settings-detail-cog"
      right={30}
    >
      <FontAwesomeIcon icon={["fal", "cog"]} />
    </Cog>

    <Cog
      title="Delete View"
      onClick={props.onRemove}
      data-cy="view-settings-detail-times"
      right={12}
      isRemoveIcon
    >
      <FontAwesomeIcon icon={["fal", "times-circle"]} />
    </Cog>
    <Indicator selected={props.selected}>
      <FontAwesomeIcon icon={[props.selected ? "fas" : "far", "circle"]} />
    </Indicator>
    <ViewItem>{props.children}</ViewItem>
  </ViewItemContainer>
);
