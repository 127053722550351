import React from "react";
import PropTypes from "prop-types";
import formatter from "../../utils/formatters/formatter";
import styled from "@emotion/styled";

const Label = styled.td`
  font-size: 12px;
  text-align: left;
  padding-bottom: 6px;
  color: ${(props) => props.color};
`;

const getMetaData = (fields, key) => {
  return (fields ?? []).find((meta) => meta.name === key || meta.alias === key);
};

export default function ChartTip(props) {
  const { config, tooltip, data, metaFields } = props;

  if (!config) return null;
  let rows;
  if (tooltip.sourceData) {
    // pie version
    rows = Object.entries(config).map((e) => ({
      label: e[1].label,
      value: tooltip.sourceData[e[0]],
      displayValue: formatter(tooltip.sourceData[e[0]], e[1].format),
    }));
  } else if (config.flatValues) {
    rows = config.tooltipRows.map((r) => {
      const meta = getMetaData(metaFields, r.key);

      if (!meta) return null;
      const key = meta.alias || meta.name;
      const type = r.typeOverride || meta.type;

      const value =
        data.allValues[key] ??
        (data.tooltip ?? []).find((item) => item.key === key)?.value;

      return {
        label: r.label,
        value,
        displayValue: formatter(value, type),
      };
    });
  } else if (config.advanced) {
    let { selectedRow } = tooltip;
    if (!selectedRow) {
      return [];
    }

    const formatType = (r) => {
      const metaMatch = getMetaData(metaFields, r.key);
      return r.typeOverride || metaMatch?.type;
    };

    const valueKey = (r) => {
      const metaMatch = getMetaData(metaFields, r.key);
      return metaMatch?.alias || metaMatch?.name;
    };

    const display = (r) => {
      const format = formatType(r);
      const value = selectedRow[valueKey(r)];
      return formatter(value, format);
    };

    rows = config.tooltipRows.map((r) => {
      return {
        label: r.label,
        value: selectedRow[valueKey(r)],
        displayValue: display(r),
      };
    });
  } else {
    // Stacked bars atm

    rows = Object.entries(config)
      .filter((e) => e[1].label)
      .filter((e) => e[0] === tooltip.key) // what is this for?
      .map((e, i) => ({
        label: e[1].label,
        value: data[e[0]],
        displayValue: formatter(data[e[0]], e[1].format),
      }));
  }
  return (
    <div>
      <table>
        <tbody>
          {tooltip.piePercent ? (
            <tr>
              <Label data-cy="chart-tip-label">
                Percent of total
                <br /> <b>{data.tooltip[0].value || "--"}</b>
              </Label>
            </tr>
          ) : null}
          {(rows ?? [])
            .filter(Boolean)
            .map(({ label, value, displayValue }) => (
              <tr key={label}>
                {config.inlineTextStyle ? (
                  <>
                    <Label
                      data-cy="chart-tip-label"
                      color={config.primaryColor}
                    >
                      {label}:{" "}
                    </Label>
                    <Label
                      data-cy="chart-tip-value"
                      style={{ paddingLeft: 5 }}
                      color={config.primaryColor}
                    >
                      <b>{displayValue || value || "--"}</b>
                    </Label>
                  </>
                ) : (
                  <Label data-cy="chart-tip-label" color={config.primaryColor}>
                    {label}
                    <br /> <b>{displayValue || value || "--"}</b>
                  </Label>
                )}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

ChartTip.propTypes = {
  config: PropTypes.object,
  data: PropTypes.object,
};
