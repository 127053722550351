import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState } from "react";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import {
  apiExportAllDrilldownSubtitles,
  downloadTable,
} from "../../store/actions/dashboard/dashboard";
import { useSelector } from "react-redux";
import { isParameterized } from "../../utils/queries";

const Container = styled.div`
  font-size: 12px;
`;

const DynamicContainer = styled.div(
  ({ theme }) => `
  position: absolute;
  background: white;
  left: -60px;
  border-radius: 5px;
  border: 1px solid ${theme.divider};
  padding: 15px;
  display: flex;
  flex-direction: column;
  min-width: 100px;
  background: ${theme.background.primary};
`
);

export default function Export(props) {
  const {
    chart,
    title,
    dispatch,
    exportId,
    isDrillable,
    setExporting,
    handleExport,
    nonConvertPercentsBack,
  } = props;

  const ref = useRef(null);
  const queries = useSelector((state) => state.dataSettings.tinyQueries);
  useOnClickOutside(ref, () => setShowExportOptions(false));
  const [showExportOptions, setShowExportOptions] = useState(false);

  const query = (queries ?? []).find((q) => chart && q.uuid === chart.queryId);

  // library export
  function handleFeExport() {
    handleExport(exportId, !nonConvertPercentsBack, title);
    setShowExportOptions(false);
  }

  // api export with all drilldown overrides included
  function handleApiExport() {
    if (isDrillable) {
      dispatch(apiExportAllDrilldownSubtitles(chart, title, setExporting));
      return setShowExportOptions(false);
    }

    dispatch(
      downloadTable(chart.exportFormat ?? "excel", chart.visualizationId, true)
    );
    setShowExportOptions(false);
  }

  // library link
  const feExportLink = exportId ? (
    <span onClick={handleFeExport}>Export</span>
  ) : null;

  // api export link
  const drilldownApiExportLink = (
    <span onClick={handleApiExport}>Export All Fields</span>
  );

  function renderExportLinks() {
    if (isParameterized(query)) {
      return feExportLink;
    }

    return (
      <Container ref={ref}>
        <span onClick={() => setShowExportOptions(!showExportOptions)}>
          Export{" "}
          <FontAwesomeIcon
            icon={["fas", showExportOptions ? "caret-down" : "caret-right"]}
          />
        </span>
        {showExportOptions && (
          <DynamicContainer>
            {feExportLink}
            <br />
            {drilldownApiExportLink}
          </DynamicContainer>
        )}
      </Container>
    );
  }

  return renderExportLinks();
}
