import { getRidOfAggregation } from "../../../../charts/TableView/Elements/EditableMenu";
import {
  AGGREGATION_SUM,
  aggregations,
  columnFormats,
  excludedTypesFromTotals,
} from "../../../../utils/constants/constants";
import { getRestOverrides } from "../../utils/tableValidation";
import { getAggregationPrefix } from "./Column/RegularColumn";

export function getOverrideByName(overrides, name) {
  return (overrides ?? []).find((override) => override.name === name);
}

export function columnToLabel(overrides, column) {
  if (column.includes("fn::")) {
    return column.split("::").pop();
  }

  if (column.includes("omf::")) {
    return column.split("::").pop();
  }

  const nonAggregatedColumn = getRidOfAggregation(column, overrides);

  return (
    (overrides ?? []).find(
      (override) =>
        override.name === nonAggregatedColumn || override.ops?.alias === column
    )?.mapping?.displayName || column
  );
}

export function getNotInOverrides(overrides, options, key) {
  return options
    .filter((option) => !option[key].includes("fn::"))
    .filter((option) => !option.notIncludeToOverrides)
    .filter((option) => {
      return !overrides.find((override) => override.name === option[key]);
    });
}

export function getUpdatedOverrides(options, overrides, key) {
  const strictOverrides = overrides ?? [];
  const notInOverrides = getNotInOverrides(strictOverrides, options ?? [], key);
  return [
    ...strictOverrides,
    ...notInOverrides.map((option) => ({ name: option.value })),
  ];
}

export function setDefaultAggregation(overrides, option, isParameterized) {
  const nonNumericTypes = ["date", "text", "string"];

  if (nonNumericTypes.includes(option.type) || isParameterized) {
    return overrides;
  }

  return overrides.map((override) => {
    if (override.name !== option.value || override.aggregation) {
      return override;
    }

    return { ...override, aggregation: AGGREGATION_SUM };
  });
}

export function setDynamicSubTitlePrefix(overrides, name) {
  const { aggregation } = getOverrideByName(overrides, name) ?? {};
  const prefix = getAggregationPrefix(aggregation);
  return prefix + name;
}

export function includeRegularValues(
  repeatingOptions,
  dynamicSubTitleKeys,
  overrides
) {
  return repeatingOptions.filter((option) => {
    const { aggregation } = getOverrideByName(overrides, option.value) ?? {};
    const prefix = getAggregationPrefix(aggregation);
    return dynamicSubTitleKeys.includes(prefix + option.value);
  });
}

export function includeFormulaValues(dynamicSubTitleKeys) {
  return dynamicSubTitleKeys
    .filter((key) => key.includes("fn::"))
    .map((formula) => ({
      value: formula,
      label: formula.split("::").pop(),
    }));
}

export function includeGroupingExtraColumnValue(
  dynamicSubTitleKeys,
  groupingExtraColumns
) {
  if (!groupingExtraColumns) {
    return [];
  }

  return dynamicSubTitleKeys
    .filter((key) => key === groupingExtraColumns.column)
    .map((key) => ({ value: key, label: key }));
}

// if you want to add formula without breaking overrides
export const formulaOption = {
  value: "fn::",
  label: "Formula column (fn::)",
  name: "fn::",
  mapping: {
    displayName: "Formula column (fn::)",
  },
};

export function getExtraColumnOption(groupingExtraColumns) {
  if (!groupingExtraColumns) {
    return;
  }

  return {
    value: groupingExtraColumns.column,
    label: groupingExtraColumns.column,
    notIncludeToOverrides: true,
  };
}

export function getUpdatedDynamicSubTitleKeys(chart, column, val) {
  return chart.dynamicSubTitleKeys.map((key) => (key === column ? val : key));
}

export function getUpdatedSubTitles(chart, selectedColumn, val) {
  return chart.subTitles.map((subTitle, i) =>
    subTitle.map((sub, j) =>
      (sub === selectedColumn.column || sub === selectedColumn.ops?.alias) &&
      i === selectedColumn.subIndex &&
      j === selectedColumn.index
        ? val
        : sub
    )
  );
}

export function getOverridesWithNewAdded(overrides, field, headers, prev) {
  const isExist = overrides.some((o) => o.name === field.name);
  const { value } = getCurrentAggregation(prev, overrides) ?? {};

  // headers can be static subtitles or dynamic subtitle kyes
  const isInUse = getRestOverrides(headers, overrides).includes(
    getRidOfAggregation(prev, overrides)
  );

  const newOverrides = overrides.filter(
    (o) => isInUse || o.name !== getRidOfAggregation(prev, overrides)
  );

  if (!isExist) {
    newOverrides.push({ ...field, ...(value && { aggregation: value }) });
  }

  return newOverrides;
}

export function getColumn(overrides, aggregated) {
  if (!aggregated) {
    return null;
  }

  const nonAggregated = getRidOfAggregation(aggregated, overrides);
  return overrides.find(
    (o) => o.name === nonAggregated || aggregated === o.ops?.alias
  );
}

export function getCurrentAggregation(col, overrides) {
  // we can have fields which names can start with aggregation prefix but they did not aggregated
  // so we should not remove this prefix
  const condition = overrides.some((override) => override.name === col);

  const none = {
    label: "None",
    prefix: "",
  };

  if (condition) {
    return none;
  }

  return (
    aggregations.find((aggr) => (col || "").startsWith(aggr.prefix)) || none
  );
}

export function isTextValue(fields, name) {
  const { mapping } = fields.find((field) => field.name === name) ?? {};

  return (
    excludedTypesFromTotals.includes(mapping?.type) ||
    (mapping?.type ?? "").includes("date")
  );
}

export function getNestedFormat(format) {
  const { options } =
    columnFormats.find((category) =>
      category.options.find((nFormat) => nFormat.value === format)
    ) ?? {};

  return options?.find((nFormat) => nFormat.value === format);
}
