import axios from "../../axios";
import { setToken } from "../../utils/getToken";
import { laravelEchoWrapper } from "../../utils/websockets";
import * as actionTypes from "./actionTypes";
import qs, { parse } from "qs";
import { handleError } from "../../utils/errorHandling";
import menuFilterSettingsFormatConverter from "../../utils/formatConverters/menuFilterSettingsFormatConverter";
import { showToast } from "./message";
import { loadUserGroups } from "./userManagement";
import { getDataFreshness } from "./userNotifications";
import { listQueries } from "./dataSettings/dataSettings";
import { getPages } from "./pageManagement";
import { loadHeaderConfiguration } from "./layout";

export function checkAuth(domain, history) {
  return async (dispatch) => {
    const query = parse(history.location.search, { ignoreQueryPrefix: true });
    const googleIdToken = query.googleIdToken;
    const user = !googleIdToken && JSON.parse(localStorage.getItem("user"));
    if (!user && !googleIdToken) {
      return dispatch({
        type: actionTypes.GET_PUBLIC_CONFIG_START,
        meta: {
          api: {
            endpoint: `/api/v1/domain_config/${domain}`,
            method: "get",
            public: true,
          },
        },
      });
    } else {
      try {
        dispatch({ type: actionTypes.CHECK_AUTH_START });
        const res = await axios.get("/api/v1/auth?domain=" + domain);
        // Returns logged in config
        const data = res.data.data;
        if (data.tenantConfig) {
          onReceiveTenantConfig(data.tenantConfig);
          dispatch({
            type: actionTypes.CHECK_AUTH_SUCCESS,
            results: data,
          });
          dispatch(completeLogin());
        } else if (googleIdToken) {
          dispatch(googleLogin(googleIdToken, domain));
        } else {
          // returned public config, go back to login screen
          localStorage.clear();
          history.go("/login");
        }
      } catch (err) {
        handleError(err);
        dispatch({ type: actionTypes.GET_PUBLIC_CONFIG_FAIL });
      }
    }
  };
}

export function login(email, password, dashboardName, tenant) {
  return async (dispatch) => {
    const requestBody = { email, password, tenant };

    dispatch({ type: actionTypes.LOGIN_START });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    try {
      doLogin(
        dispatch,
        dashboardName,
        await axios.post(
          "/api/v1/login?config=1",
          qs.stringify(requestBody),
          config
        )
      );
    } catch (err) {
      dispatch(setAuthErrors(err));
    }
  };
}

export function googleLogin(googleIdToken, domain, dashboardName) {
  return async (dispatch) => {
    dispatch({ type: actionTypes.LOGIN_START });

    try {
      doLogin(
        dispatch,
        dashboardName,
        await axios.post("/api/v1/google-login", {
          tenant: domain,
          idToken: googleIdToken,
          config: true,
        })
      );
    } catch (err) {
      handleError(err);
      dispatch(setAuthErrors(err));
    }
  };
}

function doLogin(dispatch, dashboardName, authData) {
  const { access_token: token, two_factor: twoFactor } = authData.data;
  const { tenantConfig, user } = authData.data.auth;
  const { name, role, avatar, uuid, accountUuid, ttg, group, email } = user;
  const tenantUuid = tenantConfig.uuid;

  const storageUser = {
    token,
    name,
    email,
    role,
    uuid,
    avatar,
    tenantUuid,
    accountUuid,
    ttg,
    group,
  };

  const results = {
    ...authData.data.auth,
    tenantUuid,
    twoFactor,
    dashboardName,
  };

  onReceiveTenantConfig(results.tenantConfig);
  dispatch({
    type: actionTypes.LOGIN_SUCCESS,
    results,
    meta: {
      token: {
        user: storageUser,
        create: true,
      },
    },
  });
  if (!twoFactor) {
    dispatch(completeLogin());
  }
}

function setAuthErrors(err) {
  return (dispatch) => {
    if (err.response?.status === 404) {
      return dispatch({
        type: actionTypes.LOGIN_FAIL,
        errors: ["Api not found. Check environment variables"],
      });
    }

    if (err.response?.status !== 422) {
      handleError(err);
    }

    const { errors = {}, message } = err.response?.data || {
      message: ["Something went wrong"],
    };
    const { email = [], password = [], code = [] } = errors;
    const errorList = [].concat(email, password, code, message).filter(Boolean);

    dispatch({ type: actionTypes.LOGIN_FAIL, errors: errorList });
  };
}

export function performTwoFactorAuthChallenge(code) {
  return async (dispatch) => {
    try {
      const response = await axios.post("/api/v1/two-factor-challenge", {
        code,
      });

      // We must set the token we receive, because after confirming, the API will require a token
      // that was 2FA confirmed.
      setToken(response.data.access_token);

      dispatch(completeLogin());
    } catch (err) {
      dispatch(setAuthErrors(err));
    }
  };
}

function onReceiveTenantConfig(tenantConfig) {
  const socketsConfig = tenantConfig.socketsConfig;
  if (socketsConfig && socketsConfig.key) {
    // Set up websockets.

    laravelEchoWrapper.options.broadcaster = "pusher";
    laravelEchoWrapper.options.key = socketsConfig.key;
    laravelEchoWrapper.options.wsHost = socketsConfig.wsHost;
    laravelEchoWrapper.options.wsPort = socketsConfig.wsPort;
    laravelEchoWrapper.options.forceTLS =
      !socketsConfig.wsPort || Number(socketsConfig.wsPort) === 443;
  } else {
    // Without the websocket key it's not possible to connect to the websocket.
    // That's OK. Let's emit a warning, and any laravel echo calls will just
    // invoke the Null connector not crash the application.
    if (process.env.NODE_ENV === "test" || window.Cypress) return;

    //eslint-disable-next-line no-console
    console.warn(
      "Websocket key not received from the API. " +
        "Such functionality will not do anything."
    );
  }
}

function getMyPermissions(dispatch) {
  return dispatch({
    type: actionTypes.GET_MY_PERMISSIONS_START,
    meta: {
      api: {
        public: true,
        method: "GET",
        endpoint: "/api/v1/me/permissions",
      },
    },
  });
}

export function completeLogin() {
  return async (dispatch, getState) => {
    getInsights(dispatch);
    getMyPermissions(dispatch);
    const auth = getState().auth;
    dispatch(loadUserGroups());
    if (auth.hasDataFreshness) {
      dispatch(getDataFreshness());
    }

    // we calling tiny queries to make sure that we have the uuids and types for query builder calls
    // it need for drilldown tables with different query uuid
    const queries = getState().dataSettings.tinyQueries;
    if (!queries.length) {
      dispatch(listQueries(true));
    }

    // These are actions that must complete before the layout renders.
    const promises = [];
    if (auth.hasPages) {
      promises.push(dispatch(getPages()));
    }

    // dispatch({
    //   type: actionTypes.GET_INSIGHTS_START,
    //   meta: {
    //     api: {
    //       method: "GET",
    //       endpoint: "/api/v1/insights-notifications",
    //     },
    //   },
    // });

    await Promise.all(promises);

    dispatch({
      type: actionTypes.LOGIN_COMPLETE,
    });

    return loadHeaderConfiguration([getState().auth.dashboardName, null]);
  };
}

export function logout() {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.LOGOUT_START });
      await axios.post("/api/v1/logout");
    } catch (err) {
      handleError(err);
    }
  };
}

export function clearAuthErrors() {
  return { type: actionTypes.CLEAR_LOGIN_ERRORS };
}

export function forgotPassword(email) {
  return (dispatch, getState) => {
    const auth = getState().auth;
    const returnUrl = window.location.href + "/reset";

    dispatch({
      type: actionTypes.FORGOT_PASSWORD_START,
      meta: {
        api: {
          public: true,
          method: "POST",
          endpoint: "/api/v1/forgot_password",
          payload: {
            tenant: auth.tenantUuid,
            returnUrl,
            email,
          },
        },
      },
    });
  };
}

export function resetPassword(form) {
  return (dispatch, getState) => {
    const tenant = getState().auth.tenantUuid;
    const payload = { ...form, tenant };
    dispatch({
      type: actionTypes.RESET_PASSWORD_START,
      meta: {
        api: {
          public: true,
          method: "POST",
          endpoint: "/api/v1/reset_password",
          payload,
        },
      },
    });
  };
}

export function activateInvite(form) {
  return (dispatch, getState) => {
    const tenant = getState().auth.tenantUuid;
    const { token, ...restForm } = form;
    const payload = { ...restForm, inviteCode: token, tenant };
    dispatch({
      type: actionTypes.ACTIVATE_INVITE_START,
      meta: {
        api: {
          public: true,
          method: "POST",
          endpoint: "/api/v1/activate_invite_code",
          payload,
        },
      },
    });
  };
}

function getInsights(dispatch) {
  return dispatch({
    type: actionTypes.GET_INSIGHTS_START,
    meta: {
      api: {
        method: "GET",
        endpoint: "/api/v1/insights-notifications",
      },
    },
  });
}

export function saveMenuFilterSettings(menuFilterSettings) {
  return {
    type: actionTypes.SAVE_MENU_FILTER_SETTINGS_START,
    meta: {
      api: {
        method: "PUT",
        endpoint: "/api/v1/menu_filters/settings",
        payload:
          menuFilterSettingsFormatConverter.local.toRequest(menuFilterSettings),
        toastOnFailure: true,
      },
      toasts: [
        {
          type: "success",
          title: "Settings",
          message: "Menu filters saved",
          condition: actionTypes.SAVE_MENU_FILTER_SETTINGS_START,
        },
      ],
    },
  };
}

export function saveDateFilterSettings({
  dateFilterType,
  dateFilters,
  dispatch,
}) {
  if (!dateFilterType) {
    return showToast(
      actionTypes.SAVE_DATE_FILTER_SETTINGS_FAIL,
      dispatch,
      ["Something went wrong"],
      "danger"
    );
  }

  const requestData = {
    value: {
      dateFilterSettings: {
        term: dateFilterType.value,
      },
    },
  };

  if (dateFilterType.hasParameter) {
    requestData.value.dateFilterSettings.startDate = dateFilters.start.value;
    requestData.value.dateFilterSettings.endDate = dateFilters.end.value;
  }

  return {
    type: actionTypes.SAVE_DATE_FILTER_SETTINGS_START,
    meta: {
      api: {
        method: "PUT",
        endpoint: "/api/v1/me/settings",
        payload: requestData,
        toastOnFailure: true,
      },
      toasts: [
        {
          type: "success",
          title: "Settings",
          message: "Date filters saved",
          condition: actionTypes.SAVE_DATE_FILTER_SETTINGS_START,
        },
      ],
    },
  };
}
