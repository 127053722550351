import React from "react";
import styled from "@emotion/styled";
import { themeGrayIf } from "../../../styles/styledSnippets";
import { colors } from "../constants";

const Outer = styled.div`
  border: 1px solid ${themeGrayIf(800, 300)};
  width: 90px;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 12px 0;
  box-sizing: border-box;
  border-radius: 8px;
  background: ${themeGrayIf(900, 200)};
  ${(props) =>
    props.visible
      ? `
    background: ${colors.visible};
    color: white;
  `
      : ""}
  ${(props) =>
    props.editable
      ? `
  background: ${colors.editable};
  color: white;
  `
      : ""}
`;
const Label = styled.div`
  font-weight: 300;
  font-size: 12px;
`;

const Value = styled.div`
  font-size: 42px;
`;
export default function Scorecard(props) {
  const { label, value, style, editable, visible, onClick, className } = props;
  return (
    <Outer
      className={className}
      style={style}
      editable={editable}
      visible={visible}
      onClick={onClick && onClick}
    >
      <Label>{label}</Label>
      <Value>{value}</Value>
    </Outer>
  );
}
