import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { showToastWithTimeout } from "../../store/actions/message";
import { normalizeError, handleError } from "../../utils/errorHandling";
import axios, { HEADER_CONVERT_EMPTY_STRINGS_TO_NULL } from "../../axios";
import { chartTypeKeyMap } from "../../utils/constants/chartConstants";
import {
  getSelectorForCurrentPageByUuid,
  getFirstVisualizationForPage,
  getSingleDataSourceFiltersQueryUuidByPage,
} from "../../utils/pages";
import useMemoDeepCompare from "../../utils/useMemoDeep";
import { assembleEmptySingleQueryFiltersFromActiveTableStore } from "../../utils/menuFilters";
import { useShallowEqualSelector } from "../../store";
import produce from "immer";
import {
  getActiveTableFields,
  getActiveTableAndViewUuidFromState,
} from "../../utils/activeTable";
import { convertToWithNull } from "../../store/actions/dataSettings/prepareSettings";

export default function (pageUuid) {
  const dispatch = useDispatch();
  const page = useSelector(getSelectorForCurrentPageByUuid(pageUuid));
  const isEnabledForThisPage = page?.singleDataSourceMenuFilters;

  const visualization = getFirstVisualizationForPage(page);
  const queryUuid = getSingleDataSourceFiltersQueryUuidByPage(page);
  const isActiveTable =
    visualization?.settings.type === chartTypeKeyMap.ActiveTable;
  const queryFields = useSelector((state) => state.activeTable.queryFields);
  const currentViewUuid = useSelector(
    (state) => state.activeTable.currentViewUuid
  );
  const { currentTable: currentActiveTable, currentActiveTableUuid } =
    useShallowEqualSelector(getActiveTableAndViewUuidFromState);
  const firstVisualizationActiveTableUuid =
    visualization?.settings.activeTableUuid;
  const currentViewFilters = currentActiveTable?.views?.find(
    (v) => v.uuid === currentViewUuid
  )?.filters;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const abortControllersRef = useRef(new Set());

  const loadSingleFieldByName = useCallback(
    async (fieldName, otherFieldValues) => {
      const abortController = new AbortController();
      try {
        abortControllersRef.current.add(abortController);

        setData((data) =>
          produce(data, (draft) => {
            for (const row of draft) {
              if (row.fieldName !== fieldName) {
                continue;
              }

              row.loading = true;
            }
          })
        );

        const promise = axios.post(
          `/api/v1/queries/${queryUuid}/exec`,
          {
            per_page: 100_000,
            order: {
              [fieldName]: "ASC",
            },
            overrides: {
              other: {
                isDistinct: true,
              },
              fields: [
                {
                  name: fieldName,
                },
              ],
            },
            filters: convertToWithNull([
              ...otherFieldValues?.map(({ key, selectedValues }) => ({
                name: key,
                values: selectedValues,
              })),
              ...(currentViewFilters ?? []),
            ]),
          },
          {
            signal: abortController.signal,
            headers: { [HEADER_CONVERT_EMPTY_STRINGS_TO_NULL]: "0" },
          }
        );

        const results = (await promise).data.data;

        setData((data) =>
          produce(data, (draft) => {
            for (const row of draft) {
              if (row.fieldName !== fieldName) {
                continue;
              }

              row.values = results.map((v) => v[fieldName]);
              row.loading = false;
            }
          })
        );
      } catch (e) {
        handleError(e);
      } finally {
        abortControllersRef.current.delete(abortController);
      }
    },
    [currentViewFilters, queryUuid]
  );

  const fields = useMemoDeepCompare(
    useMemo(() => {
      return (
        currentActiveTable &&
        getActiveTableFields(currentActiveTable, currentViewUuid, queryFields)
      );
    }, [currentActiveTable, currentViewUuid, queryFields])
  );

  const isEnabledAndActiveTableReady = useMemo(() => {
    if (!isEnabledForThisPage) {
      return false;
    }

    return !(
      isActiveTable &&
      (!currentActiveTableUuid ||
        currentActiveTableUuid !== firstVisualizationActiveTableUuid)
    );
  }, [
    currentActiveTableUuid,
    firstVisualizationActiveTableUuid,
    isActiveTable,
    isEnabledForThisPage,
  ]);

  useEffect(() => {
    if (isEnabledAndActiveTableReady && !queryUuid) {
      const errorMessage = "Query was not found.";
      showToastWithTimeout(dispatch, errorMessage, "danger");
      setError(
        normalizeError({
          message: errorMessage,
        })
      );
    }
  }, [dispatch, isEnabledAndActiveTableReady, queryUuid]);

  const emptySingleQueryFilters = useMemo(() => {
    if (!queryFields || !fields) {
      return;
    }

    return assembleEmptySingleQueryFiltersFromActiveTableStore(
      queryFields,
      fields
    );
  }, [fields, queryFields]);

  useEffect(() => {
    if (!emptySingleQueryFilters) {
      return;
    }

    setData(emptySingleQueryFilters);

    const abortControllers = abortControllersRef.current;
    return () => {
      for (const controller of abortControllers) {
        controller.abort();
      }
      setLoading(false);
      setData(null);
      setError(null);
    };
  }, [emptySingleQueryFilters]);

  return {
    isEnabledForThisPage,
    loading,
    data,
    error,
    queryUuid,
    loadSingleFieldByName,
  };
}

export function useIsSingleQueryFiltersEnabledOnThisPage(pageUuid) {
  const page = useSelector(getSelectorForCurrentPageByUuid(pageUuid));
  return page?.singleDataSourceMenuFilters;
}
