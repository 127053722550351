import React, { useMemo } from "react";
import Select from "../../UI/Form/Select/Select";
import SelectWithCheckboxes from "../../UI/Form/Select/SelectWithCheckboxes";
import { useDispatch } from "react-redux";
import { reloadCharts } from "../../store/actions";
import { filterTypes, sortIfDaysOfWeek } from "../../utils/constants/constants";
import { filterEmpty, filterUnknown } from "./GeneralFilters";
import FilterValuesPagination from "./FilterValuesPagination";
import useFilterPagination from "../../hooks/useFilterPagination";

export default function FilterItemDropdown({
  select,
  filters,
  type,
  classNamePrefix,
  menuIsOpen,
  searchFilterLoading,
  filter,
}) {
  const isMulti = useMemo(
    () => filterTypes.find((ft) => ft.value === type).isMulti,
    [type]
  );

  const optionSelected = useMemo(
    () => filters.filter((f) => f.checked),
    [filters]
  );

  const { totalCount } = filter ?? {};
  const dispatch = useDispatch();

  const nonEmptyDefinedFilters = sortIfDaysOfWeek(filters)
    .filter(filterUnknown)
    .filter(filterEmpty);

  const {
    fetchFilterValues,
    page,
    setPage,
    loadOptions,
    searchValue,
    onSelectInputChange,
  } = useFilterPagination(filter);

  const handleCheckboxSelect = (selected, actionObject) => {
    if (!selected || selected.length === 0) {
      filters.filter((f) => f.checked).map((f) => select(f, type));
    } else {
      // drop search on select
      onSelectInputChange("", { action: "input-change" }, page);

      if (actionObject) {
        // Try to only call select() only once.

        const { action, option, removedValue } = actionObject;
        if (action === "select-option") {
          select({ ...option, checked: false }, type);
          return;
        } else if (action === "remove-value") {
          select({ ...removedValue, checked: true }, type);
          return;
        } else if (action === "deselect-option") {
          select({ ...option, checked: true }, type);
          return;
        }
      }

      // Since we got here, we'll need to call select() once per each possible option, because we don't know what
      // changed. Though this may be optimized further by going through each selected option, comparing them with the
      // previously selected options, then only call select() for each difference. Though likely the Select All option
      // is the only thing that should _not_ get us to the above "Try to call only select() once" bit, and since I made
      // Select All not appear for too large option lists, this diff optimization may not be that necessary.

      const currectSelected = filters.map((f) => ({
        ...f,
        checked: !selected.find((s) => s.value === f.value),
      }));
      // Select checked/uncheckedness for each option, but do not reload the charts yet (false).
      currectSelected.map((f) => select(f, type, false));
      // Now reload the charts.
      dispatch(reloadCharts());
    }
  };

  return (
    <div style={{ position: "relative", padding: "0px 5px" }}>
      {isMulti ? (
        <SelectWithCheckboxes
          options={nonEmptyDefinedFilters}
          onChange={handleCheckboxSelect}
          value={optionSelected}
          classNamePrefix={classNamePrefix}
          menuIsOpen={menuIsOpen}
          loadOptions={loadOptions}
          isLoading={searchFilterLoading}
          searchValue={searchValue}
          onSelectInputChange={onSelectInputChange}
          totalCount={totalCount}
        />
      ) : (
        <Select
          defaultOptions={nonEmptyDefinedFilters}
          options={nonEmptyDefinedFilters}
          placeholder="Search..."
          onChange={(o) => {
            // drop search on select
            onSelectInputChange("", { action: "input-change" });
            select(o, type);
          }}
          tabSelectsValue={false}
          value={nonEmptyDefinedFilters?.find((f) => f.checked) || ""}
          classNamePrefix={classNamePrefix}
          menuIsOpen={menuIsOpen}
          isLoading={searchFilterLoading}
          inputValue={searchValue}
          onInputChange={onSelectInputChange}
        />
      )}
      <FilterValuesPagination
        totalCount={totalCount}
        quantityInStore={filters.length}
        page={page}
        setPage={setPage}
        fetchFilterValues={fetchFilterValues}
        searchValue={searchValue}
      />
    </div>
  );
}
