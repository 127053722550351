import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "../../../../UI/Flex/Flex";
import Input from "../../../../UI/Form/Input/Input";
import Select from "../../../../UI/Form/Select/Select";
import { getRidOfAggregation } from "../../../../charts/TableView/Elements/EditableMenu";
import { columnFormats } from "../../../../utils/constants/constants";
import { getAggregationPrefix } from "../Layout/Column/RegularColumn";
import { getNestedFormat } from "../Layout/helper";
import styled from "@emotion/styled";
import useDebounceChange from "../../../../hooks/useDebounceChange";
import { useState } from "react";

const Container = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background: ${theme.divider};
  margin-bottom: 30px;
  padding: 10px;
`
);

export default function TooltipRow({
  row,
  fields,
  overrides,
  index,
  onTooltipRowChange,
  removeTooltipRowItem,
  provided,
  snapshot,
}) {
  const value = fields.find(
    (field) => field.name === getRidOfAggregation(row.key, overrides)
  );

  const [label, setLabel] = useState(
    row.label ?? value?.mapping?.displayName ?? ""
  );

  /**
   * Changes the key for a given option by finding the corresponding override and
   * generating the aggregation prefix.
   *
   * @param {Object} option - The option object which contains the name property.
   */
  function changeKey(option) {
    const { name } = option;

    // Find the override object in the overrides array where the name matches the option name
    const { aggregation } =
      overrides.find((override) => override.name === name) ?? {};

    // Get the aggregation prefix using the option name and the aggregation from the found override
    const prefix = getAggregationPrefix(aggregation);

    // Trigger a change in the tooltip row, updating the key with the new aggregated name
    onTooltipRowChange("update", {
      key: "key",
      value: prefix + name,
      index,
      name,
    });
  }

  const { debounce } = useDebounceChange();

  /**
   * Handles the change event for the label input field.
   *
   * @param {Event} e - The input change event.
   */
  function changeLabel(e) {
    const newValue = e.target.value;

    // Set the label state to the new value from the input field
    setLabel(newValue);

    // Use the debounce function to delay the execution of onTooltipRowChange
    debounce(() =>
      onTooltipRowChange("update", { key: "label", value: newValue, index })
    );
  }

  return (
    <Container
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      isDragging={snapshot.isDragging}
    >
      <Flex alignItems="end">
        <Select
          options={fields}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.name}
          value={value}
          onChange={changeKey}
          label="Column"
        />
        <FontAwesomeIcon
          icon={["fal", "times-circle"]}
          style={{
            color: "orangered",
            fontSize: 22,
            cursor: "pointer",
            margin: "0px 5px 10px 5px",
          }}
          onClick={() => removeTooltipRowItem("remove", { index })}
        />
      </Flex>

      <br />
      <Select
        options={columnFormats}
        value={getNestedFormat(row.type)}
        onChange={(option) =>
          onTooltipRowChange("update", {
            key: "type",
            value: option.value,
            index,
          })
        }
        label="Format"
      />
      <br />
      <Input
        label="Label"
        inPlaceEdit
        value={label}
        hideEditIcon
        onChange={changeLabel}
      />
    </Container>
  );
}
