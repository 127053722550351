import { useState } from "react";
import useDebounceChange from "../../../../../hooks/useDebounceChange";
import Input from "../../../../../UI/Form/Input/Input";
import TextArea from "../../../../../UI/Form/Textarea/Textarea";

export default function TableStyles(props) {
  const { setChartState, chart } = props;
  const [message, setMessage] = useState(chart.noResultsMessageOverride);

  const { debounce } = useDebounceChange();

  function changeTableWidth(e) {
    setChartState({ ...chart, width: +e.target.value });
  }

  function changeNoResultsMessage(e) {
    setMessage(e.target.value);

    debounce(() =>
      setChartState({ ...chart, noResultsMessageOverride: e.target.value })
    );
  }

  return (
    <div>
      <div>Global Table Styles</div>
      <br />
      <Input
        label="Table width (%)"
        onChange={changeTableWidth}
        value={chart.width ?? ""}
        type="number"
      />

      <br />
      <TextArea
        type="textarea"
        label="No Results Message Override"
        value={message ?? ""}
        onChange={changeNoResultsMessage}
        resize="vertical"
      />
      <br />
    </div>
  );
}
