import formatter from "../../../utils/formatters/formatter";
import { diffDiv } from "../../../utils/func";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Target from "../../utils/Target";
import getVisualizationLabel from "../../../utils/getVisualizationLabel";
import { parse, format as dateFormat, endOfWeek, startOfWeek } from "date-fns";

function getAbsoluteValue(value, isAbsolute) {
  if (!isAbsolute || isNaN(+value)) {
    return value;
  }

  return Math.abs(value);
}

export default function tableFunctionConvertor(
  str,
  row,
  calculateNAColumns,
  totalsConfig,
  meta,
  periodCalculationSettings = {}
) {
  const [, operator, one, two, three, four, five, six] = str.split("::");
  const isRounded = str.includes("ROUNDED");
  const isAbsolute = str.includes("ABS");
  const fields = meta?.fields ?? [];
  const isPercentOption = percents.find((op) => op === operator);
  const precision = isRounded ? 0 : 1;
  const append = str.includes("APPEND(")
    ? str.split("APPEND(")[1].split(")")[0]
    : null;

  const format = isPercentOption
    ? isRounded
      ? "percent-sig"
      : "percent"
    : isRounded
    ? "currency-whole"
    : "currency";

  if (
    calculateNAColumns &&
    calculateNAColumns.find((nac) => row[nac.key] && row[nac.key] === nac.value)
  ) {
    return {
      formatted: "n/a",
      value: null,
      align: "left",
    };
  }

  // TODO: change this in future
  const { hiddenColumns, rowGroupKey } = totalsConfig ?? {};
  if (hiddenColumns?.includes(str) && row[rowGroupKey] === "Total") {
    return {
      formatted: " ",
      value: null,
      align: "left",
      isProgressBarHidden: true,
    };
  }

  switch (operator) {
    //-----------------PERCENTS--------------//
    case "PERCENT": {
      const value = getAbsoluteValue(row[one] / row[two], isAbsolute);
      const notNumber = isNaN(value) || !isFinite(value);

      return {
        formatted: notNumber
          ? "--"
          : formatter(value, format, precision, append),
        value: notNumber ? "--" : value,
        align: "right",
        description: `${definition(one)} / ${definition(two)}`,
      };
    }

    case "PERCENT_WHOLE": {
      const value = getAbsoluteValue(row[one] / row[two], isAbsolute);
      const notNumber = isNaN(value) || !isFinite(value);

      return {
        formatted: notNumber
          ? "--"
          : formatter(value, "percent-whole", precision, append),
        value: notNumber ? "--" : value,
        align: "right",
        description: `${definition(one)} / ${definition(two)}`,
      };
    }

    case "PERCENT_MINUS_DIVISION": {
      const value = (row[one] - row[two]) / row[three];
      const notValue = isNaN(value) || !isFinite(value);
      const formatted = notValue ? "--" : formatter(value, format, precision);

      return {
        value: notValue ? null : value,
        formatted,
        align: "right",
        description: `(${definition(one)} - ${definition(two)}) / ${definition(
          three
        )}`,
      };
    }

    case "PERCENT_MINUS_DIVISION_DENOMINATOR": {
      const value = row[one] - row[two] / row[three];
      const notValue = isNaN(value) || !isFinite(value);
      const formatted = notValue ? "--" : formatter(value, format, precision);

      return {
        value: notValue ? null : value,
        formatted,
        align: "right",
        description: `${definition(one)} - (${definition(two)} / ${definition(
          three
        )})`,
      };
    }

    case "PERCENT_CANCEL_YOY": {
      const first = row[one] / (+row[one] + +row[two]);
      const second = row[three] / (+row[three] + +row[four]);
      const value = getAbsoluteValue(first - second, isAbsolute);

      return {
        value,
        formatted: isNaN(value) ? "--" : formatter(value, format, precision),
        align: "right",
        description: `${definition(one)} / 
        (${definition(one)} + ${definition(two)}) - 
        ${definition(three)} / 
        (${definition(three)} + ${definition(four)})`,
      };
    }

    case "PERCENT_NET_CANCEL_YOY": {
      const first = row[one] / (+row[two] + +row[three]);
      const second = row[four] / (+row[five] + +row[six]);
      const value = getAbsoluteValue(first - second, isAbsolute);

      return {
        value,
        formatted: isNaN(value) ? "--" : formatter(value, format, precision),
        align: "right",
        description: `${definition(one)} / 
        (${definition(two)} + ${definition(three)}) - 
        ${definition(four)} / 
        (${definition(five)} + ${definition(six)})`,
      };
    }

    case "VALUE_AS_PERCENT": {
      const value = getAbsoluteValue(row[one], isAbsolute);

      return {
        value,
        formatted: isNaN(value) ? "--" : formatter(value, format, precision),
        align: "right",
        description: definition(one),
      };
    }

    case "PERCENT_DIVISION_ADDITION": {
      const value = getAbsoluteValue(
        row[one] / (+row[two] + +row[three]),
        isAbsolute
      );

      return {
        value,
        formatted: isNaN(value) ? "--" : formatter(value, format, precision),
        align: "right",
        description: `${definition(one)} / (${definition(two)} + ${definition(
          three
        )})`,
      };
    }

    case "PRE_CALCULATED_PERCENT_DIFFERENCE": {
      const value = row[one] - row[two];
      return {
        value,
        formatted: isNaN(value) ? "--" : formatter(value, "percent", 1),
        align: "right",
        description: `${definition(one)} - ${definition(two)}`,
      };
    }

    case "DIFFERENCE_ON_DIVISION_PERCENT": {
      const current = (row[one] - row[two]) / row[two];
      const prev = (row[three] - row[four]) / row[four];
      const value = getAbsoluteValue(current - prev, isAbsolute);

      return {
        formatted:
          isNaN(value) || !isFinite(value)
            ? "--"
            : formatter(value, format, precision),
        value,
        align: "right",
        description: `(${definition(one)} - ${definition(two)}) / ${definition(
          two
        )} - (${definition(three)} - ${definition(four)}) / ${definition(
          four
        )}`,
      };
    }

    case "ADDITION_ON_DIVISION_PERCENT": {
      const current = (row[one] - row[two]) / row[two];
      const prev = (row[three] - row[four]) / row[four];
      const value = getAbsoluteValue(current + prev, isAbsolute);

      return {
        formatted:
          isNaN(value) || !isFinite(value)
            ? "--"
            : formatter(value, format, precision),
        value,
        align: "right",
        description: `(${definition(one)} - ${definition(two)}) / ${definition(
          two
        )} + (${definition(three)} - ${definition(four)}) / ${definition(
          four
        )}`,
      };
    }

    case "COMPARE_DIVISION_ON_DIVISION_PERCENT": {
      const current = row[one] / row[two];
      const prev = row[three] / row[four];
      const value = getAbsoluteValue(current / prev, isAbsolute);

      return {
        formatted: isNaN(value) ? "--" : formatter(value, format),
        value,
        align: "right",
        description: `(${definition(one)} / ${definition(two)}) / (${definition(
          three
        )} / ${definition(four)})`,
      };
    }

    case "DIVISION_PERCENT":
    case "DIVISION_PERCENT_PRECISION_2":
    case "DIVISION_PERCENT_PRECISION_3": {
      // TODO: make the expected operator look more like 'DIVISION_PERCENT(0.3f)'
      const value = getAbsoluteValue(row[one] / row[two], isAbsolute);
      const precision = isRounded ? 0 : operator.replace(/^\D+/g, "") || 1;
      const notValue = isNaN(value) || !isFinite(value);

      return {
        formatted: notValue ? "--" : formatter(value, "percent", precision),
        value,
        align: "right",
        description: `${definition(one)} / ${definition(two)}`,
      };
    }

    case "PERCENT_POINT_DIFFERENCE": {
      const newVal = row[one] / row[two];
      const oldVal = row[three] / row[four];

      if (
        isNaN(newVal) ||
        isNaN(oldVal) ||
        !isFinite(newVal) ||
        !isFinite(oldVal)
      ) {
        return {
          formatted: "--",
          value: null,
          align: "right",
          description: `(${definition(one)} / ${definition(
            two
          )}) - (${definition(three)} / ${definition(four)})`,
        };
      }

      const change = getAbsoluteValue(newVal - oldVal, isAbsolute);
      return {
        formatted: formatter(change, "percent", precision),
        value: isNaN(change) ? null : change,
        align: "right",
      };
    }

    case "DIVISION_PERCENT_MINUS_ONE": {
      if (!row[one] || !row[two]) {
        return {
          formatted: "--",
          value: null,
          align: "right",
          description: `(${definition(one)} / ${definition(two)}) - 1`,
        };
      }

      const value = getAbsoluteValue(row[one] / row[two] - 1, isAbsolute);

      return {
        formatted: formatter(value, "percent", precision),
        value,
        align: "right",
      };
    }

    case "ONE_MINUS_PERCENT": {
      if (!row[one] || !row[two]) {
        return {
          formatted: "--",
          value: null,
          align: "right",
          description: `(1 - ${definition(one)}) / ${definition(two)}`,
        };
      }

      const value = getAbsoluteValue((1 - row[one]) / row[two], isAbsolute);

      return {
        formatted: formatter(value, "percent", precision),
        value,
        align: "right",
      };
    }

    case "BAD_DEBT": {
      const metTarget = +row[one] >= +row[two];

      if (metTarget) {
        return {
          formatted: "100%",
          value: 1,
          align: "right",
        };
      } else {
        const target = new Target(row[one], row[two], "%", 1);
        target.setInvertSuccess();
        target.setNewValue(1 - target.value);

        return {
          formatted: target.formatted,
          value: target.value,
          align: "right",
          description: `If ${one} great or equal to ${two} then 100%, else ${two} / ${one}`,
        };
      }
    }

    case "DIFFERENCE_PERCENT":
    case "DIFFERENCE_PERCENT_1": {
      const val = getAbsoluteValue(
        (row[one] || 0) - (row[two] || 0),
        isAbsolute
      );

      const precision = +operator.split("_")[2];

      return {
        formatted: formatter(val, "percent", precision),
        value: val,
        align: "right",
        description: `${definition(one)} - ${definition(two)}`,
      };
    }

    case "PERCENT_DIFFERENCE":
    case "PERCENT_DIFFERENCE_PRECISION_2":
    case "PERCENT_DIFFERENCE_PRECISION_3": {
      const calculatePercentDifference = (val1, val2) => {
        if (val1 === null || val2 === null) {
          return;
        }

        return val1 && +val1 !== 0
          ? getAbsoluteValue((val2 - val1) / val1, isAbsolute)
          : "n/a";
      };

      const value = calculatePercentDifference(
        getAbsoluteValue(row[one], true),
        row[two]
      );
      const decimal = operator.split("_").pop();

      return {
        formatted: value
          ? formatter(value, format, +decimal || precision, append)
          : "--",
        value: value || null,
        align: "right",
        description: `(${definition(two)} - ${definition(one)}) / ${definition(
          one
        )}`,
      };
    }

    case "DIFF_DIV_PERCENT": {
      const [C, D] = [row[three], row[four]].map((number) =>
        getAbsoluteValue(number, true)
      );
      const value = getAbsoluteValue(
        diffDiv([row[one], row[two], C, D]) / (C / D),
        isAbsolute
      );

      return {
        formatted:
          value && isFinite(value)
            ? formatter(value, format, precision, append)
            : "--",
        value: value && isFinite(value) ? value : null,
        align: "right",
        description: `((${definition(one)} / ${definition(
          two
        )}) - (${definition(three)} / ${definition(four)})) / (${definition(
          three
        )} / ${definition(four)})`,
      };
    }

    case "DIFF_SUB_DIV_PERCENT": {
      const value = getAbsoluteValue(
        diffDiv([row[one], row[two], row[three], row[four]]),
        isAbsolute
      );

      return {
        formatted:
          value && isFinite(value)
            ? formatter(value, format, precision, append)
            : "--",
        value: value && isFinite(value) ? value : null,
        align: "right",
        description: `(${definition(one)} / ${definition(two)}) - (${definition(
          three
        )} / ${definition(four)})`,
      };
    }

    case "PERCENT_OVER_PERCENT": {
      const calculatePercentChange = (newNumber, originalNumber) => {
        return getAbsoluteValue(
          (newNumber - originalNumber) / originalNumber,
          isAbsolute
        );
      };

      const value = calculatePercentChange(
        row[three] / row[four],
        row[one] / row[two]
      );

      return {
        formatted: row[four]
          ? formatter(value, format, precision, append)
          : "--",
        value: value && isFinite(value) ? value : null,
        align: "right",
        description: `((${definition(three)} / ${definition(
          four
        )}) - (${definition(one)} / ${definition(two)})) / (${definition(
          four
        )}) - (${definition(one)})`,
      };
    }

    //-----------------CUSTOM FORMATS--------------//
    case "REPLACE_WITH_ICON": {
      const value = row[one];

      if (value === null) {
        return {
          value,
          formatted: null,
          align: "right",
        };
      }

      const icon = value?.toString().toLowerCase() === "no" ? "times" : "check";

      return {
        value,
        formatted: (
          <FontAwesomeIcon icon={["fas", icon]} style={{ color: "white" }} />
        ),
        align: "center",
      };
    }

    case "GROUPED_MONTHS": {
      const obj = {
        value: row[one],
        formatted:
          row[one] <= 6
            ? "0-6 Months"
            : row[one] <= 12
            ? "6-12 Months"
            : "12+ Months",
        align: "left",
      };

      return obj;
    }

    case "STATIC": {
      return {
        formatted: row[one] || "--",
        value: null,
        align: "right",
      };
    }

    //-----------------INTEGERS & NUMBERS--------------//
    case "INTEGER": {
      const value = getAbsoluteValue(row[one], isAbsolute);

      return {
        value,
        formatted: isNaN(value) ? "--" : formatter(value, "integer", precision),
        align: "right",
        description: definition(one),
      };
    }

    case "DIFFERENCE_INTEGER": {
      const val = getAbsoluteValue(
        (row[one] || 0) - (row[two] || 0),
        isAbsolute
      );

      return {
        formatted: formatter(val, "integer"),
        value: val,
        align: "right",
        description: `${definition(one)} - ${definition(two)}`,
      };
    }

    case "DIFFERENCE_DECIMAL":
    case "DIFFERENCE_DECIMAL_1": {
      const val = getAbsoluteValue(
        (row[one] || 0) - (row[two] || 0),
        isAbsolute
      );

      const oneFloating = +operator.split("_")[2] === 1;

      return {
        formatted: formatter(val, oneFloating ? "decimal-tenth" : "decimal"),
        value: val,
        align: "right",
        description: `${definition(one)} - ${definition(two)}`,
      };
    }

    case "ADDITION_INTEGER": {
      const val = getAbsoluteValue(
        (row[one] || 0) + (row[two] || 0),
        isAbsolute
      );

      return {
        formatted: formatter(val, "integer"),
        value: val,
        align: "right",
        description: `${definition(one)} + ${definition(two)}`,
      };
    }

    case "DIFFERENCE_INTEGER_FROM_ADDITION": {
      const val = getAbsoluteValue(
        (row[one] || 0) + (row[two] || 0) - (row[three] || 0),
        isAbsolute
      );

      return {
        formatted: formatter(val, "integer"),
        value: val,
        align: "right",
        description: `(${definition(one)} + ${definition(two)}) - (${definition(
          three
        )}`,
      };
    }

    case "DIVISION":
    case "DIVISION_1":
    case "DIVISION_0": {
      const value = getAbsoluteValue(row[one] / row[two], isAbsolute);
      const precision = operator.split("_")[1] ?? 2;

      const x = three.includes("APPEND") ? "x" : "";
      const notValue =
        isNaN(value) || value === -Infinity || value === Infinity;

      return {
        formatted: notValue ? "--" : value.toFixed(precision) + x,
        value,
        align: "right",
        description: `${definition(one)} / ${definition(two)}`,
      };
    }

    case "DIVISION_DIFFERENCE": {
      const denominator = (row[two] || 0) - (row[three] || 0);
      const val = getAbsoluteValue((row[one] || 0) / denominator, isAbsolute);
      const regex = /\((.*?)\)/;
      const hasSuffix = four.includes("suffix(");
      const suffix = hasSuffix ? regex.exec(four)[1] : "";

      return {
        formatted: denominator
          ? formatter(val, "decimal-tenth") + suffix
          : "--",
        value: val,
        align: "right",
        description: `${definition(one)} / (${definition(two)} - ${definition(
          three
        )})}`,
      };
    }

    case "DIVISION_ADDITION": {
      const denominator = (row[two] || 0) + (row[three] || 0);
      const val = getAbsoluteValue((row[one] || 0) / denominator, isAbsolute);

      return {
        formatted: denominator ? formatter(val, "decimal-tenth") : "--",
        value: val,
        align: "right",
        description: `${definition(one)} / (${definition(two)} + ${definition(
          three
        )})}`,
      };
    }

    case "DIFF_DIV":
    case "DIFF_DIV_1":
    case "DIFF_DIV_2": {
      const value = getAbsoluteValue(
        diffDiv([row[one], row[two], row[three], row[four]]),
        isAbsolute
      );

      const decimal = +operator.split("_").pop() || 0;

      const format = {
        0: "integer",
        1: "decimal-tenth",
        2: "decimal",
      };

      return {
        formatted: !isNaN(value)
          ? formatter(value, format[decimal], decimal, append)
          : "--",
        value: !isNaN(value) ? value : null,
        align: "right",
        description: `${definition(one)} / (${definition(two)} - ${definition(
          three
        )}) / ${definition(four)}`,
      };
    }

    //-----------------CURRENCY--------------//
    case "VALUE_AS_CURRENCY": {
      const value = getAbsoluteValue(row[one], isAbsolute);

      return {
        value,
        formatted: isNaN(value) ? "--" : formatter(value, format, precision),
        align: "right",
        description: definition(one),
      };
    }

    case "DIVISION_CURRENCY": {
      const value = getAbsoluteValue(row[one] / row[two], isAbsolute);

      return {
        formatted:
          isNaN(value) || !isFinite(value)
            ? "--"
            : formatter(value, isRounded ? "currency-whole" : "currency"),
        value,
        align: "right",
        description: `${definition(one)} / ${definition(two)}`,
      };
    }

    case "DIVISION_CURRENCY_UK": {
      const value = row[one] / row[two];

      return {
        formatted:
          isNaN(value) || !isFinite(value)
            ? "--"
            : formatter(value, "$,.2f-uk"),
        value,
        align: "right",
        description: `${definition(one)} / ${definition(two)}`,
      };
    }

    case "COMPARE_DIVISION_ON_DIVISION_CURRENCY": {
      const current = row[one] / row[two];
      const prev = row[three] / row[four];
      const value = getAbsoluteValue(current / prev, isAbsolute);

      return {
        formatted:
          isNaN(value) || !isFinite(value)
            ? "--"
            : formatter(value, "currency"),
        value,
        align: "right",
        description: `(${definition(one)} / ${definition(two)}) / (${definition(
          three
        )} / ${definition(four)})`,
      };
    }

    case "DIFFERENCE": {
      const val = getAbsoluteValue(row[one] - row[two], isAbsolute);

      return {
        formatted: formatter(val, format),
        value: val,
        align: "right",
        description: `${definition(one)} - ${definition(two)}`,
      };
    }

    case "PERIOD_DIVISION_DIFFERENCE_CURRENCY": {
      const { groupingKey, rowValues = [] } = periodCalculationSettings;

      const desc1 = getDescriptionWithPrefix(currPeriodPrefix, one);
      const desc2 = getDescriptionWithPrefix(currPeriodPrefix, two);
      const desc3 = getDescriptionWithPrefix(prevPeriodPrefix, one);
      const desc4 = getDescriptionWithPrefix(prevPeriodPrefix, two);

      const description = `${desc1} / ${desc2} - ${desc3} / ${desc4}`;

      // set description anyway
      const withDescription = {
        ...initialEmptyDataObject,
        description,
      };

      // Find the index of the current row in rowValues based on the groupingKey
      const currentIndex = rowValues.findIndex(
        (item) => item[groupingKey] === row[groupingKey]
      );

      // If the current row is the first one, return initialEmptyDataObject
      if (!currentIndex || currentIndex === -1) {
        return withDescription;
      }

      // Get the previous row
      const prevRow = rowValues[currentIndex - 1];

      // Calculate the value based on the specified formula
      const value = getAbsoluteValue(
        row[one] / row[two] - prevRow[one] / prevRow[two],
        isAbsolute
      );

      if (isNaN(value) || !isFinite(value)) {
        return withDescription;
      }

      return {
        formatted: formatter(value, "currency"),
        value,
        align: "right",
        description,
      };
    }

    // DATE FORMATS

    case "WEEK_RANGE_UK":
      if (!row[one] || !row[two])
        return { formatted: "--", value: null, align: "center" };

      const yearDate = new Date(+row[one], 1, 1);

      const d = parse(String(+row[two]), "I", yearDate);
      const startString = dateFormat(startOfWeek(d), "dd/MM/yyyy");
      const endString = dateFormat(endOfWeek(d), "dd/MM/yyyy");
      const formattedRange = startString + " - " + endString;
      return { formatted: formattedRange, value: d, align: "left" };

    default: {
      const value = getAbsoluteValue(row[one] / row[two], isAbsolute);

      return {
        formatted: formatter(value, format, precision, append),
        value: value || "--",
        align: "right",
        description: `${definition(one)} / ${definition(two)}`,
      };
    }
  }

  function definition(column) {
    const label = getVisualizationLabel(fields, column);
    if (!label) {
      return column;
    }

    return label
      .replace(/^(Sum)/, "")
      .replace(/^(Avg)/, "")
      .trim();
  }

  function getDescriptionWithPrefix(prefix, column) {
    return prefix + definition(column);
  }
}

const percents = [
  "PERCENT_DIFFERENCE",
  "PERCENT_DIFFERENCE_PRECISION_2",
  "PERCENT_DIFFERENCE_PRECISION_3",
  "PERCENT_OVER_PERCENT",
  "PERCENT",
  "DIFF_DIV_PERCENT",
  "PERCENT_DIVISION_ADDITION",
  "VALUE_AS_PERCENT",
  "COMPARE_DIVISION_ON_DIVISION_PERCENT",
  "PERCENT_CANCEL_YOY",
  "PERCENT_NET_CANCEL_YOY",
  "BAD_DEBT",
  "PERCENT_MINUS_DIVISION",
  "DIFF_SUB_DIV_PERCENT",
  "DIFFERENCE_ON_DIVISION_PERCENT",
  "ADDITION_ON_DIVISION_PERCENT",
  "PERCENT_MINUS_DIVISION_DENOMINATOR",
];

const initialEmptyDataObject = {
  formatted: "--",
  value: null,
  align: "right",
  description: "",
};

// Prefix for labeling the current period, e.g., "Current Month" or "Current Year"
const currPeriodPrefix = "Current ";

// Prefix for labeling the previous period, e.g., "Previous Month" or "Previous Year"
const prevPeriodPrefix = "Previous ";
