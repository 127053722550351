import { orderBy } from "lodash-es";
import tableFunctionConvertor from "../../charts/TableView/functions/tableFunctionConvertor";
import formatter from "../formatters/formatter";
import tableOpenMathFunctionConvertor from "../../charts/TableView/functions/tableOpenMathFunctionConvertor";

const sortHourOfDay = ({ key, sortType }, data, direction) => {
  const formatted = data.map((item) => ({
    ...item,
    formatted: formatter(item[key], sortType),
    sortedKey: parseInt(item[key]),
  }));

  const am = formatted.filter((f) => f.formatted.toLowerCase().includes("am"));
  const pm = formatted.filter((f) => f.formatted.toLowerCase().includes("pm"));

  const result = [
    ...orderBy(am, ["sortedKey"], ["asc"]),
    ...orderBy(pm, ["sortedKey"], ["asc"]),
  ];

  return direction.toLowerCase() === "asc" ? result : result.reverse();
};

const sortDates = ({ key }, data, direction) => {
  function stringToDateObject(date) {
    if (date && !isNaN(Date.parse(date))) {
      return new Date(date);
    }

    return date;
  }

  const clonnedArray = data.map((d) => ({
    ...d,
    sortedKey: stringToDateObject(d[key]),
  }));

  const sorted = clonnedArray.sort((a, b) => a.sortedKey - b.sortedKey);
  return direction.toLowerCase() === "asc" ? sorted : sorted.reverse();
};

const sortTenures = ({ key }, data, direction) => {
  const tenures = [
    "0-4 Months",
    "4+ Months",
    "4-8 Months",
    "8-12 Months",
    "12+ Months",
    "12-24 Months",
    "24-36 Months",
    "36-48 Months",
    "48+ Months",
    "Total",
  ];

  const clonnedArray = data.map((d) => ({
    ...d,
    sortedKey: tenures.findIndex((tenure) => tenure === d[key]),
  }));

  return orderBy(clonnedArray, "sortedKey", direction.toLowerCase());
};

const specialSorting = (special = {}, data, direction) => {
  switch (special.sortType) {
    case "hourOfDay":
      return sortHourOfDay(special, data, direction);

    case "date":
      return sortDates(special, data, direction);

    case "tenure":
      return sortTenures(special, data, direction);

    default:
      return data;
  }
};

export const sortByKey =
  (
    key,
    direction = "ASC",
    regularFormatting,
    specialSortingTypes = [],
    formatOverrides,
    coloredColumnEdges,
    rowGroupKey
  ) =>
  (data) => {
    if (!data.filter((d) => d ?? d === 0).length) {
      return data;
    }

    const special = specialSortingTypes.find((sst) => sst.key === key);

    if (special) {
      return specialSorting(special, data, direction);
    }

    if (key.includes("::")) {
      const multiplier = key.includes("reverseColor") ? -1 : 1;
      const precalculatePercents = data.map((d) => ({
        ...d,
        sortedKey: multiplier * getValue(d, key, regularFormatting),
      }));

      return orderBy(
        precalculatePercents,
        ["sortedKey"],
        [direction.toLowerCase()]
      );
    }

    const prepareForSortData = data.map((item) => {
      // multiplier should be calculated separate for each item
      // because in format overrides we can have different condition values on same key
      const multiplier = getColorMultiplierOnSorting(
        key,
        formatOverrides,
        coloredColumnEdges,
        rowGroupKey,
        item
      );

      if (typeof item !== "string") {
        return {
          ...item,
          sortedKey: setMultiplierToNumbersOnly(item[key], multiplier),
        };
      }

      return item;
    });

    return orderBy(
      prepareForSortData,
      ["sortedKey"],
      [direction.toLowerCase()]
    );
  };

const getValueForSort = (value) => {
  if (["--", "n/a"].some((x) => x === value) || (!value && value !== 0)) {
    return -Infinity;
  }

  return isNaN(+value) ? value : +value;
};

const getValue = (row, key, regularFormatting) => {
  const isOpenMathFormula = key.includes("omf::");

  const { value } = isOpenMathFormula
    ? tableOpenMathFunctionConvertor(key, row)
    : tableFunctionConvertor(key, row, null, regularFormatting);

  return getValueForSort(value);
};

const getColorMultiplierOnSorting = (
  key,
  formatOverrides,
  coloredColumnEdges,
  rowGroupKey,
  item
) => {
  const formatOverride = (formatOverrides ?? []).find(
    (fOverride) =>
      fOverride.overrideKey === key &&
      item[rowGroupKey] === fOverride.conditionValue
  );

  const coloredColumnEdge = (coloredColumnEdges ?? []).find(
    (ccEdge) => ccEdge.columnName === key
  );

  if (coloredColumnEdge?.reverse || formatOverride?.reverseColor) {
    return -1;
  }

  return 1;
};

const setMultiplierToNumbersOnly = (value, multiplier) => {
  return isNaN(+value)
    ? getValueForSort(value)
    : multiplier * getValueForSort(value);
};
