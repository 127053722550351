import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Checkbox from "../../../../UI/Form/Checkbox/Checkbox";
import RadioButton from "../../../../UI/RadioButton/RadioButton";
import Select from "../../../../UI/Form/Select/Select";
import { getAggregationPrefix } from "./Column/RegularColumn";
import HorizontalTotals from "./HorizontalTotals";

export default function Totals({
  RadioGroup,
  Group,
  theme,
  chart,
  setChartState,
  fields,
}) {
  const { overrides, totalsAverages, totals, totalsOnly, horizontalTotals } =
    chart;

  function getTotalAverageColumns() {
    return (overrides ?? []).filter((override) => {
      const prefix = getAggregationPrefix(override.aggregation);
      return (totalsAverages ?? []).includes(prefix + override.name);
    });
  }

  function setTotalAverageColumns(options) {
    if (!options) {
      setChartState({ ...chart, totalsAverages: [] });
    }

    setChartState({
      ...chart,
      totalsAverages: options.map(
        (option) => getAggregationPrefix(option.aggregation) + option.name
      ),
    });
  }

  return (
    <div style={{ marginTop: 20 }}>
      <span>Totals</span>
      <RadioGroup>
        <RadioButton
          checked={!totals}
          onChange={() =>
            setChartState({
              ...chart,
              totals: false,
              totalsOnly: false,
            })
          }
        />
        <label>No Totals</label>
      </RadioGroup>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Group width="30%" background={theme.divider} />
        <Group width="30%" background={theme.divider} />
        <Group width="30%" background={theme.divider} />
      </div>
      <Group background={theme.divider}>
        <FontAwesomeIcon
          icon={["far", "times-circle"]}
          style={{ color: theme.notification.errorMain }}
        />
      </Group>
      <hr color={theme.background.secondary} />
      <RadioGroup>
        <RadioButton
          checked={!!totals}
          onChange={() => setChartState({ ...chart, totals: true })}
        />
        <label>Include Totals</label>

        <Checkbox
          label="Totals Only"
          checked={!!totalsOnly}
          color={theme.text.secondary}
          disabled={!totals}
          onChange={() =>
            setChartState({
              ...chart,
              totalsOnly: !totalsOnly,
            })
          }
        />
      </RadioGroup>
      {totals ? (
        <>
          <br />
          <Select
            options={overrides ?? []}
            value={getTotalAverageColumns()}
            onChange={setTotalAverageColumns}
            isClearable={false}
            isMulti
            menuPlacement="top"
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.name}
            label="Total Average Columns"
          />
          <br />
        </>
      ) : null}

      <Checkbox
        label="Horizontal Totals"
        checked={!!horizontalTotals}
        color={theme.text.secondary}
        onChange={() =>
          setChartState({
            ...chart,
            horizontalTotals: horizontalTotals
              ? null
              : { columns: [], header: "Total" },
          })
        }
      />
      <br />

      <HorizontalTotals
        chart={chart}
        setChartState={setChartState}
        fields={fields}
      />

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Group
          width={horizontalTotals ? "20%" : "30%"}
          background={theme.divider}
        >
          {totalsOnly && (
            <FontAwesomeIcon
              icon={["far", "times-circle"]}
              style={{ color: theme.notification.errorMain }}
            />
          )}
        </Group>
        <Group
          width={horizontalTotals ? "20%" : "30%"}
          background={theme.divider}
        >
          {totalsOnly && (
            <FontAwesomeIcon
              icon={["far", "times-circle"]}
              style={{ color: theme.notification.errorMain }}
            />
          )}
        </Group>
        <Group
          width={horizontalTotals ? "20%" : "30%"}
          background={theme.divider}
        >
          {totalsOnly && (
            <FontAwesomeIcon
              icon={["far", "times-circle"]}
              style={{ color: theme.notification.errorMain }}
            />
          )}
        </Group>
        {horizontalTotals && (
          <Group width="20%" background={theme.primary}>
            {totalsOnly && (
              <FontAwesomeIcon
                icon={["far", "times-circle"]}
                style={{ color: theme.notification.errorMain }}
              />
            )}
          </Group>
        )}
      </div>
      <Group background={theme.primary} />
    </div>
  );
}
