/**
 * @typedef QueryField
 * @property {string} name
 * @property {object} mapping
 * @property {string} mapping.displayName
 * @property {string} mapping.type
 */

export function isParameterized(query) {
  return query?.type === "parameterized";
}

/**
 * Checks if the given query UUID is known to be parameterized based on the
 * Redux state.
 *
 * @param queryUuid
 * @param state
 * @returns {null|boolean}
 */
export function isQueryUuidParameterizedInStore(queryUuid, state) {
  for (const query of state.dataSettings?.tinyQueries ?? []) {
    if (query.uuid === queryUuid) {
      return isParameterized(query);
    }
  }
  const headerData = state.layout?.header || null;
  const blocks =
    headerData?.blocks.length > 0 ? headerData.responseData.blocks : [];
  for (const block of blocks) {
    for (const visualization of block.visualizations) {
      if (!visualization.query) {
        continue;
      }
      if (visualization.query.uuid === queryUuid) {
        return isParameterized(visualization.query);
      }
    }
  }
  for (const page of state.layout?.tabs ?? []) {
    for (const block of page.blocks) {
      for (const visualization of block.visualizations) {
        if (!visualization.query) {
          continue;
        }
        if (visualization.query.uuid === queryUuid) {
          return isParameterized(visualization.query);
        }
      }
    }
  }
  return null;
}
