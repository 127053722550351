import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "../../../../UI/Form/Input/Input";
import { RowStyleContainer } from "./RowStyles";
import styled from "@emotion/styled";
import Select from "../../../../UI/Form/Select/Select";

const Type = styled.div(
  ({ theme }) => `
  svg {
    color: ${theme.notification.successMain};
    margin-left: 10px;
  }
`
);

export default function ParameterizedQuery({
  chart,
  setChartState,
  theme,
  isParameterized,
  queries,
}) {
  const { parameterizedFilterPrefix } = chart;

  function changeQuery(option) {
    setChartState({ ...chart, queryId: option.uuid });
  }

  return (
    <RowStyleContainer>
      <Type>
        <span>Query type: {isParameterized ? "Parameterized" : "Regular"}</span>
        <FontAwesomeIcon icon={["fas", "check-circle"]} />
      </Type>
      <br />

      <Select
        options={queries}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.uuid}
        label="Query"
        simpleValue={chart.queryId}
        onChange={changeQuery}
      />

      <br />
      {isParameterized && (
        <Input
          label="Parameterized Prefix"
          value={parameterizedFilterPrefix ?? ""}
          onChange={(e) =>
            setChartState({
              ...chart,
              parameterizedFilterPrefix: e.target.value,
            })
          }
        />
      )}
      <hr color={theme.background.secondary} />
    </RowStyleContainer>
  );
}
