import React, { useRef, useEffect, useCallback } from "react";
import { select } from "d3-selection";
import { axisBottom } from "d3-axis";
import { format, isValid } from "date-fns";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import formatter from "../../utils/formatters/formatter";
import { Label } from "./YAxis";

const G = styled.g(
  ({ theme, hideLines, xAxisTypeLabel }) => `
    line {
        stroke: ${theme.text.divider};
        opacity: 0;
        stroke-width: 4px;
    }
    text {
        fill: ${theme.text.secondary};
        font-size: ${xAxisTypeLabel ? 8 : 10}px;
    }
    path {
        stroke: ${theme.divider};
        ${hideLines ? "display: none;" : null}
    }
`
);

// This only works for dates currently

export default function XAxis(props) {
  const {
    width,
    height,
    xScale,
    numTicks,
    centerLabels,
    isLinear,
    xKeyFormat,
    hideXAxisTicks,
    hideXAxisLine,
    splitedDateFormat, // Where is this passed from?
    tickFormat, // Where is this passed from?
    xAxisDate,
    values,
    formatBands,
    dateParserStyle, // Where is this passed from?
    fiscalQuarterStartOffset,
    forceRotateLabels,
    xAxisTypeLabel,
    xAxisHeightOffset = 0,
    bands,
  } = props;
  const xAxis = useRef(null);
  const duplicateRef = useRef(null);
  duplicateRef.current = null;

  const hour = useCallback(
    (val) => {
      if (splitedDateFormat) {
        return `${format(
          val,
          splitedDateFormat[0]
        )} ${splitedDateFormat[1].toUpperCase()}${format(
          val,
          splitedDateFormat[1]
        )}`;
      }

      if (formatBands && dateParserStyle) {
        return formatter(
          val,
          dateParserStyle,
          null,
          null,
          null,
          fiscalQuarterStartOffset
        );
      }

      return isLinear || formatBands ? formatter(val, xKeyFormat) : val;
    },
    [
      splitedDateFormat,
      formatBands,
      dateParserStyle,
      isLinear,
      xKeyFormat,
      fiscalQuarterStartOffset,
    ]
  );

  const xAxisDateFormat = useCallback(
    (v) => {
      if (xAxisDate === "date-week") {
        return formatter(v, xAxisDate);
      }
      const isValidDate = isValid(v);
      if (!isValidDate) {
        return v;
      }

      const date = format(new Date(v), xAxisDate);

      if (duplicateRef.current !== date) {
        duplicateRef.current = date;
        return date;
      }

      return "";
    },
    [xAxisDate]
  );

  // Ticks only work for scaleTime
  // Num Ticks only works for multiple line chart
  const ticks =
    numTicks && !bands ? numTicks : width < 300 ? 5 : width < 750 ? 11 : 15;

  const tickDomain = xScale.domain();

  const tickValues =
    bands && ticks <= tickDomain.length
      ? getEvenlySpacedValues(tickDomain, Math.round(ticks))
      : tickDomain;

  const adjustTextLabels = useCallback(
    (selection) => {
      if (centerLabels) {
        selection.selectAll(".tick text");
      }
    },
    [centerLabels]
  );

  const shouldRotateLabels =
    forceRotateLabels || width / (values?.length || 1) < 18;

  const tickOffset = shouldRotateLabels ? height + xAxisHeightOffset : height;

  useEffect(() => {
    select(xAxis.current)
      .attr("transform", `translate(0, ${tickOffset + 4 || 0})`)
      .attr("data-cy", "x-axis")
      .call(
        axisBottom(xScale)
          .tickPadding([15])
          .ticks(ticks)
          .tickValues(bands && tickValues) // This is breaking everything
          .tickSize(-height, 0)
          .tickFormat(xAxisDate ? xAxisDateFormat : tickFormat ?? hour)
      )
      .call(adjustTextLabels)
      .selectAll("path")
      .style("display", () => (hideXAxisLine ? "none" : null));

    select(xAxis.current)
      .selectAll("text")
      .html(function (d, i, s) {
        let text = select(this).text();
        let splitText = text.split(" - ");
        if (s.length <= 5) return text;

        return splitText.length > 1 ? splitText[0] : text;
      })
      .attr(
        "transform",
        shouldRotateLabels ? "translate(-25, 5) rotate(-45) " : "translate(0)"
      );

    select(xAxis.current).selectAll("line").style("opacity", 0);
  }, [
    adjustTextLabels,
    bands,
    height,
    hideXAxisLine,
    hour,
    shouldRotateLabels,
    tickFormat,
    tickOffset,
    tickValues,
    ticks,
    xAxisDate,
    xAxisDateFormat,
    xScale,
  ]);

  return (
    <>
      <G
        ref={xAxis}
        hideTicks={hideXAxisTicks}
        hideLines={hideXAxisLine}
        xAxisTypeLabel={xAxisTypeLabel}
      />
      {xAxisTypeLabel ? (
        <Label
          transform={`translate(${width / 2}, ${
            shouldRotateLabels ? 252 : 248
          })`}
        >
          <text> {xAxisTypeLabel} </text>
        </Label>
      ) : null}
    </>
  );
}

XAxis.defaultProps = {
  dateFormat: "yyyy-MM-dd",
};

XAxis.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  margin: PropTypes.object,
  xScale: PropTypes.func,
  dateFormat: PropTypes.string,
  centerLabels: PropTypes.bool,
  isLinear: PropTypes.bool,
};

function getEvenlySpacedValues(originalArray, desiredCount) {
  // Adjust interval calculation
  let interval = (originalArray.length - 1) / (desiredCount - 1);
  let resultArray = [];

  for (let i = 0; i < desiredCount; i++) {
    // Round the index to nearest integer to avoid possible fractional index.
    let index = Math.round(i * interval);
    resultArray.push(originalArray[index]);
  }

  return resultArray;
}
