import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TippyTooltipWrapper from "../../../../../charts/Tooltip/TippyTooltipWrapper";

const OptionContainer = styled.div`
  color: ${(props) => props.theme.text.secondary};
  font-size: 13px;
  font-weight: 400;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.text.primary};
  }

  svg {
    margin-right: 14px;
  }
`;

const Content = styled.div`
  width: 335px;
  padding: 5px;
  font-size: 12px;
`;

export default function Option({ op, handleSelect }) {
  const option = (
    <OptionContainer data-cy="operator-option" onClick={() => handleSelect(op)}>
      <FontAwesomeIcon icon={["fas", op.icon]} />
      {op.label}
    </OptionContainer>
  );

  if (op.description) {
    return (
      <TippyTooltipWrapper
        content={
          <Content>
            {op.description.split("\n").map((line, index) => (
              <div key={line + index}>{line}</div>
            ))}
          </Content>
        }
      >
        {option}
      </TippyTooltipWrapper>
    );
  }

  return option;
}
