import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useHistory } from "react-router";
import styled from "@emotion/styled";
import {
  createUser,
  updateUser,
  loadUserRoles,
  loadUserGroups,
  loadUserAccounts,
} from "../../../store/actions";
import ButtonGroup from "../../../UI/ButtonGroup/ButtonGroup";
import Select from "../../../UI/Form/Select/Select";
import IOButton from "../../../UI/Form/Button/IOButton";
import Input from "../../../UI/Form/Input/Input";
import Form from "../../../UI/Form/Form";
import Checkbox from "../../../UI/Form/Checkbox/Checkbox";
import { sortBy, partition, noop } from "lodash-es";
import { useParams } from "react-router-dom";
import { handleError } from "../../../utils/errorHandling";
import axios from "../../../axios";
import Validate from "../../../UI/Form/Validate";
import UserAvatar from "../../UserProfile/UserAvatar/UserAvatar";
import {
  inviteReturnUrl,
  ROLE_TENANT_OWNER,
  passwordMinLength,
} from "../../../utils/constants/constants";
import Loading from "../../../UI/Loading/Loading";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > div {
      display: flex;
      flex-direction: column;
    }
  }
  justify-content: space-evenly;
  padding: ${(props) => (props.noPadding ? "0px 0px" : "40px 40px")};
`;

const FormControl = styled.div`
  display: inline-block;
  position: relative;
  margin-bottom: 40px;
  width: 254px;
`;

const UserAvatarUploader = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 25px;
`;

export default function CreateUserOrUpdate(props) {
  const userUuid = useParams().userId || props.userUuid;

  const { roles, groups, accounts, loading } = useSelector(
    (state) => state.userManagement,
    shallowEqual
  );
  const [localUser, setLocalUser] = useState(defaultUser);
  const [image, setImage] = useState({
    file: null,
    url: null,
    isRemoved: false,
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const layoutState = useSelector((state) => state.layout);
  const twoFactorAuthRequired = Boolean(layoutState.twoFactorAuthRequired);
  const [userLoading, setUserLoading] = useState(true);

  const rolesWithoutTenantOwner = useMemo(() => {
    return (roles ?? []).filter((v) => v.name !== ROLE_TENANT_OWNER);
  }, [roles]);
  const [, rolesWithoutAccountOtherThanTenantOwner] = useMemo(() => {
    return partition(rolesWithoutTenantOwner, (v) => v.accountUuid);
  }, [rolesWithoutTenantOwner]);

  useEffect(() => {
    if (userUuid) {
      (async () => {
        try {
          setUserLoading(true);
          const user = (await axios.get(`/api/v1/users/${userUuid}`)).data.data;
          setLocalUser({
            ...user,
            groups: user.groups.map((v) => v.uuid),
            isTenantOwner: user.role === ROLE_TENANT_OWNER,
          });
          const avatar = user.avatar || {};
          setImage({ file: null, url: avatar["512x512"] });
        } catch (e) {
          handleError(e);
        } finally {
          setUserLoading(false);
        }
      })();
    } else {
      setUserLoading(false);
      setLocalUser({
        ...defaultUser,
        isTenantOwner: !rolesWithoutTenantOwner.length,
      });
    }
  }, [dispatch, rolesWithoutTenantOwner.length, userUuid]);

  useEffect(() => {
    dispatch(loadUserRoles());
    dispatch(loadUserGroups());
    dispatch(loadUserAccounts());
  }, [dispatch]);

  const handleSubmit = ({ email, name, password, passwordRepeat }) => {
    createUserOrUpdate(
      {
        ...localUser,
        email,
        name,
        password: password || undefined,
        passwordRepeat: passwordRepeat || undefined,
      },
      history
    );
  };

  const createUserOrUpdate = (user, history) => {
    const userData = {
      ...user,
      image: image.file,
      isAvatarRemoved: image.isRemoved,
    };
    if (userData.invite) {
      userData.invite = "1";
      userData.returnUrl = inviteReturnUrl;
    } else {
      delete userData.invite;
    }
    if (userData.isTenantOwner) {
      userData.accountUuid = "";
      userData.groups = [];
      userData.role = ROLE_TENANT_OWNER;
    }
    if (userData.groups.length) {
      delete userData.group;
      userData.groups = userData.groups.map((uuid) => ({ uuid }));
    } else {
      delete userData.groups;
      userData.group = "";
    }

    if (user.action === "create") {
      dispatch(createUser(userData, history));
    } else {
      dispatch(updateUser(userData, history));
    }
  };

  const fields = ["name", "email", "password", "passwordRepeat"];
  const required = ["name", "email"];

  const accountsOptions = useMemo(() => {
    const accountsArray = accounts ?? [];
    return rolesWithoutAccountOtherThanTenantOwner.length
      ? [{ uuid: null, displayName: "(None)" }, ...accountsArray]
      : accountsArray;
  }, [accounts, rolesWithoutAccountOtherThanTenantOwner.length]);

  const groupsOptions = useMemo(() => {
    return groups
      ? groups.filter((v) => v.accountUuid === localUser.accountUuid)
      : [];
  }, [groups, localUser.accountUuid]);

  const rolesOptions = useMemo(() => {
    return sortBy(
      rolesWithoutTenantOwner.filter(
        (v) => v.accountUuid === localUser.accountUuid
      ),
      (v) => v.name !== "account_user" // Make this the first in the list.
    );
  }, [localUser.accountUuid, rolesWithoutTenantOwner]);

  // Ensure that a correct option is always selected.
  useEffect(() => {
    if (
      accountsOptions.length &&
      !accountsOptions.find((v) => v.uuid === localUser.accountUuid)
    ) {
      setLocalUser((localUser) => ({
        ...localUser,
        accountUuid: accountsOptions[0].uuid,
      }));
    }
  }, [accounts, accountsOptions, localUser.accountUuid]);

  useEffect(() => {
    if (
      rolesOptions.length &&
      !rolesOptions.find((v) => v.name === localUser.role)
    ) {
      setLocalUser((localUser) => ({
        ...localUser,
        role: rolesOptions[0].name,
      }));
    }
  }, [rolesOptions, localUser.role]);

  const getMinPasswordLength = useCallback(
    (value) => {
      if (userUuid) {
        return value ? passwordMinLength : 0;
      }

      return passwordMinLength;
    },
    [userUuid]
  );

  const onAttachImage = ({ file, url }) => {
    setImage({ file, url, isRemoved: false });
  };

  const onRemoveAvatar = () => {
    setImage({ file: null, url: null, isRemoved: true });
  };

  const hiddenVisibilityIfTenantOwner = localUser.isTenantOwner
    ? "hidden"
    : undefined;

  if (userLoading) {
    return <Loading />;
  }

  return (
    <>
      <Form
        fields={fields}
        required={required}
        initial={localUser}
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        {(formApi) => (
          <>
            <Container noPadding={props.userUuid}>
              <div>
                <UserAvatarUploader>
                  <UserAvatar
                    avatar={localUser.avatar}
                    image={image}
                    attachImage={onAttachImage}
                    removeAvatar={onRemoveAvatar}
                  />
                </UserAvatarUploader>
                <FormControl>
                  <Validate formApi={formApi} required min={3} max={50}>
                    <Input
                      name="name"
                      id="name"
                      label="Name"
                      value={formApi.values["name"]}
                      invalid={Boolean(
                        formApi.touched["name"] && formApi.errors["name"]
                      )}
                      errorMessage={
                        formApi.touched["name"] && formApi.errors["name"]
                          ? formApi.errors["name"].type === "required"
                            ? "Name is required"
                            : formApi.errors["name"].type === "min"
                            ? "Name must be at least 3 character"
                            : formApi.errors["name"].type === "max"
                            ? "Name must be less then 50 character"
                            : null
                          : null
                      }
                      required
                      data-lpignore
                    />
                  </Validate>
                </FormControl>
                <FormControl>
                  <Validate formApi={formApi} required email>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      value={formApi.values["email"]}
                      label="Email"
                      invalid={Boolean(
                        formApi.touched["email"] && formApi.errors["email"]
                      )}
                      errorMessage={
                        formApi.touched["email"] && formApi.errors["email"]
                          ? formApi.errors["email"].type === "required"
                            ? "Email is required"
                            : formApi.errors["email"].type === "email"
                            ? "Email must be a valid email address"
                            : null
                          : null
                      }
                      autoComplete="off"
                      data-lpignore="true"
                      required
                    />
                  </Validate>
                </FormControl>
              </div>
              <div>
                <div>
                  <FormControl>
                    <Checkbox
                      label="Tenant Owner"
                      checked={localUser.isTenantOwner}
                      disabled={!rolesWithoutTenantOwner.length}
                      onChange={
                        rolesWithoutTenantOwner.length
                          ? () =>
                              setLocalUser((localUser) => ({
                                ...localUser,
                                isTenantOwner: !localUser.isTenantOwner,
                              }))
                          : noop
                      }
                    />
                  </FormControl>

                  {accountsOptions.length > 1 && (
                    <FormControl
                      style={{
                        visibility: hiddenVisibilityIfTenantOwner,
                      }}
                    >
                      <Select
                        options={accountsOptions}
                        placeholder="Select Account..."
                        onChange={(value) =>
                          setLocalUser((localUser) => ({
                            ...localUser,
                            accountUuid: value.uuid,
                          }))
                        }
                        getOptionValue={(o) => o.uuid}
                        getOptionLabel={(o) => o.displayName}
                        simpleValue={localUser.accountUuid}
                        label="Account"
                        cy="select-account"
                      />
                    </FormControl>
                  )}
                  <FormControl
                    style={{
                      visibility: hiddenVisibilityIfTenantOwner,
                    }}
                  >
                    <Select
                      options={rolesOptions}
                      placeholder="Select Role..."
                      onChange={(value) =>
                        setLocalUser((localUser) => ({
                          ...localUser,
                          role: value.name,
                        }))
                      }
                      getOptionValue={(o) => o.name}
                      getOptionLabel={(o) => o.displayName}
                      simpleValue={localUser.role}
                      label="Role"
                      cy="select-role"
                    />
                  </FormControl>
                  <FormControl
                    style={{
                      visibility:
                        (localUser.isTenantOwner || !localUser.accountUuid) &&
                        "hidden",
                    }}
                  >
                    <Select
                      isMulti
                      options={groupsOptions}
                      onChange={(value) =>
                        setLocalUser((localUser) => {
                          const ret = {
                            ...localUser,
                            groups: localUser.groups,
                          };

                          if (!value) {
                            if (localUser.groups.length) {
                              ret.groups = [];
                            }
                          } else {
                            ret.groups = value
                              .map((v) => v.uuid)
                              .filter((v) => v);
                          }
                          return ret;
                        })
                      }
                      getOptionValue={(o) => o.uuid}
                      getOptionLabel={(o) => o.displayName}
                      simpleValue={localUser.groups}
                      label="Group"
                      placeholder="🔑 All access"
                      cy="select-group"
                    />
                  </FormControl>
                </div>
                <div>
                  {localUser.twoFactorEnabled ? (
                    <FormControl>
                      <Checkbox
                        label="Two-Factor Authentication Enabled"
                        checked={!localUser.disableTwoFactorAuthentication}
                        onChange={() =>
                          setLocalUser({
                            ...localUser,
                            disableTwoFactorAuthentication:
                              localUser.disableTwoFactorAuthentication
                                ? ""
                                : "1",
                          })
                        }
                        style={{ width: 25 }}
                      />
                    </FormControl>
                  ) : twoFactorAuthRequired ? (
                    // Indicate 2FA not set up for a user only if 2FA is enabled for the tenant.
                    <FormControl>
                      Two-Factor Authentication is not enabled for this user.
                    </FormControl>
                  ) : null}
                  {userUuid ? (
                    <FormControl>
                      <Validate
                        formApi={formApi}
                        required={!userUuid}
                        min={getMinPasswordLength(formApi.values["password"])}
                        max={50}
                      >
                        <Input
                          type="password"
                          name="password"
                          id="password"
                          label="Password"
                          value={formApi.values["password"]}
                          invalid={Boolean(
                            formApi.touched["password"] &&
                              formApi.errors["password"]
                          )}
                          errorMessage={
                            formApi.touched["password"] &&
                            formApi.errors["password"]
                              ? formApi.errors["password"].type === "required"
                                ? "Password is required"
                                : formApi.errors["password"].type === "min"
                                ? `Password must be at least ${passwordMinLength} characters`
                                : formApi.errors["password"].type === "max"
                                ? "Password must be less then 50 characters"
                                : null
                              : null
                          }
                          autoComplete="new-password"
                          data-lpignore
                        />
                      </Validate>
                    </FormControl>
                  ) : (
                    <FormControl>
                      <FormControl>
                        <Checkbox
                          label="Send Invite Email"
                          checked={!!localUser.invite}
                          onChange={() =>
                            setLocalUser({
                              ...localUser,
                              invite: !localUser.invite,
                            })
                          }
                        />
                      </FormControl>
                    </FormControl>
                  )}
                  <FormControl>
                    {formApi.values["password"] && (
                      <Validate
                        formApi={formApi}
                        required={!userUuid}
                        match="password"
                        min={getMinPasswordLength(formApi.values["password"])}
                        max={50}
                      >
                        <Input
                          type="password"
                          name="passwordRepeat"
                          id="passwordRepeat"
                          label="Repeat Password"
                          value={formApi.values["passwordRepeat"]}
                          invalid={Boolean(
                            (formApi.values["password"] &&
                              formApi.touched["passwordRepeat"] &&
                              formApi.errors["passwordRepeat"]) ||
                              (formApi.touched["password"] &&
                                formApi.values["passwordRepeat"].length <
                                  getMinPasswordLength(
                                    formApi.values["password"]
                                  ))
                          )}
                          errorMessage={
                            formApi.values["password"] &&
                            formApi.touched["passwordRepeat"] &&
                            formApi.errors["passwordRepeat"]
                              ? formApi.errors["passwordRepeat"].type ===
                                "required"
                                ? "Repeat Password is required"
                                : formApi.errors["passwordRepeat"].type ===
                                  "match"
                                ? "Passwords must be equal"
                                : formApi.errors["passwordRepeat"].type ===
                                  "min"
                                ? `Repeat Password must be at least ${passwordMinLength} characters`
                                : formApi.errors["passwordRepeat"].type ===
                                  "max"
                                ? "Repeat Password must be less then 50 character"
                                : null
                              : null
                          }
                          data-lpignore
                        />
                      </Validate>
                    )}
                  </FormControl>
                </div>
              </div>
            </Container>
            <ButtonGroup spacing={20} position="center">
              <IOButton
                processing={loading}
                disabled={
                  !formApi.formValid ||
                  (userUuid &&
                    formApi.touched["password"] &&
                    formApi.values["passwordRepeat"].length <
                      getMinPasswordLength(formApi.values["password"]))
                }
                type="submit"
                cy="submit-create-user"
              >
                {localUser.action === "create" ? "Create User" : "Update User"}
              </IOButton>
              <IOButton
                type="button"
                onClick={() => {
                  if (props.onClose) {
                    return props.onClose();
                  }
                  history.length >= 2
                    ? history.goBack()
                    : history.push("/admin/user-management/users");
                }}
                cancel
                cy="cancel-create-user"
              >
                Cancel
              </IOButton>
            </ButtonGroup>
          </>
        )}
      </Form>
    </>
  );
}

const defaultUser = Object.freeze({
  action: "create",
  invite: false,
  groups: [],
});
