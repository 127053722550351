import Checkbox from "../../../../../UI/Form/Checkbox/Checkbox";
import Select from "../../../../../UI/Form/Select/Select";
import {
  getUpdatedOverrides,
  includeFormulaValues,
  includeGroupingExtraColumnValue,
  includeRegularValues,
  setDefaultAggregation,
  setDynamicSubTitlePrefix,
} from "../helper";

export default function DynamicSubTitlesContainer(props) {
  const {
    chart,
    options,
    removeDynamicSubTitleColumn,
    setChartState,
    repeatingOptions,
    isParameterized,
  } = props;

  const { dynamicSubTitleKeys, overrides, groupingExtraColumns, filterNulls } =
    chart;

  if (!dynamicSubTitleKeys) {
    return null;
  }

  // set multiple values in dynamic group
  function setDynamicRepeatingValues(options, optionActions) {
    const { action, removedValue, option } = optionActions;

    if (action === "remove-value") {
      const column = setDynamicSubTitlePrefix(overrides, removedValue.value);
      return removeDynamicSubTitleColumn(column);
    }

    const updatedOverrides = getUpdatedOverrides(options, overrides, "value");

    const aggregatedOverrides = setDefaultAggregation(
      updatedOverrides,
      option,
      isParameterized
    );

    setChartState({
      ...chart,
      dynamicSubTitleKeys: options
        ? options.map((option) =>
            setDynamicSubTitlePrefix(aggregatedOverrides, option.value)
          )
        : [],
      overrides: aggregatedOverrides,
    });
  }

  // get multiple values in dynamic group
  function getDynamicRepeatingValues() {
    return [
      ...includeRegularValues(repeatingOptions, dynamicSubTitleKeys, overrides),
      ...includeFormulaValues(dynamicSubTitleKeys),
      ...includeGroupingExtraColumnValue(
        dynamicSubTitleKeys,
        groupingExtraColumns
      ),
    ];
  }

  function setFilterNulls() {
    setChartState({
      ...chart,
      filterNulls: !filterNulls,
    });
  }

  return (
    <>
      <Select
        options={options}
        value={getDynamicRepeatingValues()}
        onChange={setDynamicRepeatingValues}
        isClearable={false}
        isMulti
      />
      <br />
      <Checkbox
        label="Hide Null Sections"
        checked={filterNulls}
        onChange={setFilterNulls}
      />
    </>
  );
}
