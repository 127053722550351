import { useEffect, useState } from "react";
import produce from "immer";
import isEqual from "react-fast-compare";
import { useFilters } from "../../../Pages/DataManagement/DataExplorer/hooks/useExplorerFilters/useFilters";
import { isEqualWith, omit, set, keyBy } from "lodash-es";

/**
 * @typedef {import('@ag-grid-community/core').ColDef} ColDef
 */

/**
 * @typedef {Object} VisibleField
 * @property {string} colId
 * @property {string} [aggregationType]
 **/

const defaultInitialState = { visibleFields: [], filters: [] };
export default function useActiveTableViewManager({
  activeViewInitialState = defaultInitialState,
  allColumns,
  handleSaveView,
  user,
}) {
  const [previousViewInitialState, setPreviousViewInitialState] =
    useState(null);
  const [nextView, setNextView] = useState(activeViewInitialState);
  const [nextColumns, setNextColumns] = useState(allColumns); // all columns with views settings
  const [name, setName] = useState(activeViewInitialState?.name || "");
  // filters only used on create view screen for copy template from another view
  const [filters, setFilters] = useState(null);

  const [processing, setProcessing] = useState(false);
  const filterApi = useFilters(activeViewInitialState.filters ?? []);

  useEffect(() => {
    if (
      !previousViewInitialState ||
      !isEqual(previousViewInitialState, activeViewInitialState)
    ) {
      setPreviousViewInitialState(activeViewInitialState);
      const nextMergedColumns = convertedColumns(
        activeViewInitialState,
        allColumns
      );
      setNextColumns(nextMergedColumns);
      setNextView(activeViewInitialState);
      setName(activeViewInitialState?.name || "");
      filterApi.setDraftFilters(activeViewInitialState?.filters || []);
    }
  }, [activeViewInitialState, allColumns, previousViewInitialState, filterApi]);

  function changeValue(columnUuid, property, value) {
    return setNextColumns((nextColumns) =>
      produce(nextColumns, (draft) => {
        const columnIndex = nextColumns.findIndex(
          (c) => c.colId === columnUuid
        );
        if (columnIndex < 0) {
          return;
        }
        set(draft[columnIndex], property, value);
      })
    );
  }

  function setVisible(columnUuid) {
    changeValue(columnUuid, "visible", true);
  }

  function setInvisible(columnUuid) {
    changeValue(columnUuid, "visible", false);
  }

  function setAllVisible() {
    const updatedColumns = nextColumns.map((c) => ({ ...c, visible: true }));
    return setNextColumns(updatedColumns);
  }

  function setAllInvisible() {
    const updatedColumns = nextColumns.map((c) => ({ ...c, visible: false }));
    return setNextColumns(updatedColumns);
  }

  const visibleColumns = nextColumns.filter((c) => c.visible);

  const nextViewSettings = {
    ...nextView,
    visibleFields: visibleColumns,
    name,
    filters: filters ?? filterApi.draftFilters,
  };

  const disabled = processing || !name;

  const convertedActiveViewInitialState = {
    ...activeViewInitialState,
    visibleFields: convertedColumns(activeViewInitialState, allColumns).filter(
      (c) => c.visible
    ),
    filters: activeViewInitialState?.filters ?? [],
  };

  const isDirty = !isEqualWith(
    // updatedAt will change afte api call so we do not need to compare it
    omit(convertedActiveViewInitialState, "updatedAt"),
    omit(nextViewSettings, "updatedAt"),
    isEqualComparator
  );

  async function saveView() {
    try {
      setProcessing(true);
      handleSaveView(nextViewSettings);
    } finally {
      setProcessing(false);
    }
  }

  function setDefaultViewFlag() {
    const displaySettings = nextView.displaySettings ?? {};

    return setNextView({
      ...nextView,
      displaySettings: {
        ...displaySettings,
        isDefaultView: !displaySettings.isDefaultView,
      },
    });
  }

  function setViewSplitBy(columnName) {
    const displaySettings = nextView.displaySettings ?? {};

    return setNextView({
      ...nextView,
      displaySettings: {
        ...displaySettings,
        splitBy: columnName,
      },
    });
  }

  function setVisibilityViewUser(isPrivate) {
    const displaySettings = nextView.displaySettings ?? {};

    return setNextView({
      ...nextView,
      displaySettings: {
        ...displaySettings,
        viewVisibilityUserUuid: isPrivate ? user.uuid : null,
      },
    });
  }

  function setDisplaySettings(settings) {
    setNextView({ ...nextView, displaySettings: settings });
  }

  return {
    nextColumns,
    setNextColumns,
    visibleColumns,
    setVisible,
    setInvisible,
    changeValue,
    setAllVisible,
    setAllInvisible,
    nextViewSettings,
    name,
    setName,
    disabled,
    saveView,
    processing,
    isDirty,
    filterApi,
    displaySettings: nextView.displaySettings ?? {},
    setDefaultViewFlag,
    setVisibilityViewUser,
    setNextView,
    setFilters,
    setDisplaySettings,
    setViewSplitBy,
  };
}

function convertedColumns(viewState, allColumns) {
  // Map visible fields first
  const visibleColumns = assembleColumnsAndVisibleFields(
    viewState.visibleFields,
    allColumns
  ).map(({ column, visibleField }) => ({
    ...column,
    visible: true,
    aggregationType: visibleField.aggregationType,
    // Do not allow aggregated columns to be editable, implicitly.
    editable: visibleField.aggregationType ? false : column.editable,
  }));

  const remainingColumns = allColumns
    .filter((c) => !viewState.visibleFields.find((f) => f.colId === c.colId))
    .map((c) => ({ ...c, visible: false }));

  const orderedColumns = [...visibleColumns, ...remainingColumns];

  return orderedColumns;
}

/**
 * @param {VisibleField[]} visibleFields
 * @param {ColDef[]} colDefs
 * @param {Array<{dataSourceFieldUuid: string, name: string}>} queryFields
 * @returns {Array<{visibleField: VisibleField, column: ColDef}>}
 */
export function assembleColumnsAndVisibleFields(
  visibleFields,
  colDefs,
  queryFields = []
) {
  const columnsByName = keyBy(colDefs, "field");
  const queryFieldsByUuid = keyBy(queryFields, "dataSourceFieldUuid");

  return visibleFields
    .map((visibleField) => {
      // for some reason this can be undefined, we need to filter out undefined values
      const column = colDefs.find((column) => {
        if (column.colId === visibleField.colId) {
          // Column match.
          return true;
        }
        const queryField = queryFieldsByUuid[visibleField.colId];

        // Is the visibleField's uuid referencing the output data source field's
        // uuid rather than the available active table column's? Then map it if
        // a matched active table column is found with the same name.
        return queryField
          ? column.colId === columnsByName[queryField.name]?.colId
          : false;
      });

      return {
        visibleField,
        column,
      };
    })
    .filter((c) => c.column);
}

/**
 * Assembles a list that contains the visible fields, along with minimal
 * column data coming from a combination of the active table columns and
 * the data source fields.
 *
 * @param {VisibleField[]} visibleFields
 * @param {Array<{uuid: string, name: string}>} columns
 * @param {Array<{dataSourceFieldUuid: string, name: string}>} queryFields
 * @returns {Array<{visibleField: VisibleField, column: {name: string}}>}
 */
export function assembleVisibleFieldsWithColumns(
  visibleFields,
  columns,
  queryFields = []
) {
  const columnsByUuid = keyBy(columns, "uuid");
  const queryFieldsByUuid = keyBy(queryFields, "dataSourceFieldUuid");

  return visibleFields.map((visibleField) => {
    const column = columnsByUuid[visibleField.colId]
      ? {
          name: columnsByUuid[visibleField.colId].name,
        }
      : queryFieldsByUuid[visibleField.colId]
      ? {
          name: queryFieldsByUuid[visibleField.colId].name,
        }
      : null;

    return {
      visibleField,
      column,
    };
  });
}

// visibleFields has cellEditorParams for image types
// it include handleChange function which changes to new instanse on update
// we should skeep this comparison
function isEqualComparator(a, b) {
  if (a instanceof Function && b instanceof Function) {
    return true;
  }
}
