import React from "react";

export default function ArrowLine(props) {
  const { lineYPosition, lineXEnd } = props;
  const lineXStart1 = 0;
  const lineXEnd1 = lineXEnd / 2 - 40;
  const lineXStart2 = lineXEnd / 2 + 40;
  const lineXEnd2 = lineXEnd;
  return (
    <g style={{ opacity: 0.5 }}>
      <defs>
        {/* Define the left-pointing arrow marker */}
        <marker
          id="arrowStart"
          markerWidth="7"
          markerHeight="6"
          refX="0"
          refY="3"
          orient="auto"
          markerUnits="strokeWidth"
        >
          <path d="M10,0 L0,3 L10,6 Z" fill="black" />
        </marker>

        {/* Define the right-pointing arrow marker */}
        <marker
          id="arrowEnd"
          markerWidth="7"
          markerHeight="6"
          refX="5"
          refY="3"
          orient="auto"
          markerUnits="strokeWidth"
        >
          <path d="M0,0 L10,3 L0,6 Z" fill="black" />
        </marker>
      </defs>
      {/* First line segment */}
      <line
        x1={lineXStart1}
        y1={lineYPosition}
        x2={lineXEnd1}
        y2={lineYPosition}
        stroke="black"
        strokeWidth="1"
        markerStart="url(#arrowStart)"
      />
      {/* Second line segment */}
      <line
        x1={lineXStart2}
        y1={lineYPosition}
        x2={lineXEnd2}
        y2={lineYPosition}
        stroke="black"
        strokeWidth="1"
        markerEnd="url(#arrowEnd)"
      />
    </g>
  );
}
