import formatter from "../../../utils/formatters/formatter";
import { getMappingType } from "../../../utils/getVisualizationLabel";
import { getRidOfAggregation } from "../Elements/EditableMenu";

export function getLimitable(value, count = 100) {
  return typeof value === "string" && value.length > count;
}

export function getLimitedValue(limitable, value, count = 100) {
  if (limitable) {
    return value.substring(0, count) + "...";
  }
  return value;
}

// set complex cell value based on multiple columns
export function setMultipleColumn(chart, item) {
  const { multipleRowGroups, rowGroupKey, meta, overrides } = chart;

  if (!multipleRowGroups) return item;

  const keys = (rowGroupKey ?? "").split("__");
  const groupedValue = generateGroupedValue(keys, item, overrides, meta);

  return {
    ...item,
    [rowGroupKey]: groupedValue,
  };
}

// Helper function to generate the grouped value based on the row group keys
function generateGroupedValue(keys, item, overrides, meta) {
  return keys.reduce((acc, curr) => {
    const nonAggregatedName = getRidOfAggregation(curr, overrides);
    const format = getMappingType(meta?.fields, nonAggregatedName);
    return acc + formatter(item[curr], format) + " ";
  }, "");
}

export function overrideMetaFields(chart) {
  const {
    meta = {},
    multipleRowGroups,
    rowGroupKey,
    multipleRowGroupsDisplayName,
  } = chart;

  // Return meta as is if multiple row groups is false
  if (!multipleRowGroups) return meta;

  // Add the new field to the fields array and return the updated meta
  return {
    ...meta,
    fields: [
      ...(meta.fields ?? []),
      { name: rowGroupKey, label: multipleRowGroupsDisplayName },
    ],
  };
}

export function setGroupedRowBackgroundColor(
  theme,
  rows,
  groupedRowBackgroundColumn
) {
  if (!groupedRowBackgroundColumn) return {};

  const { dark, light } = theme.groupedZebraRows;

  return rows.reduce((acc, curr) => {
    const value = curr.values?.[0]?.[groupedRowBackgroundColumn];

    // Skip if no value or value already processed
    if (!value || acc[value]) return acc;

    const lastColor = Object.values(acc).pop();
    const color = getCurrentZebraColor([dark, light], lastColor);
    acc[value] = color;

    return acc;
  }, {});
}

function getCurrentZebraColor(colors, color) {
  return color ? (color === colors[0] ? colors[1] : colors[0]) : colors[0];
}

export function getTooltipConfiguration(tooltipConfig, cellKey, dataRow) {
  if (!Array.isArray(tooltipConfig)) {
    return tooltipConfig;
  }

  // Find the tooltip configuration matching the specified column
  const config = tooltipConfig?.find((item) => item.showOnColumn === cellKey);

  // Return undefined if no matching configuration is found
  if (!config) return;

  // Check if the tooltip should be shown based on a specific column value
  if (config.showIfColumnValueExists) {
    const columnValue = dataRow[config.showIfColumnValueExists];
    return columnValue ? config : null;
  }

  // Return the configuration if no additional conditions are specified
  return config;
}
