import React, { useRef, useState, useCallback, useEffect } from "react";
import styled from "@emotion/styled";
import ButtonOutline from "../ButtonOutline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { themeGray } from "../../../styles/styledSnippets";
import { capitalize } from "lodash-es";
import { getActiveTableFileTypeConfig } from "../../../utils/activeTable";
import { PreviewContainer, useActiveTableFileDownload } from "./common";
import ActiveTablePreviewImage from "./ActiveTablePreviewImage";
import Flex from "../../../UI/Flex/Flex";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";

const Container = styled.div`
  width: 400px;
  height: 340px;
  border: 1px solid ${themeGray(400)};
  background: ${themeGray(200)};
  padding: 20px;
  box-sizing: border-box;
  margin-left: -90px;
  margin-top: -340px;
  position: fixed;
`;

const UploadButton = styled.button`
  color: ${themeGray(800)};
  background-color: ${themeGray(200)};
  border: 1px solid ${(props) => props.theme.gray.gray400};
  padding: 10px 20px;
  margin: 10px 0;
  ${(props) =>
    props.backgroundColor
      ? `
    border: none;
    background: ${props.theme.notification.infoMain};
    color: white;
  `
      : ""}

  border-radius: 4px;
  cursor: pointer;
`;

const NoImage = styled.div`
  height: 200px;
  width: 360px;
  font-size: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: ${themeGray(700)};
  border: 1px solid ${themeGray(300)};
  background: ${themeGray(800)};
`;

export default function ActiveTableFileEditor(props) {
  const { colDef, stopEditing } = props;
  const columnType = colDef.type;
  const isImage = columnType === "image";
  const noun = isImage ? "image" : "file";
  const nounCapitalized = capitalize(noun);
  const containerRef = useRef();
  const inputRef = useRef();
  const [previewImage, setPreviewImage] = useState(null);
  const [file, setFile] = useState();

  const existingFile = props.data[props.colDef.field];

  const isThereAFileCurrently = file !== null && (existingFile || previewImage);

  const onFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const uploadedFile = event.target.files[0];
      setFile(uploadedFile);
      const reader = new FileReader();

      reader.onload = function (e) {
        setPreviewImage(e.target.result);
      };

      reader.readAsDataURL(uploadedFile);
    }
  };

  function onDeleteFile() {
    setFile(null);
    setPreviewImage(null);
  }

  const deleteEnabled = isThereAFileCurrently;

  const onUploadButtonClick = () => {
    inputRef.current.click();
  };

  const containerRefCallback = useCallback((current) => {
    if (current) {
      containerRef.current = current;
      current.parentElement.style.zIndex = 1003;
    }
  }, []);

  useEffect(() => {
    window.addEventListener("click", listener);

    return () => {
      window.removeEventListener("click", listener);
    };

    function listener(evt) {
      // Close on clicking away.
      if (containerRef.current && !containerRef.current.contains(evt.target)) {
        stopEditing();
      }
    }
  }, [stopEditing]);

  const { downloadIcon } = useActiveTableFileDownload(existingFile);

  function handleSave() {
    const field = props.column.colDef.field;
    const nextRowData = { ...props.data, [field]: file };
    const event = {
      rowIndex: props.rowIndex,
      colDef: props.column.colDef,
      data: nextRowData,
      value: file,
      oldValue: props.data[field],
    };
    props.handleChange(event);
    stopEditing();
  }

  const deleteIcon = (
    <FontAwesomeIcon
      className={"clickable"}
      title={"Delete File"}
      icon={faTrash}
      fontSize="1rem"
      opacity={deleteEnabled ? 1 : 0.4}
      onClick={deleteEnabled ? onDeleteFile : undefined}
    />
  );

  const fileTypeConfig = getActiveTableFileTypeConfig(columnType);
  return (
    <Container ref={containerRefCallback}>
      <div style={{ marginBottom: 24, fontWeight: 500 }}>
        Select your {noun} and save
      </div>

      <input
        ref={inputRef}
        id="imageUpload"
        style={{ display: "none" }}
        type="file"
        accept={fileTypeConfig.accept}
        onChange={onFileChange}
      />
      {isThereAFileCurrently && isImage && previewImage ? (
        <PreviewContainer
          style={{ backgroundImage: `url(${previewImage})` }}
        ></PreviewContainer>
      ) : isThereAFileCurrently ? (
        <ActiveTablePreviewImage
          columnType={columnType}
          existingImage={existingFile}
        />
      ) : (
        <NoImage style={columnType !== "api-image" && { opacity: 0.4 }}>
          <FontAwesomeIcon icon={fileTypeConfig.solid_icon} />
        </NoImage>
      )}
      <div style={{ position: "absolute", top: 2, right: 4 }}>
        <ButtonOutline hideOutline onClick={stopEditing}>
          <FontAwesomeIcon icon={["fal", "times"]} color="black" />
        </ButtonOutline>
      </div>
      <Flex alignItems="center" gap="0.5rem">
        <label htmlFor="imageUpload">
          <UploadButton onClick={onUploadButtonClick}>
            {previewImage ? "Change" : "Upload"} {nounCapitalized}
          </UploadButton>
        </label>
        <label htmlFor="imageUpload">
          <UploadButton backgroundColor onClick={handleSave}>
            Save
          </UploadButton>
        </label>
        <div style={{ flex: 1 }} />

        {deleteIcon}
        {downloadIcon}
      </Flex>
    </Container>
  );
}
